import { Stack, Typography } from "@mui/material"
import Loading from "components/Loading/Loading"
import Button from "components/ui/Button/Button"
import ChainStack from "components/ui/ChainStack/ChainStack"
import ContextMenu from "components/ui/ContextMenu"
import FontIcon from "components/ui/FontIcon/FontIcon"
import { useAppDispatch, useAppSelector } from "config/store"
import useActions from "hooks/useActions"
import NodeType from "models/node.model"
import { useEffect, useMemo } from "react"
import { setLocalSearchDisabled, setLocalSearchValue } from "reducers/localSearch"
//import sortNodes from "utils/sortNodes"
import { useSort } from "hooks/useSort"
import { OnSelectType } from "view/types/node"
import EntitiesGrid from "../EntitiesGrid/EntitiesGrid"
import EntitiesTable from "../EntitiesTable/EntitiesTable"
import { EntitesLayout } from "../types"

interface EntitiesListProps {
  parent: NodeType
  contextHeader?: string[]
  contextItems: string[]
  enablePreview?: boolean
  onClick: (e: React.MouseEvent) => void
  onNodesSelect: OnSelectType
  selectedOne?: NodeType
  onPaste?: (destination: NodeType) => Promise<void>
}

const EntitiesList = (props: EntitiesListProps) => {

  const {
    parent,
    contextHeader,
    contextItems,
    enablePreview,
    onClick,
    onNodesSelect,
    onPaste,
  } = props

  const { children = {} } = parent as NodeType
  const { content: entities } = children as NodeType['children']

  const { value: searchValue } = useAppSelector(state => state.localSearch)
  const selected = useAppSelector(state => state.clipboard?.selected)
  const layout = useAppSelector(state => state.toolbar.layout)

  const sortNodes = useSort()

  const dispatch = useAppDispatch()
  const { renderHeader, renderItems } = useActions(parent)

  const filteredEntities = useMemo(() => {
    if (!searchValue || searchValue === '') return entities
    return entities?.filter((entity: NodeType) => entity.name.toLowerCase().includes(searchValue.toLowerCase()))
  }, [entities, searchValue])

  const handleClearSearch = () =>
    dispatch(setLocalSearchValue(''))

  useEffect(() => {
    if (entities?.length > 0) {
      dispatch(setLocalSearchDisabled(false))
    }
    else {
      dispatch(setLocalSearchDisabled(true))
    }
    return () => {
      dispatch(setLocalSearchValue(''))
    }
  }, [entities])

  const nodes = useMemo(() => filteredEntities?.length > 0 ? sortNodes(filteredEntities) : [], [filteredEntities, sortNodes])

  return (
    <Loading showLoading={!nodes}>
      <ContextMenu
        contextId={`entities-${parent?.id}`}
        disabled={!parent?.id || layout === EntitesLayout.TABLE}
        header={renderHeader(contextHeader)}
        items={renderItems(contextItems)}
      >
        <Stack id='entities-list-wrapper' overflow='auto'
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            height: '100%'
          }}
          onClick={onClick}
        >
          {searchValue && (
            <ChainStack
              spacing='8px'
              padding='20px 0 0 20px'
            >
              <Typography variant='h6'>Результаты поиска «{searchValue}»</Typography>
              <Button color='primary' onClick={handleClearSearch}>
                <FontIcon icon='close' color='primary' />
              </Button>
            </ChainStack>
          )}
          {searchValue?.length > 0 && nodes?.length < 1 && entities?.length > 0 && (
            <Typography padding='0 20px' color='text.disabled' variant='h6'>
              Ничего не найдено, попробуйте очистить поле для поиска
            </Typography>
          )}
          {entities?.length < 1 && (
            <Typography padding='20px 20px' color='text.disabled' variant='h6'>
              Здесь ничего нет. Вы можете создать вложенную папку или добавить документы
            </Typography>
          )}
          {layout === EntitesLayout.GRID
            ? (
              <EntitiesGrid
                parent={parent}
                nodes={nodes}
                selected={selected}
                onSelect={onNodesSelect}
                onPaste={onPaste}
              />
            )
            : nodes?.length > 0 && (
              <EntitiesTable
                entities={nodes}
                contextItems={contextItems}
                enablePreview={enablePreview}
                onNodesSelect={onNodesSelect}
              />
            )
          }
        </Stack>
      </ContextMenu>
    </Loading>
  )
}

export default EntitiesList
