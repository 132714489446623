import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import auth from 'reducers/auth'
import { ACCESS_TOKEN } from './constants'

const baseQuery = fetchBaseQuery({
  prepareHeaders(headers) {
    const accessToken = sessionStorage.getItem(ACCESS_TOKEN)
    if (accessToken) headers.set('Authorization', 'Bearer ' + accessToken)
    return headers
  },
})

const baseQueryWithRetry = retry(
  async (args: string | FetchArgs, api, extraOptions) => {
    const result = await baseQuery(
      args,
      api,
      extraOptions
    )
    // bail out of re-tries immediately if unauthorized,
    // because we know successive re-retries would be redundant

    if ((result.error?.status as number) < 500 || ((result.error as any)?.originalStatus as number) < 500 || result.error?.status === 'FETCH_ERROR') {
      console.log('common-api error', result.error)
      return retry.fail(result.error)
    }
    return result
  },
  {
    maxRetries: 0,
  }
)

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQueryWithRetry(args, api, extraOptions)

  if (result.error && (result.error.status === 401 || (result.error as any)?.originalStatus as number === 401)) {
    auth.actions.logout()
    //result = await baseQueryWithRetry(args, api, extraOptions)
  }

  return result
}

export const commonApi = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'USER', 'TRENDS', 'FAVORITES', 'QUICK_NODES', 'NODE', 'RESPONSIBLE', 'DIRECTORY', 'NODE_RIGHTS', 'ACCESS_GROUPS', 'USERS', 'EMPLOYEES'
  ],
  endpoints: (_) => ({}),
})
