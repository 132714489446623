import { Stack } from "@mui/material"
import { ConfirmInputRenderInputParams } from "components/ui/ConfirmInput/types"
import { EmployeeType } from "models/employee.model"
import { NodeUserRightsType } from "models/node-user-rights"
import { useEffect, useState } from "react"
import EmployeeAutoComplete from "../../../../../components/controls/EmployeeAutoComplete/EmployeeAutoComplete"
import RightsAutoComplete from "../../components/RightAutoComplete/RightAutoComplete"

export const EmployeeRenderInput = ({ onChange }: ConfirmInputRenderInputParams<NodeUserRightsType>) => {

  const [employee, setEmployee] = useState(null)
  const [right, setRight] = useState(null)
  
  const handleEmployeeChange = (event: any, value: EmployeeType) => {
    setEmployee(value)
  }

  const handleRightChange = (event: any, value: EmployeeType) => {
    setRight(value)
  }

  useEffect(() => {
    onChange(undefined, employee && right ? { employee, right } : null)
  }, [employee, right])

  return (
    <Stack direction='row' spacing='4px' display='grid' gridTemplateColumns='1fr 1fr'>
      <EmployeeAutoComplete
        onChange={handleEmployeeChange}
      />
      <RightsAutoComplete
        onInputChange={() => setRight(null)}
        onChange={handleRightChange}
      />
    </Stack>
  )
}
