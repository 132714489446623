//import { authError } from "app/shared/reducers/authentication";

/* eslint no-console: off */
export default () => next => action => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    const { type, payload, meta, error } = action;

    console.groupCollapsed(type);
    console.log('Payload:', payload);
//    if (authError) {
//      console.log('Error:', error);
//    }
    console.log('Meta:', meta);
    console.groupEnd();
  }

  return next(action);
};
