import { Stack } from "@mui/material"
import Loading from "components/Loading/Loading"
import Dialog from "components/ui/Dialog"
import { EmployeeType } from "models/employee.model"
import {useEffect} from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router"
import { useGetEmployeeQuery, useGetFilteredEmployeeMutation } from "reducers/api/employee.api"
import {
  useCreateUserMutation,
  useRemoveUserMutation, useUpdateUserMutation
} from "../../../reducers/api/users.api"
import Header from "./Header/Header"
import UsersToolbar from "./Toolbars/UsersToolbar"
import UserDialogActions from "./UserDialogActions/UserDialogActions"
import UserManageForm from "./UserManageForm/UserManageForm"
import UsersList from "./UsersList/UsersList"
import {UserType} from "../../../models/user.model";

interface UsersProps {
  callbackUrl?: string
}

const Users = (props: UsersProps) => {
  const { callbackUrl } = props

  const {employeeId}  = useParams()
  const navigate = useNavigate();

  const methods = useForm<EmployeeType>({
    defaultValues: {
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      role: null,
      isActive: true
    }, mode:'onChange'
  })
  const { handleSubmit, reset, formState: { isValid: isFormValid } } = methods

  const isExistingUserId = Boolean(employeeId) && employeeId !== '-'

  const [getFilteredEmployee, { data: employeeList, isLoading: isEmployeeLoading }] = useGetFilteredEmployeeMutation()

  const { currentData: employeeData, refetch: refetchEmployee } = useGetEmployeeQuery({ id: employeeId }, {
    skip: !isExistingUserId
  })

  const [createUser, { isLoading: isUserCreate }] = useCreateUserMutation()
  const [updateUser, { isLoading: isUserUpdate }] = useUpdateUserMutation()
  const [removeUser, {isSuccess: deleteSuccess}] = useRemoveUserMutation()
  const isLoading = isEmployeeLoading || isUserCreate || isUserUpdate

  useEffect(() => {
    if (!employeeData) {
      reset({})
      return
    }
    const defaultValues = {
      firstName: employeeData?.firstName,
      middleName: employeeData?.middleName,
      lastName: employeeData?.lastName,
      role: employeeData?.role,
      isActive: employeeData?.isActive,
      email: employeeData?.email
    }
    reset(defaultValues)
  }, [employeeId, employeeData, reset])

  useEffect(() => {
    getFilteredEmployee([])
  }, [])


  useEffect(()=>{
    deleteSuccess && getFilteredEmployee([])
  },[deleteSuccess])

  const handleClose = () => {
    reset()
    navigate(callbackUrl)
  }

  const handleBackClick = () => {
    reset()
    navigate('/settings/users')
  }

  const handleManageUser = (employee?: EmployeeType) => {
    navigate(`/settings/users/${employee ? employee.id : '-'}`)
    reset()
  }

  const handleRemoveUser = async (employee: EmployeeType) => {
    await removeUser({ id: employee.userId })
  }

  const handleFormSubmit = handleSubmit(async (data: UserType) => {
     const dataForUpdate = { ...data,  id: employeeData?.userId}
     const response = employeeId !== '-' ?
       await updateUser(dataForUpdate) :
       await createUser({...data, isActive:true});

    if ((response as any)?.data) {
      if(employeeId !== '-') {
          setTimeout(() => {
            getFilteredEmployee([])
            navigate(`/settings/users`)
          }, 200)
      }else{
        getFilteredEmployee([])
        navigate(`/settings/users`)
          }
      reset({})
    }
  })

  return (
    <Dialog
      fullHeight
      open
      title={<Header employeeUserId={employeeId} handleBackClick={handleBackClick} />}
      actions={employeeId
        ? <UserDialogActions
          disabled={!isFormValid}
          handleFormSubmit={handleFormSubmit}
          handleClose={handleClose}
        />
        : undefined
      }
      onClose={handleClose}
    >
      <Loading showLoading={isLoading}>
        {!employeeId
          ? (
            <Stack spacing='16px' sx={{ overflow: 'auto' }}>
              <UsersToolbar onAddUser={handleManageUser} />
              <UsersList
                usersList={employeeList?.content || []}
                isLoading={isEmployeeLoading}
                onEdit={handleManageUser}
                onRemove={handleRemoveUser}
              />
            </Stack>
          ) : (
            <Stack>
              <FormProvider {...methods}>
                <UserManageForm employeeId={employeeId} />
              </FormProvider>
            </Stack>
          )
        }
      </Loading>
    </Dialog>
  )
}

export default Users
