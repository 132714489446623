import { Divider, Stack, Typography } from "@mui/material"
import FontIcon from "components/ui/FontIcon/FontIcon"
import NodeType from "models/node.model"
import PreviewPanel from "./PreviewPanel/PreviewPanel"

interface PreviewProps {
  enablePreview: boolean
  previewContent: NodeType
  previewContentError?: string
  onClose: any
}

const Preview = ({ enablePreview, previewContent, previewContentError, onClose }: PreviewProps) => {
  return (
    <>
      {enablePreview && <Divider orientation='vertical' sx={{ backgroundColor: 'primary.line' }} />}
      {enablePreview && (
        previewContent
          ? <PreviewPanel node={previewContent} onClose={onClose} />
          : (
            <Stack
              spacing='4px'
              flex={1}
              sx={{
                width: '100%',
                overflow: 'auto',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FontIcon icon='visibility' />
              <Typography variant='subtitle2'>
                {previewContentError || 'Для просмотра выберите один документ слева'}
              </Typography>
            </Stack>
          )
      )
      }
    </>
  )
}

export default Preview
