import AutoComplete, { AutoCompleteProps } from "components/ui/auto-complete/AutoComplete"
import { RightsType } from "models/rights.model"
import { useTranslation } from "react-i18next"
import { useGetRightsQuery } from "reducers/api/rights.api"

const RightsAutoComplete = ({ defaultValue, ...rest }: AutoCompleteProps<RightsType>) => {

  const { t } = useTranslation()

  const { data: rights, isLoading: isRightsLoading } = useGetRightsQuery()

  return (
    <AutoComplete<RightsType>
      fullWidth
      clientSide
      size='small'
      placeholder={t('kbl.labels.chooseRight')}
      disableClearable
      data={rights?.content}
      loading={isRightsLoading}
      optionLabel='name'
      value={defaultValue}
      {...rest}
    />
  )
}

export default RightsAutoComplete
