export const docIcon = (
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.59564 0C6.87812 0 5.5 1.38501 5.5 3.10253V66.9044C5.5 68.6219 6.87812 70 8.59564 70H56.5C58.2175 70 59.5 68.6219 59.5 66.9044V17L42.5 0H8.59564Z" fill="#ECEDEE" />
    <path d="M42.5 17H59.5L42.5 0V17Z" fill="#CDD1D3" />
    <path d="M0 33C0 31.3431 1.34315 30 3 30H43.5C45.1569 30 46.5 31.3431 46.5 33V53C46.5 54.6569 45.1569 56 43.5 56H3C1.34315 56 0 54.6569 0 53V33Z" fill="#1754B6" />
    <path d="M16.4827 43.048C16.4827 44.3707 16.232 45.4747 15.7307 46.36C15.24 47.2453 14.5467 47.9067 13.6507 48.344C12.7547 48.7813 11.6987 49 10.4827 49H6.78669V37.576H10.7387C11.9654 37.576 13.0054 37.7893 13.8587 38.216C14.712 38.632 15.3627 39.2453 15.8107 40.056C16.2587 40.8667 16.4827 41.864 16.4827 43.048ZM13.2827 43.16C13.2827 42.4667 13.1867 41.8907 12.9947 41.432C12.8134 40.9733 12.536 40.632 12.1627 40.408C11.8 40.184 11.336 40.072 10.7707 40.072H9.87469V46.472H10.5627C11.5014 46.472 12.1894 46.2 12.6267 45.656C13.064 45.112 13.2827 44.28 13.2827 43.16Z" fill="white" />
    <path d="M29.2247 43.272C29.2247 44.1573 29.118 44.9627 28.9047 45.688C28.6914 46.4027 28.3607 47.0213 27.9127 47.544C27.4647 48.0667 26.8887 48.4667 26.1847 48.744C25.4914 49.0213 24.654 49.16 23.6727 49.16C22.7127 49.16 21.8807 49.0213 21.1767 48.744C20.4834 48.4667 19.9074 48.0667 19.4487 47.544C19.0007 47.0213 18.6647 46.4027 18.4407 45.688C18.2274 44.9627 18.1207 44.152 18.1207 43.256C18.1207 42.072 18.318 41.0427 18.7127 40.168C19.1074 39.2827 19.7154 38.6 20.5367 38.12C21.358 37.64 22.4087 37.4 23.6887 37.4C24.99 37.4 26.046 37.6453 26.8567 38.136C27.678 38.616 28.2754 39.2987 28.6487 40.184C29.0327 41.0587 29.2247 42.088 29.2247 43.272ZM21.3687 43.272C21.3687 43.9547 21.4434 44.5467 21.5927 45.048C21.7527 45.5387 22.0034 45.9173 22.3447 46.184C22.686 46.4507 23.1287 46.584 23.6727 46.584C24.238 46.584 24.686 46.4507 25.0167 46.184C25.358 45.9173 25.6034 45.5387 25.7527 45.048C25.902 44.5467 25.9767 43.9547 25.9767 43.272C25.9767 42.2373 25.806 41.4213 25.4647 40.824C25.1234 40.2267 24.5314 39.928 23.6887 39.928C23.134 39.928 22.686 40.0667 22.3447 40.344C22.0034 40.6107 21.7527 40.9947 21.5927 41.496C21.4434 41.9867 21.3687 42.5787 21.3687 43.272Z" fill="white" />
    <path d="M36.3747 39.944C36.012 39.944 35.6867 40.024 35.3987 40.184C35.1107 40.3333 34.8654 40.5573 34.6627 40.856C34.46 41.144 34.3054 41.496 34.1987 41.912C34.092 42.328 34.0387 42.8027 34.0387 43.336C34.0387 44.0507 34.1294 44.6533 34.3107 45.144C34.492 45.6347 34.7694 46.008 35.1427 46.264C35.516 46.5093 35.9854 46.632 36.5507 46.632C37.052 46.632 37.5374 46.5627 38.0067 46.424C38.4867 46.2853 38.9667 46.1147 39.4467 45.912V48.52C38.9454 48.744 38.428 48.904 37.8947 49C37.372 49.1067 36.812 49.16 36.2147 49.16C34.9774 49.16 33.964 48.9147 33.1747 48.424C32.3854 47.9333 31.804 47.2507 31.4307 46.376C31.0574 45.4907 30.8707 44.472 30.8707 43.32C30.8707 42.456 30.988 41.6667 31.2227 40.952C31.468 40.2267 31.8254 39.6027 32.2947 39.08C32.764 38.5467 33.34 38.136 34.0227 37.848C34.716 37.56 35.5107 37.416 36.4067 37.416C36.9614 37.416 37.5427 37.48 38.1507 37.608C38.7587 37.736 39.356 37.9387 39.9427 38.216L38.9987 40.648C38.5827 40.4453 38.1614 40.28 37.7347 40.152C37.308 40.0133 36.8547 39.944 36.3747 39.944Z" fill="white" />
  </>
)
