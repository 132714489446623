import AutoComplete, { AutoCompleteProps } from 'components/ui/auto-complete/AutoComplete'
import FontIcon from 'components/ui/FontIcon'
import { renderRecentOption } from '../../utils'
import RecentList from './RecentList/RecentList'

interface SearchAutoCompleteProps extends AutoCompleteProps<string> {
  removeRecent: (text: string) => void
}

const SearchAutoComplete = ({ data, removeRecent, ...rest }: SearchAutoCompleteProps) => {

  return (
    <AutoComplete<string>
      data={data}
      placeholder='Введите строку для поиска'
      clientSide
      freeSolo
      fullWidth
      renderOption={renderRecentOption(removeRecent)}
      renderInputProps={{
        InputProps: {
          startAdornment: <FontIcon icon='search' />
        }
      }}
      PaperComponent={(props) => <RecentList {...props} />}
      ListboxProps={{
        sx: { paddingBlock: 0, '& .MuiAutocomplete-option': { paddingRight: '4px' } }
      }}
      {...rest}
    />
  )
}

export default SearchAutoComplete
