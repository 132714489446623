import { List, ListItem, ListItemText, TextField } from "@mui/material"
import useActions from "hooks/useActions"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { isEditable } from "utils/checkRight"
import Button from "../../../../../components/ui/Button/Button"
import ConfirmInput from "../../../../../components/ui/ConfirmInput/ConfirmInput"
import FontIcon from "../../../../../components/ui/FontIcon"
import DocumentCard from "../components/DocumentCard/DocumentCard"
import { CardBlocksProps, GeneralRowType, NameActionsProps } from "./DocumentCardBlocksProps"

const DocumentDetails = ({ rows }: any) => {
  return (
    <List disablePadding>
      {rows.map(({ title, value, actions }) =>
        <ListItem
          key={title}
          sx={(theme) => ({
            height: '36px',
            padding: 0,
            '&:not(:last-of-type)': {
              borderBottom: `1px solid ${theme.palette.primary.line}`
            }
          })}
        >
          <ListItemText
            primaryTypographyProps={{
              variant: 'subtitle2',
              color: 'primary.text03'
            }}
            sx={{
              flex: 1,
              maxWidth: '120px'
            }}
          >
            {title}
          </ListItemText>
          <ListItemText
            primaryTypographyProps={{
              noWrap: true,
              variant: 'subtitle2',
            }}
            sx={{
              flex: 1
            }}
          >
            {value}
          </ListItemText>
          {actions}
        </ListItem>
      )}
    </List>
  )
}

const DocumentGeneral = (props: CardBlocksProps) => {
  const [nameEdit, setNameEdit] = useState(false)
  const { t } = useTranslation()

  const { node } = props
  const { handleToggleFavorites, completeRenameNode } = useActions(node)
  const pathOfNameWithFormat = `.${node?.name.split(".")[node?.name.split(".").length - 1]}`

  const NameActions = (props: NameActionsProps) => {
    const { icon, onClick } = props
    return (
      <Button iconOnly onClick={onClick}>
        <FontIcon icon={icon} />
      </Button>
    )
  }

  const FavoriteActions = ({ onClick }: any) => {
    return (
      <Button iconOnly onClick={onClick}>
        <FontIcon
          icon='star'
          fill={node?.isFavorite}
          sx={{
            ...(node?.isFavorite && {
              color: '#F29B4A'
            })
          }}
        />
      </Button>
    )
  }

  const ChangeNameField = () => {
    return (
      <ConfirmInput
        renderInput={({ onChange }) => (
          <TextField
            variant='standard'
            InputProps={{ disableUnderline: true }}
            fullWidth
            defaultValue={node?.name}
            onChange={(e) => onChange(e, e.target.value)}
            sx={{ mb: '-1px' }}
          />
        )}
        onConfirm={completeRenameNode}
        onClose={() => { setNameEdit(false) }}
      />)
  }

  const handleNameEditClick = () => {
    setNameEdit(true)
  }

  const rows: GeneralRowType[] = [
    {
      title: t('kbl.title.name'), value: nameEdit
        ? <ChangeNameField />
        : node?.name,
      actions: isEditable(node) && !nameEdit && <NameActions onClick={handleNameEditClick} icon='edit' />
    },
    { title: t('kbl.title.author'), value: node?.createdBy?.fullName },
    { title: t('kbl.title.createdDate'), value: new Date(node?.createdDate).toLocaleDateString() },
    { title: t('kbl.title.updatedDate'), value: new Date(node?.lastModifiedDate).toLocaleDateString() },
    { title: t('kbl.title.isFavorite'), value: node?.isFavorite ? t('kbl.yes') : t('kbl.no'), actions: <FavoriteActions onClick={handleToggleFavorites} iconName='star' /> },
    {
      title: t('kbl.title.isArchive'),
      value: node?.isDeleted ? t('kbl.yes') : t('kbl.no'),
    },
  ]

  return (
    <DocumentCard title={t('kbl.title.general')}>
      <DocumentDetails
        rows={rows}
      />
    </DocumentCard>
  )
}

export default DocumentGeneral
export { DocumentDetails }
