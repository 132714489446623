import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import { commonApi } from './common-api'
import errorMiddleware from './error-middleware'
import loggerMiddleware from './logger-middleware'

import auth from 'reducers/auth'
import clipboard from 'reducers/clipboard'
import context from 'reducers/context'
import localSearch from 'reducers/localSearch'
import node from 'reducers/node'
import settings from 'reducers/settings'
import toolbar from 'reducers/toolbar'

export const store = configureStore({
  devTools: true,
  reducer: {
    auth: auth.reducer,
    node: node.reducer,
    toolbar: toolbar.reducer,
    localSearch: localSearch.reducer,
    clipboard: clipboard.reducer,
    context: context.reducer,
    settings: settings.reducer,
    [commonApi.reducerPath]: commonApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      errorMiddleware,
      loggerMiddleware,
      commonApi.middleware
    ),
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

const getStore = () => store

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default getStore
