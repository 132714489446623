import { commonApi } from "config/common-api"
import { API_URL } from "config/constants"

const apiUrl = `${API_URL}/kbl/link-node`

export const linkNode = commonApi.injectEndpoints({
  endpoints: builder => ({

    removeLink: builder.mutation<void, { id: string, initId: string }>({
      query: ({ id, initId }) => ({
        url: `${apiUrl}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, error, args) => !error ? [{ type: 'NODE', id: args.initId }] : []
    }),
  })
})

export const {
  useRemoveLinkMutation,
} = linkNode

export default linkNode
