import { Button } from "@mui/material"
import Loading from "components/Loading/Loading"
import { ResponsibleType } from "models/responsible.model"
import { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import Dialog from "../../../../../components/ui/Dialog"
import {
	useCreateResponsibleMutation,
	useGetResponsibleQuery,
	useUpdateResponsibleMutation
} from "../../../../../reducers/api/responsible.api"
import ResponsibleForm from './ResponsibleForm/ResponsibleForm'

interface ResponsibleDialogProps {
	responsible?: ResponsibleType
	onClose: () => void
	onSuccess: () => void
}

const ResponsibleDialog = ({ responsible, onClose, onSuccess }: ResponsibleDialogProps) => {
	
	const { t } = useTranslation()

	const methods = useForm<ResponsibleType>()

	const { handleSubmit, reset, formState: { isValid: isFormValid } } = methods 
	const { data: responsibleData, isLoading } = useGetResponsibleQuery({ id: responsible?.id }, { skip: !responsible?.id })

	const [updateResponsible] = useUpdateResponsibleMutation()

 const [createResponsible] = useCreateResponsibleMutation()

	const handleFormSubmit = handleSubmit(async (data: ResponsibleType) => {
		
		if (responsible?.id) {
			
			for (const key in data) {
        data[key] === responsibleData[key] && key !== 'id' && delete data[key];
      }

			if(Object.keys(data).length <= 1) return onClose();	

			try {
				const response = await updateResponsible(data)
				if ((response as any).error) {
					throw (response as any).error
				}
				onSuccess()
				onClose()				
			}
			catch (e) {
				console.log('update responsible error:', e)
			 }
		}
		else {
			try {
				if(data.department === '') data['department'] = null;
				const response = await createResponsible(data)
				if ((response as any).error) {
					throw (response as any).error
				}
				onSuccess()
				onClose()
			}
			catch (e) {
				console.log('create responsible error:', e)
			 }
		}
	})
	
	useEffect(() => {
		if (!responsibleData) return
		const defaultValues = {
      id: responsibleData.id,
      firstName: responsibleData.firstName,
      middleName: responsibleData.middleName,
      lastName: responsible.lastName,
      department: responsibleData.department,
      post: responsibleData.post,
      address: responsibleData.address,
      phone: responsibleData.phone,
      email: responsibleData.email,
      forGroup: responsibleData.forGroup,
      functional: responsibleData.functional,
      delegation: responsibleData.delegation,
    };
		reset(defaultValues)
	}, [responsibleData])

	return (
    <Dialog
      fullHeight
      open={true}
      title={
        responsible?.id
          ? t('kbl.dialog.title.general.changeResponsibleInfo')
          : t('kbl.dialog.title.general.createResponsible')
      }
      onClose={onClose}
      actions={
        <>
          <Button fullWidth variant='outlined' onClick={onClose}>
            {t('kbl.buttons.cancel')}
          </Button>
          <Button
            form='my-form'
            type='submit'
            fullWidth
            variant='contained'
            disabled={!isFormValid}
            color='success'
            onClick={handleFormSubmit}
          >
            {t('kbl.buttons.save')}
          </Button>
        </>
      }
    >
      <Loading showLoading={isLoading}>
        <FormProvider {...methods}>
          <ResponsibleForm editing={responsible?.id} />
        </FormProvider>
      </Loading>
    </Dialog>
  );
}

export default ResponsibleDialog
