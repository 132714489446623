import { Divider, ListItemIcon, MenuItem, MenuItemProps, Typography } from "@mui/material"
import { NOOP } from "config/constants"
import { Key } from "react"
import FontIcon from "../FontIcon"

export interface MenuItemType extends MenuItemProps {
  key?: Key
  label?: string
  icon?: string
  expander?: boolean
  command?: Function
  customComponent?: React.ReactNode
  data?: { [key: string]: any }
}

interface MenuListProps {
  items: MenuItemType[]
  onClose?: () => void
}

function MenuList({ items, onClose = NOOP }: MenuListProps) {

  const handleClick = (command: Function) => {
    if (command) command()
    onClose()
  }

  const menuItems = items.map(({ key, label, icon, divider, customComponent, command, ...rest }) => {
    return (
      customComponent
        ? <MenuItem key={key}>{customComponent}</MenuItem>
        : divider
          ? <Divider key={key} />
          : <MenuItem key={key} onClick={() => handleClick(command)} {...rest}>
            {icon && (
              <ListItemIcon
                sx={{
                  minWidth: '24px !important',
                }}
              >
                <FontIcon icon={icon} sx={{ marginTop: '-2px' }} />
              </ListItemIcon>
            )}
            <Typography noWrap>{label}</Typography>
          </MenuItem>
    )
  })

  return <>{menuItems}</>
}

export default MenuList
