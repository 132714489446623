import { SvgIcon } from '@mui/material'
import NodeType from 'models/node.model'
import DocumentIcon from '../DocumentIcon/DocumentIcon'
import FolderIcon from '../FolderIcon'
import TrendIcon from '../TrendIcon'

interface NodeIconProps {
  node: NodeType
  small?: boolean
  width?: number
}

const NodeIcon = ({ node = {}, small, width = small ? 24 : 66 }: NodeIconProps) => {
  const icons = {
    'ROOT': <TrendIcon small={small} />,
    'TREND': <TrendIcon small={small} />,
    'FOLDER': <FolderIcon small={small} />,
    'REFERENCE': <FolderIcon small={small} />,
    'DOCUMENT': <DocumentIcon small={small} extension={node?.document?.attachment?.extension} />,
  }

  const height = Math.round(width * 1.06)

  return icons[node.nodeType]
    ? (
      <SvgIcon
        inheritViewBox
        sx={{
          width: `${width}px`,
          height: `${height}px`,
          ...(node.isDeleted && { opacity: '0.5', filter: 'saturate(0)' }),
        }}
      >
        <svg
          viewBox={small ? '0 0 24 26' : `${node.nodeType === 'DOCUMENT' ? '-20' : 0} 0 100 70`}
          fill="none" xmlns="http://www.w3.org/2000/svg">
          {icons[node.nodeType]}
        </svg>
      </SvgIcon>
    )
    : null
}

export default NodeIcon
