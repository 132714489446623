export const pngIcon = (
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.09564 0C7.37812 0 6 1.38501 6 3.10253V66.9044C6 68.6219 7.37812 70 9.09564 70H57C58.7175 70 60 68.6219 60 66.9044V17L43 0H9.09564Z" fill="#ECEDEE" />
    <path d="M43 17H60L43 0V17Z" fill="#CDD1D3" />
    <path d="M6 44H60V67C60 68.6569 58.6569 70 57 70H9C7.34315 70 6 68.6569 6 67V44Z" fill="#F2C94C" />
    <path d="M20.5356 51.576C21.9862 51.576 23.0689 51.8907 23.7836 52.52C24.5089 53.1493 24.8716 54.0453 24.8716 55.208C24.8716 55.7307 24.7969 56.232 24.6476 56.712C24.4982 57.1813 24.2529 57.608 23.9116 57.992C23.5809 58.3653 23.1382 58.664 22.5836 58.888C22.0289 59.1013 21.3462 59.208 20.5356 59.208H19.6716V63H16.5836V51.576H20.5356ZM20.4716 54.072H19.6716V56.696H20.2796C20.5462 56.696 20.7916 56.6533 21.0156 56.568C21.2502 56.472 21.4369 56.3227 21.5756 56.12C21.7142 55.9067 21.7836 55.6293 21.7836 55.288C21.7836 54.9253 21.6769 54.632 21.4636 54.408C21.2502 54.184 20.9196 54.072 20.4716 54.072Z" fill="white" />
    <path d="M37.5729 63H33.5249L29.3489 54.952H29.2849C29.3063 55.1333 29.3223 55.3467 29.3329 55.592C29.3543 55.8373 29.3703 56.0933 29.3809 56.36C29.3916 56.6267 29.4023 56.888 29.4129 57.144C29.4236 57.4 29.4289 57.6347 29.4289 57.848V63H26.6929V51.576H30.7249L34.8849 59.512H34.9329C34.9223 59.3307 34.9063 59.1227 34.8849 58.888C34.8743 58.6427 34.8636 58.3973 34.8529 58.152C34.8423 57.896 34.8316 57.6453 34.8209 57.4C34.8209 57.1547 34.8209 56.936 34.8209 56.744V51.576H37.5729V63Z" fill="white" />
    <path d="M44.6287 56.312H49.5567V62.472C48.97 62.6747 48.2927 62.84 47.5247 62.968C46.7674 63.096 45.9727 63.16 45.1407 63.16C44.042 63.16 43.0767 62.9467 42.2447 62.52C41.4234 62.0933 40.778 61.4427 40.3087 60.568C39.85 59.6933 39.6207 58.5893 39.6207 57.256C39.6207 56.0613 39.85 55.0267 40.3087 54.152C40.7674 53.2773 41.4394 52.6053 42.3247 52.136C43.2207 51.656 44.3194 51.416 45.6207 51.416C46.3354 51.416 47.0127 51.4853 47.6527 51.624C48.2927 51.752 48.8474 51.9227 49.3167 52.136L48.3407 54.552C47.9354 54.3493 47.5087 54.2 47.0607 54.104C46.6127 53.9973 46.138 53.944 45.6367 53.944C44.954 53.944 44.4047 54.0987 43.9887 54.408C43.5727 54.7173 43.2687 55.128 43.0767 55.64C42.8954 56.152 42.8047 56.7173 42.8047 57.336C42.8047 58.04 42.906 58.6373 43.1087 59.128C43.3114 59.6187 43.6047 59.992 43.9887 60.248C44.3727 60.4933 44.842 60.616 45.3967 60.616C45.5247 60.616 45.6687 60.6107 45.8287 60.6C45.9887 60.5893 46.1434 60.5733 46.2927 60.552C46.4527 60.5307 46.5754 60.5093 46.6607 60.488V58.696H44.6287V56.312Z" fill="white" />
  </>
)
