import { ButtonProps, useMediaQuery } from "@mui/material"
import Button from "components/ui/Button/Button"
import FontIcon from "components/ui/FontIcon/FontIcon"

interface PreviewButtonProps extends ButtonProps {
  active: boolean
  label?: string
  onClick: () => void
}

const PreviewButton = ({ active, label, onClick, disabled }: PreviewButtonProps) => {

  const isWidth1200 = useMediaQuery('(min-width:1200px)')

  return (
    <Button
      size='small'
      disabled={disabled}
      startIcon={(
        <FontIcon
          icon={active ? 'visibility_off' : 'visibility'}
          sx={{ color: disabled ? 'text.disabled' : 'primary.main' }}
        />
      )}
      onClick={onClick}
    >
      {isWidth1200 ? label : null}
    </Button>
  )
}

export default PreviewButton
