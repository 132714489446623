import { Stack } from "@mui/material"
import { ConfirmInputRenderInputParams } from "components/ui/ConfirmInput/types"
import { AccessGroupType } from "models/access-group.model"
import { NodeUserRightsType } from "models/node-user-rights"
import { RightsType } from "models/rights.model"
import { useEffect, useState } from "react"
import GroupAutoComplete from "../../components/GroupAutoComplete/GroupAutoComplete"
import RightsAutoComplete from "../../components/RightAutoComplete/RightAutoComplete"

export const AccessGroupRenderInput = ({ onChange }: ConfirmInputRenderInputParams<NodeUserRightsType>) => {

  const [accessGroupKbl, setNewAccessGroupKbl] = useState(null)
  const [right, setRight] = useState(null)

  const handleAccessGroupChange = (_event: any, value: AccessGroupType) => {
    setNewAccessGroupKbl(value)
  }

  const handleRightChange = (_event: any, value: RightsType) => {
    setRight(value)
  }

  useEffect(() => {
    onChange(undefined, accessGroupKbl && right ? { accessGroupKbl, right } : null)
  }, [accessGroupKbl, right])

  return (
    <Stack direction='row' spacing='4px' display='grid' gridTemplateColumns='1fr 1fr'>
      <GroupAutoComplete
        onChange={handleAccessGroupChange}
      />
      <RightsAutoComplete
        onInputChange={() => setRight(null)}
        onChange={handleRightChange}
      />
    </Stack>
  )
}
