export const pdfIcon = (
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.09564 0C7.37812 0 6 1.38501 6 3.10253V66.9044C6 68.6219 7.37812 70 9.09564 70H57C58.7175 70 60 68.6219 60 66.9044V17L43 0H9.09564Z" fill="#ECEDEE" />
    <path d="M43 17H60L43 0V17Z" fill="#CDD1D3" />
    <path d="M0 33C0 31.3431 1.34315 30 3 30H40C41.6569 30 43 31.3431 43 33V53C43 54.6569 41.6569 56 40 56H3C1.34314 56 0 54.6569 0 53V33Z" fill="#8B131D" />
    <path d="M11.4418 37.576C12.8925 37.576 13.9751 37.8907 14.6898 38.52C15.4151 39.1493 15.7778 40.0453 15.7778 41.208C15.7778 41.7307 15.7031 42.232 15.5538 42.712C15.4045 43.1813 15.1591 43.608 14.8178 43.992C14.4871 44.3653 14.0445 44.664 13.4898 44.888C12.9351 45.1013 12.2525 45.208 11.4418 45.208H10.5778V49H7.48981V37.576H11.4418ZM11.3778 40.072H10.5778V42.696H11.1858C11.4525 42.696 11.6978 42.6533 11.9218 42.568C12.1565 42.472 12.3431 42.3227 12.4818 42.12C12.6205 41.9067 12.6898 41.6293 12.6898 41.288C12.6898 40.9253 12.5831 40.632 12.3698 40.408C12.1565 40.184 11.8258 40.072 11.3778 40.072Z" fill="white" />
    <path d="M27.2952 43.048C27.2952 44.3707 27.0445 45.4747 26.5432 46.36C26.0525 47.2453 25.3592 47.9067 24.4632 48.344C23.5672 48.7813 22.5112 49 21.2952 49H17.5992V37.576H21.5512C22.7779 37.576 23.8179 37.7893 24.6712 38.216C25.5245 38.632 26.1752 39.2453 26.6232 40.056C27.0712 40.8667 27.2952 41.864 27.2952 43.048ZM24.0952 43.16C24.0952 42.4667 23.9992 41.8907 23.8072 41.432C23.6259 40.9733 23.3485 40.632 22.9752 40.408C22.6125 40.184 22.1485 40.072 21.5832 40.072H20.6872V46.472H21.3752C22.3139 46.472 23.0019 46.2 23.4392 45.656C23.8765 45.112 24.0952 44.28 24.0952 43.16Z" fill="white" />
    <path d="M32.3892 49H29.3492V37.576H36.1012V40.056H32.3892V42.232H35.8132V44.712H32.3892V49Z" fill="white" />
  </>
)
