import {
  CircularProgress,
  TextField,
} from '@mui/material'
import renderEndAdornment from '../../../utils/end-adornment'
import { RenderInputProps } from '../types'

const RenderInput = <T,>(props: RenderInputProps<T>) => {
  const {
    value,
    loading,
    placeholder,
    InputProps,
    inputProps,
    renderInputProps,
    getAdornment,
    ...params
  } = props
  return (
    <TextField
      {...params}
      placeholder={placeholder}
      {...renderInputProps}
      InputProps={{
        ...InputProps,
        ...renderInputProps?.InputProps,
        inputProps: {
          ...inputProps,
          ...renderInputProps?.inputProps,
        },
        endAdornment: (
          <>
            {renderEndAdornment(
              loading ? (
                <CircularProgress color='inherit' size={12} />
              ) : (
                getAdornment && getAdornment(value)
              ),
            )}
            {InputProps?.endAdornment}
          </>
        ),
      }}
    />
  )
}

export default RenderInput
