export const pptIcon = (
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.09564 0C7.37812 0 6 1.38501 6 3.10253V66.9044C6 68.6219 7.37812 70 9.09564 70H57C58.7175 70 60 68.6219 60 66.9044V17L43 0H9.09564Z" fill="#ECEDEE" />
    <path d="M43 17H60L43 0V17Z" fill="#CDD1D3" />
    <path d="M0 33C0 31.3431 1.34315 30 3 30H39C40.6569 30 42 31.3431 42 33V53C42 54.6569 40.6569 56 39 56H3C1.34315 56 0 54.6569 0 53V33Z" fill="#C3391C" />
    <path d="M11.3481 37.576C12.7987 37.576 13.8814 37.8907 14.5961 38.52C15.3214 39.1493 15.6841 40.0453 15.6841 41.208C15.6841 41.7307 15.6094 42.232 15.4601 42.712C15.3107 43.1813 15.0654 43.608 14.7241 43.992C14.3934 44.3653 13.9507 44.664 13.3961 44.888C12.8414 45.1013 12.1587 45.208 11.3481 45.208H10.4841V49H7.39606V37.576H11.3481ZM11.2841 40.072H10.4841V42.696H11.0921C11.3587 42.696 11.6041 42.6533 11.8281 42.568C12.0627 42.472 12.2494 42.3227 12.3881 42.12C12.5267 41.9067 12.5961 41.6293 12.5961 41.288C12.5961 40.9253 12.4894 40.632 12.2761 40.408C12.0627 40.184 11.7321 40.072 11.2841 40.072Z" fill="white" />
    <path d="M21.4574 37.576C22.9081 37.576 23.9908 37.8907 24.7054 38.52C25.4308 39.1493 25.7934 40.0453 25.7934 41.208C25.7934 41.7307 25.7188 42.232 25.5694 42.712C25.4201 43.1813 25.1748 43.608 24.8334 43.992C24.5028 44.3653 24.0601 44.664 23.5054 44.888C22.9508 45.1013 22.2681 45.208 21.4574 45.208H20.5934V49H17.5054V37.576H21.4574ZM21.3934 40.072H20.5934V42.696H21.2014C21.4681 42.696 21.7134 42.6533 21.9374 42.568C22.1721 42.472 22.3588 42.3227 22.4974 42.12C22.6361 41.9067 22.7054 41.6293 22.7054 41.288C22.7054 40.9253 22.5988 40.632 22.3854 40.408C22.1721 40.184 21.8414 40.072 21.3934 40.072Z" fill="white" />
    <path d="M32.6548 49H29.5668V40.104H26.7828V37.576H35.4388V40.104H32.6548V49Z" fill="white" />
  </>
)
