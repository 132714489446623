import { Stack } from "@mui/material"
import Button from "components/ui/Button/Button"
import ConfirmInput from "components/ui/ConfirmInput/ConfirmInput"
import FontIcon from "components/ui/FontIcon"
import { ResponsibleType } from "models/responsible.model"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import ResponsibleAutoComplete from "../components/ResponsibleAutoComplete/ResponsibleAutoComplete"

interface ResponsibleToolbarProps {
  onAddResponsible: (values: ResponsibleType[]) => void
}

const ResponsibleToolbar = ({ onAddResponsible }: ResponsibleToolbarProps) => {

  const { t } = useTranslation()
  const [showAddResponsible, setShowAddResponsible] = useState(false)

  return (
    <Stack spacing='10px'>
      {showAddResponsible
        ? (
          <ConfirmInput<ResponsibleType>
            renderInput={({ onChange }) => (
              <ResponsibleAutoComplete onChange={onChange} />
            )}
            onClose={() => setShowAddResponsible(false)}
            onConfirm={onAddResponsible}
          />
        )
        : (
          <Button
            size='small'
            variant='outlined'
            disabled={showAddResponsible}
            startIcon={<FontIcon icon='add' />}
            onClick={() => setShowAddResponsible(true)}
            sx={{ width: 'fit-content' }}
          >
            {t('kbl.buttons.addResponsible')}
          </Button>
        )
      }
    </Stack>
  )
}

export default ResponsibleToolbar
