import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, Input, Stack } from '@mui/material'
import List from '@mui/material/List'
import NodeIcon from 'components/Icons/NodeIcon/NodeIcon'
import Loading from 'components/Loading/Loading'
import Dialog from 'components/ui/Dialog'
import FontIcon from 'components/ui/FontIcon'
import TreeItem from 'components/ui/Tree/TreeItem'
import { NOOP } from 'config/constants'
import NodeType from 'models/node.model'
import { useCallback, useEffect, useState } from 'react'
import { DragDropContext, Draggable, DraggableProvided, DropResult, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useGetAllTrendsQuery, useUpdateTrendsMutation } from 'reducers/api/node.api'

const getNode = (nodes: NodeType[], nodeId: string) => {
  let node: NodeType = undefined
  nodes.forEach(root => {
    if (node) return
    if (root.id === nodeId) {
      node = root
    }
    else if (root?.children.length > 0) {
      if (node) return
      root.children.forEach(child => {
        if (child.id === nodeId) {
          node = child
        }
      })
    }
  })
  return node
}

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  ...draggableStyle,
  userSelect: 'none',
  background: isDragging ? 'rgba(0, 0, 0, 0.05)' : undefined,
})

export default function Trends({ callbackUrl = '/' }: { draggable?: boolean, callbackUrl?: string }) {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { data: initialTrendsData, isLoading: isTrendsLoading } = useGetAllTrendsQuery()

  const [updateTrends, { isLoading: isTrendsUpdating }] = useUpdateTrendsMutation()

  const { id: trendId } = useParams()

  const [draggable, setDraggable] = useState(false)
  const [data, setData] = useState<NodeType[]>([]) // сюда кладем слепок входных данных, и тут будут вноситься изменения перед отправкой на бек
  const [expanded, setExpanded] = useState(trendId ? [trendId] : [])
  const [editing, setEditing] = useState<NodeType | undefined>(undefined) // нода, которую в данный момент редактируют
  const [edited, setEdited] = useState({}) // измененные ноды

  const [newRoot, setNewRoot] = useState<NodeType | undefined>()
  const [newTrend, setNewTrend] = useState<NodeType | undefined>()

  const [newRootsNumber, setNewRootsNumber] = useState<number>(0)
  const [newTrendsNumber, setNewTrendsNumber] = useState<number>(0)

  const Edit = () => {

    return (
      <Input
        disableUnderline
        autoFocus
        name={editing?.id}
        defaultValue={editing?.name}
        onChange={handleEditChange}
        onKeyDown={handleKeyDown}
        sx={{
          width: '100%',
          '& .MuiInput-input': (theme) => ({
            padding: '0 4px 0 4px',
            borderRadius: '3px',
            border: `1px solid ${theme.palette.primary.main}`
          })
        }}
        onBlur={handleEditApply}
      />
    )
  }

  const handleClose = () => navigate(callbackUrl)

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleEditApply()
      return
    }
    if (e.key === 'Escape') {
      e.stopPropagation()
      setEditing(undefined)
      setNewTrend(undefined)
      setNewRoot(undefined)
    }
  }

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditing(v => ({ ...v, name: e.target.value }))
  }

  const handleEditApply = () => {
    let _data = [...data]

    const _editing = { ...editing }

    if (_editing.id.startsWith('draft_new_root')) { // добавляем root node
      _editing.id = _editing.id.slice(6)
      setNewRootsNumber(v => v + 1)
      _data.forEach((_root, idx) => _data[idx] = { ..._root, order: _root.order + 1 })
      _data.unshift(_editing)
    }
    else if (_editing.id.startsWith('draft_new_trend')) { // добавляем trend node
      _editing.id = _editing.id.slice(6)
      setNewTrendsNumber(v => v + 1)
      const parentId = _editing.parent.id
      const _root = getNode(_data, parentId)
      const _children: NodeType['children'] = [..._root.children.map(child => ({ ...child, order: child.order + 1 }))]
      _children.unshift(_editing)
      _data = [..._data.map(item => item.id === parentId ? ({ ...item, children: _children }) : item)]
    }
    else {
      _data.forEach((item, idx) => {
        const _item = { ...item }
        const _children = [..._data[idx].children]
        if (item.id === editing.id && item.name !== editing.name) {
          _data[idx] = editing
        }
        else if (item.children.length > 0) {
          item.children.forEach((child, index: number) => {
            if (child.id === editing.id && child.name !== editing.name) {
              _children[index] = editing
              _item.children = _children
              _data[idx] = _item
            }
          })
        }
      })
    }

    if (editing.name !== getNode(initialTrendsData, editing.id)?.name)
      setEdited(v => ({ ...v, [_editing.id]: _editing }))
    else {
      setEdited(v => ({ ...v, [_editing.id]: undefined }))
    }

    setEditing(undefined)
    setNewRoot(undefined)
    setNewTrend(undefined)
    setData(_data)
  }

  const handleExpand = (id: string, expand?: boolean) => {
    const expandedSet = new Set(expanded)
    if (expandedSet.has(id) && !expand)
      expandedSet.delete(id)
    else
      expandedSet.add(id)
    setExpanded(Array.from(expandedSet))
  }

  const handleDelete = (id: string) => {
    const _data = [...data]
    data.forEach((root, idx) => {
      const _children = [...root.children]
      if (root.id === id) {
        _data[idx] = { ...root, isDeleted: !root.isDeleted }
      }
      else {
        root.children.forEach((child, index) => {
          if (child.id === id) {
            _children[index] = { ...child, isDeleted: !child.isDeleted }
            _data[idx] = { ...root, children: _children }
          }
        })
      }
    })
    setData(_data)
  }

  const handleClick = (item: NodeType) => {
    if (!item.isDeleted && editing?.id !== item.id) setEditing(item)
  }

  const handleAddRootClick = () => {
    const newTrendName = t('kbl.labels.newTrend')
    const _newRoot: NodeType = {
      id: newRootsNumber ? `draft_new_root_${newRootsNumber}` : 'draft_new_root',
      name: newRootsNumber ? `${newTrendName} ${newRootsNumber}` : newTrendName,
      nodeType: 'ROOT',
      order: 1,
      isDirectory: false,
      children: []
    }
    setNewRoot(_newRoot)
    setEditing(_newRoot)
  }

  const handleConfirm = async () => {
    const _data = data.filter(root => !(root.id.startsWith('new_root') && root.isDeleted))
    _data.forEach((root, idx) => {
      if (root.id.startsWith('new_root')) {
        delete root.id
      }
      const _children = root.children.filter(child => !(child.id.startsWith('new_trend') && child.isDeleted))
      root.children.forEach((child, index) => {
        const _child = { ...child }
        if (_child.id.startsWith('new_trend')) {
          delete _child.id
          delete _child.parent
        }
        _children[index] = { ..._child }
      })
      _data[idx] = { ...root, children: _children }
    })

    await updateTrends(_data)
    handleClose()
  }

  const setItem = useCallback((node: NodeType) => {
    return node?.id && node.id === editing?.id ? <Edit /> : <>{node?.name}</>
  }, [data, editing])

  const setActions = useCallback((item: NodeType) => {

    const handleAddTrendClick = (e: React.MouseEvent) => {
      e.stopPropagation()
      const newTrendName = t('kbl.labels.newTrend')
      const _data = [...data]
      const rootIdx = _data.findIndex(x => x.id === item.id)

      handleExpand(_data[rootIdx].id, true)

      const _newTrend: NodeType = {
        id: newTrendsNumber ? `draft_new_trend_${newTrendsNumber}` : 'draft_new_trend',
        name: newTrendsNumber ? `${newTrendName} ${newTrendsNumber}` : newTrendName,
        nodeType: 'TREND',
        order: 1,
        parent: {
          id: _data[rootIdx].id,
          isDirectory: false,
          nodeType: _data[rootIdx].nodeType
        }
      }
      setNewTrend(_newTrend)
      setEditing(_newTrend)
    }

    const handleDeleteClick = (e: React.MouseEvent) => {
      e.stopPropagation()
      handleDelete(item.id)
    }

    return (
      <>
        {item?.hasChildren || editing?.id === item.id || item.isDirectory
          ? null
          : (
            <>
              {item.isDeleted
                ? <FontIcon icon='undo' onClick={handleDeleteClick} />
                : <FontIcon icon='delete' color='error' onClick={handleDeleteClick} />
              }
            </>
          )
        }
        {!item.isDirectory && !item.parent && (expanded.includes(item.id) || item.children.length < 1) && !item.isDeleted && editing?.id !== item.id
          ? <FontIcon icon='add' onClick={handleAddTrendClick} sx={{ paddingLeft: '5px' }} />
          : null}
      </>
    )
  }, [initialTrendsData, data, expanded, newTrendsNumber, editing])

  const setSx = (item: NodeType) => {

    const isEdited = edited[item.id] ? (getNode(initialTrendsData, item.id)?.name !== edited[item.id].name) : false
    const hasDeletedChildren = item?.children?.filter(item => item?.isDeleted)?.length > 0
    const hasNewChildren = item?.children?.length !== getNode(initialTrendsData, item.id)?.children?.length

    return {
      width: '100%',
      '& .MuiListItemText-root': {
        width: '100%',
        ...(item.isDeleted
          ? {
            color: 'primary.text03',
            textDecoration: 'line-through'
          }
          : undefined),
        ...(hasDeletedChildren || hasNewChildren || isEdited
          ? {
            color: 'green',
          }
          : undefined)
      }
    }
  }

  const buildTree = useCallback(() => {
    if (!data) return
    const _data = (newRoot ? [newRoot, ...data] : [...data])

    const renderTreeItem = (item: NodeType, provided?: DraggableProvided) => (
      <TreeItem
        draggable={draggable}
        dragHandleProps={provided?.dragHandleProps}
        key={item.id}
        node={newTrend?.parent?.id === item.id ? { ...item, children: [newTrend, ...item.children] } : item}
        item={setItem(item)}
        icon={<NodeIcon node={item} small />}
        expanded={expanded.includes(item.id)}
        selected={editing?.id === item.id}
        selectedSubItemId={editing?.id}
        showActions='always'
        onExpand={handleExpand}
        onClick={handleClick}
        onDragEnd={handleDragEnd}
        setActions={setActions}
        setSx={setSx}
        setItem={setItem}
      />
    )

    return (
      _data?.map((item, index) => {
        return (
          draggable ?
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {(provided, snapshot) => (
                <Box
                  ref={provided.innerRef}
                  id={`draggable-${item.id}`}
                  {...provided.draggableProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  )}
                >
                  {renderTreeItem(item, provided)}
                </Box>
              )}
            </Draggable>
            : renderTreeItem(item)
        )
      })
    )
  }, [data, expanded, editing, newRoot, draggable])

  const handleDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const droppableId = result.destination.droppableId
    const sourceOrder = result.source.index
    const destinationOrder = result.destination.index

    const __data = (droppableId === 'root'
      ? data
      : data.find(x => x.id === droppableId)?.children)
      .map((x, idx) => idx === sourceOrder
        ? { ...x, order: destinationOrder }
        : sourceOrder < destinationOrder
          // перетаскиваем сверху вниз
          ? idx > sourceOrder && idx <= destinationOrder
            ? { ...x, order: x.order - 1 }
            : x
          // перетаскиваем снизу вверх
          : idx >= destinationOrder && idx < sourceOrder
            ? { ...x, order: x.order + 1 }
            : x
      )

    __data.sort((a, b) => a.order - b.order)

    if (droppableId === 'root') {
      setData(__data)
    }
    else {
      setData(prev => prev.map(x => x.id === droppableId
        ? { ...x, children: __data }
        : x
      ))
    }
  }

  useEffect(() => {
    setData(initialTrendsData?.map((x, idxX) => ({
      ...x,
      order: idxX,
      children: x.children?.map((y, idxY) => ({ ...y, order: idxY }))
    })))
  }, [initialTrendsData])

  return (
    <Dialog
      fullHeight
      open={true}
      title='Направления'
      onClose={handleClose}
      actions={
        <>
          <Button fullWidth variant='outlined' onClick={handleClose}>
            {t('kbl.buttons.cancel')}
          </Button>
          <LoadingButton
            disabled={isTrendsLoading}
            loading={isTrendsUpdating}
            loadingPosition="start"
            startIcon={<FontIcon icon='check' sx={{ color: 'primary.contrastText' }} />}
            fullWidth
            variant='contained'
            color='success'
            onClick={handleConfirm}
          >
            {t('kbl.buttons.save')}
          </LoadingButton>
        </>
      }
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
          overflow: 'auto'
        }}
      >
        <Stack
          direction='row'
          spacing='10px'
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: '8px'
          }}
          onClick={NOOP}>
          <Button
            variant='outlined'
            size='small'
            disabled={isTrendsLoading}
            startIcon={<FontIcon icon='swap_vert' sx={{ color: isTrendsLoading ? 'primary.text03' : 'primary.main' }} />}
            onClick={() => setDraggable(v => !v)}
          >
            Сортировать
          </Button>
          <Button
            size='small'
            variant='outlined'
            disabled={isTrendsLoading}
            startIcon={<FontIcon icon='add' sx={{ color: isTrendsLoading ? 'primary.text03' : 'primary.main' }} />}
            onClick={handleAddRootClick}
          >
            {t('kbl.buttons.addTrend')}
          </Button>
          <Box sx={{ flex: 1 }}></Box>
          <Button
            size='small'
            variant='text'
            disabled={isTrendsLoading}
            endIcon={(
              <FontIcon
                icon={expanded.length > 0 ? 'expand_less' : 'expand_more'}
                sx={{
                  color: isTrendsLoading ? 'primary.text03' : 'primary.text'
                }}
              />
            )}
            sx={{
              fontSize: '14px',
            }}
            onClick={() => setExpanded(expanded.length > 0 ? [] : initialTrendsData.map(x => x.id))}
          >
            {expanded.length > 0 ? t('kbl.buttons.collapseAll') : t('kbl.buttons.expandAll')}
          </Button>
        </Stack>
        <Box
          sx={{
            display: 'grid',
            overflow: 'auto'
          }}
        >
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <Loading showLoading={isTrendsLoading}>
              <>
                {draggable
                  ? <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="root">
                      {(provided, snapshot) => (
                        <Box
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {buildTree()}
                        </Box>
                      )}
                    </Droppable>
                  </DragDropContext>
                  : buildTree()
                }
              </>
            </Loading>
          </List>
        </Box>
      </Box>
    </Dialog>
  )
}
