import { Box, Stack, Typography } from "@mui/material"
import Loading from "components/Loading/Loading"
import Dialog from "components/ui/Dialog"
import FontIcon from "components/ui/FontIcon"
import { AccessGroupType } from "models/access-group.model"
import { EmployeeType } from "models/employee.model"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router"
import { useAddEmployeeMutation, useCreateGroupMutation, useGetGroupQuery, useGetGroupsQuery, useRemoveEmployeeMutation, useRemoveGroupMutation, useUpdateGroupMutation } from "reducers/api/access-group.api"
import AccessGroupList from "./AccessGroupList/AccessGroupList"
import AccessGroupName from "./AccessGroupName/AccessGroupName"
import AccessGroupsList from "./AccessGroupsList/AccessGroupsList"
import AccessGroupToolbar from "./Toolbars/AccessGroupToolbar/AccessGroupToolbar"
import AccessGroupsToolbar from "./Toolbars/AccessGroupsToolbar/AccessGroupsToolbar"

interface AccessGroupsProps {
  callbackUrl?: string
}

const AccessGroups = (props: AccessGroupsProps) => {

  const { callbackUrl } = props

  const { t } = useTranslation()
  const { accessGroupId } = useParams()

  const navigate = useNavigate()

  const [currAccessGroup, setCurrAccessGroup] = useState<AccessGroupType>(null)

  const { data: accessGroupData, isLoading: isAccessGroupLoading } = useGetGroupQuery({ id: accessGroupId }, {
    skip: accessGroupId === '-' || !accessGroupId
  })

  const { data: accessGroupsData, isLoading: isAccessGroupsLoading } = useGetGroupsQuery(undefined, {
    skip: Boolean(accessGroupId)
  })

  const [createGroup, { isLoading: isAccessGroupCreating }] = useCreateGroupMutation()
  const [updateGroup] = useUpdateGroupMutation()
  const [removeGroup] = useRemoveGroupMutation()
  const [addEmployee] = useAddEmployeeMutation()
  const [removeEmployee] = useRemoveEmployeeMutation()

  const isLoading = isAccessGroupLoading || isAccessGroupCreating

  const handleClose = () => navigate(callbackUrl)

  const handleManageAccessGroup = (accessGroup?: AccessGroupType) => {
    navigate(`/settings/access-groups/${accessGroup ? accessGroup.id : '-'}`)
  }

  const handleBackClick = () => navigate('/settings/access-groups')

  const handleEditName = async (name: string) => {
    if (currAccessGroup) {
      await updateGroup({ ...currAccessGroup, name })
      return
    }
    const response = await createGroup({ name })
    if ((response as any)?.data) {
      navigate(`/settings/access-groups/${(response as any).data.id}`)
    }
  }

  // const handleAddEmployees = (accessGroup: AccessGroupType) => {
  //   updateGroup(accessGroup)
  // }

  const handleAddEmployees = (values: EmployeeType[]) => {
    values.forEach(value => {
      addEmployee({ employeeId: value.id, accessGroupId: currAccessGroup?.id })
    })
  }

  const handleRemoveGroup = async (accessGroup: AccessGroupType) => {
    await removeGroup({ id: accessGroup.id })
  }

  const handleRemoveEmployee = async (employee: EmployeeType) => {
    await removeEmployee({ employeeId: employee.id, accessGroupId: currAccessGroup?.id })
  }

  useEffect(() => {
    if (accessGroupData && accessGroupId && accessGroupId !== '-')
      setCurrAccessGroup(accessGroupData)
    else
      setCurrAccessGroup(null)
  }, [accessGroupId, accessGroupData])

  return (
    <Dialog
      fullHeight
      onClose={handleClose}
      open={true}
      title={(
        <Stack direction='row' alignItems='center' spacing='16px'>
          {accessGroupId && (
            <Stack direction='row' alignItems='center' sx={{ color: 'primary.main', cursor: 'pointer' }} onClick={handleBackClick}>
              <FontIcon icon='chevron_left' sx={{ color: 'primary.main' }} />
              <Typography variant='subtitle2'>
                Назад
              </Typography>
            </Stack>
          )}
          <Box>
            {t('kbl.dialog.title.accessGroups')}
          </Box>
        </Stack>
      )}
    >
      <Loading showLoading={isLoading}>
        {accessGroupId ? (
          <Stack spacing='16px' sx={{ overflow: 'auto' }}>
            <AccessGroupName accessGroup={currAccessGroup} onEditName={handleEditName} />
            <AccessGroupToolbar disabled={!currAccessGroup} onAddEmployees={handleAddEmployees} />
            <AccessGroupList
              accessGroup={currAccessGroup}
              isLoading={isLoading}
              onRemove={handleRemoveEmployee}
            />
          </Stack>
        ) : (
          <Stack spacing='16px' sx={{ overflow: 'auto' }}>
            <AccessGroupsToolbar onAddAccessGroup={handleManageAccessGroup} />
            <AccessGroupsList
              data={accessGroupsData?.content || []}
              isLoading={isAccessGroupsLoading}
              onEdit={handleManageAccessGroup}
              onRemove={handleRemoveGroup}
            />
          </Stack>
        )}
      </Loading>
    </Dialog>
  )
}

export default AccessGroups
