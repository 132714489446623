import { GridCellParams, GridRowId, GridToolbarQuickFilter } from "@mui/x-data-grid-premium"
import DataGrid from "components/ui/DataGrid/DataGrid"
import { RenderFiltersType } from "components/ui/DataGrid/types"
import { renderCell, renderHeader } from "components/utils/datagrid"
import { ResponsibleType } from "models/responsible.model"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import ResponsibleAction from "./ResponsibleItemAction/ResponsibleItemAction"
import ResponsibleItemContent from "./ResponsibleItemContent/ResponsibleItemContent"

interface ResponsibleListProps {
  data: ResponsibleType[]
  isLoading: boolean
  onRemove: (data: ResponsibleType) => Promise<void>
}

const ResponsibleList = ({ data, isLoading, onRemove }: ResponsibleListProps) => {

  const { t } = useTranslation()
  const [pending, setPending] = useState<string[]>(null)

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([])

  const handleDetailPanelExpandedRowIdsChange = useCallback(
    (newIds: GridRowId[]) => {
      setDetailPanelExpandedRowIds(
        newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
      )
    }, []
  )

  const handleRemove = async (data: ResponsibleType) => {
    setPending(v => [...(v || []), data.id])
    await onRemove(data)
  }

  const columns = useMemo(() => [
    { headerName: t('kbl.dataGrid.shortName'), field: 'shortName', },
    { headerName: t('kbl.dataGrid.post'), field: 'post', flex: 2 },
    { headerName: t('kbl.dataGrid.phone'), field: 'phone', },
    {
      field: 'actions', type: 'actions', width: 30,
      renderCell: (cell: GridCellParams) => (
        <ResponsibleAction
          isPending={pending?.includes(cell.row.id)}
          onClick={() => handleRemove(cell.row)}
        />
      )
    }
  ].map((column: any) => ({ renderCell, renderHeader, ...column })), [data, pending])

  const renderFilters: RenderFiltersType = (apiRef) => {
    return (
      <GridToolbarQuickFilter sx={{ width: '100%', paddingBottom: 0 }} />
    )
  }

  useEffect(() => {
    setPending(null)
  }, [data])

  return (
    <DataGrid
      columns={columns}
      rows={data}
      stateKey='node-responsible'
      stateStorage='localStorage'
      density='compact'
      disableColumnFilter
      disableColumnMenu
      disableRowSelectionOnClick
      disableColumnSelector
      hideFooter
      autoPageSize
      loading={isLoading}
      showDetailOnRowClick
      slotProps={{
        toolbar: {
          disableExport: true,
          renderFilters
        },
      }}
      getDetailPanelContent={({ row }) => <ResponsibleItemContent {...row} />}
      getDetailPanelHeight={() => 'auto'}
      detailPanelExpandedRowIds={detailPanelExpandedRowIds}
      onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
    />
  )
}

export default ResponsibleList
