import { Box, FormControlLabel, Stack, Switch } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import FontIcon from 'components/ui/FontIcon'
import Menu from 'components/ui/Menu'
import { ADMINS, MANAGERS } from 'config/constants'
import { useAppDispatch, useAppSelector } from 'config/store'
import { MouseEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { useGetMeQuery } from 'reducers/api/employee.api'
import { logout } from 'reducers/auth'
import { setShowDeleted } from 'reducers/settings'

export default function Account() {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { data: meData } = useGetMeQuery()

  const showDeleted = useAppSelector(state => state.settings.showDeleted)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    handleClose()
    dispatch(logout())
  }

  const manageTrends = () => {
    navigate('/settings/trends', { state: { background: location } })
  }

  const manageUsers = () => {
    navigate('/settings/users', { state: { background: location } })
  }

  const manageAccessGroups = () => {
    navigate('/settings/access-groups', { state: { background: location } })
  }

  const ShowDeleted = (
    <FormControlLabel
      control={
        <Switch
          size='small'
          checked={showDeleted}
          onChange={(e) => dispatch(setShowDeleted(e.target.checked))}
        />
      }
      label={t('kbl.buttons.showDeleted')}
    />
  )

  const accountMenuItems = useMemo(() => {
    const menuLogout = { key: 'logout', label: t('kbl.menu.items.logout'), icon: 'logout', command: handleLogout }

    const menuItems = []

    if (MANAGERS.includes(meData?.role.recordType))
      menuItems.push({ key: 'trends', label: t('kbl.menu.items.manageTrends'), icon: 'edit', command: manageTrends })

    if (ADMINS.includes(meData?.role.recordType)) {
      menuItems.push(
        { key: 'employees', label: t('kbl.menu.items.employees'), icon: 'switch_account', command: manageUsers },
        { key: 'access-groups', label: t('kbl.menu.items.accessGroups'), icon: 'groups', command: manageAccessGroups },
      )
    }

    if (menuItems.length > 0) {
      menuItems.push({ key: 'divider1', divider: true })
    }

    menuItems.push({ key: 'is-deleted', customComponent: ShowDeleted }, { key: 'divider2', divider: true }, menuLogout)
    return menuItems

  }, [meData, showDeleted])

  return (
    <>
      <Stack direction='row' spacing='10px'>
        <Stack direction='row' spacing='10px' alignItems='center' flex={1}>
          <FontIcon
            icon='person'
            sx={{
              backgroundColor: 'primary.mosmetro',
              fontSize: '34px !important',
              padding: '6px',
              borderRadius: '6px',
              color: 'white',
            }}
          />
            <Typography variant='h2' noWrap>
              {meData?.name}
            </Typography>
        </Stack>
        <Box>
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            {open ? <FontIcon icon='expand_less' /> : <FontIcon icon='expand_more' />}
          </IconButton>
        </Box>
      </Stack>

      {open &&
        <Menu
          items={accountMenuItems}
          anchorEl={anchorEl}
          id="account-menu"
          open
          onClose={handleClose}
          //onClick={handleClose}
          sx={{
            //position: 'static',
            '& .MuiPaper-root': {
              overflow: 'visible',
              //marginTop: '36px',
              minWidth: '220px',
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: '9px',
                width: '10px',
                height: '10px',
                backgroundColor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        />
      }
    </>
  )
}
