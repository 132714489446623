import { Box, CircularProgress } from "@mui/material"

const NodePending = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        top: '40px',
        justifyContent: 'center',
      }}
    >
      <CircularProgress size={26} sx={{ color: 'white' }} />
    </Box>
  )
}

export default NodePending
