import { Stack, Typography } from "@mui/material"
import NodeIcon from "components/Icons/NodeIcon/NodeIcon"
import NodeType from "models/node.model"

interface ContentTitleProps {
  node: NodeType
  title: string
}

const ContentTitle = ({ node, title }: ContentTitleProps) => {
  return (
    <Stack direction='row' spacing='8px' alignItems='center' flex={1} overflow='auto' sx={{ minWidth: '250px' }}>
      <NodeIcon node={node} small />
      <Typography variant='h1' noWrap
        sx={{
          paddingTop: '4px'
        }}
      >
        {title}
      </Typography>
    </Stack>
  )
}

export default ContentTitle
