import { Box, Collapse } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import AdvancedSearchForm from "./AdvancedSearchForm/AdvancedSearchForm"

interface AdvancedSearchProps {
  anchorEl: HTMLElement
  show: boolean
}

const AdvancedSearch = ({ anchorEl, show }: AdvancedSearchProps) => {

  const [left, setLeft] = useState(100)
  const ref = useRef<HTMLElement>()

  const getLeft = () => (
    anchorEl?.getBoundingClientRect().x - ref.current?.getBoundingClientRect().x + anchorEl?.getBoundingClientRect().width / 2 - 8
  )

  const handleResize = () => {
    setLeft(getLeft())
  }

  useEffect(() => {
    setLeft(getLeft())
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [anchorEl, ref])

  return (
    <Collapse in={show} timeout={0}>
      <Box 
        ref={ref}
        sx={{
          padding: '8px 16px 16px 16px',
          border: '1px solid',
          borderColor: 'primary.line',
          borderRadius: '8px',
          backgroundColor: 'rgba(252, 252, 252, 1)',
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: '-9px',
            left: `${left}px`,
            width: '15px',
            height: '15px',
            transform: 'rotate(45deg)',
            backgroundColor: 'rgba(252, 252, 252, 1)',
            borderWidth: '1px 0 0 1px',
            borderStyle: 'solid',
            borderColor: 'primary.line',
            borderTopLeftRadius: '2px'
          }
        }}
      >
        <AdvancedSearchForm />
      </Box>  
    </Collapse>
  )
}

export default AdvancedSearch
