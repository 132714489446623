// TODO: добавить suggest-highlight

import { Autocomplete as MuiAutocomplete } from '@mui/material'
import Loading from 'components/Loading/Loading'
import useDebounce from 'hooks/useDebounce'
import { useCallback, useEffect, useState } from 'react'
import RenderInput from './render-input/render-input'
import RenderOption from './render-option/render-option'
import { AutoCompleteProps } from './types'

const AutoComplete = <T,>(props: AutoCompleteProps<T>) => {
  const {
    data,
    clientSide,
    placeholder,
    optionLabel = 'name',
    loading,
    debounceDelay,
    renderInputProps,
    multiple,
    onChange = () => { },
    onInputValueChange,
    getAdornment,
    renderOptionProps,
    ...rest
  } = props

  const [value, setValue] = useState<T>()
  const [inputValue, setInputValue] = useState('')

  const { debounce } = useDebounce({
    delay: debounceDelay || (clientSide ? 0 : undefined),
  })

  const handleInputChange: AutoCompleteProps<T>['onInputChange'] = (_event, value, reason) => {
    if (reason === 'reset') return
    setInputValue(value)
  }

  const handleChange: AutoCompleteProps<T>['onChange'] = (event, value: T, reason) => {
    if (value?.[optionLabel] === inputValue || value === inputValue) {
      debounce()
    }
    setValue(value)
    onChange(event, value, reason)
  }

  const getOptionLabel = useCallback((option: T) => typeof option === 'string' ? option : option?.[optionLabel], [])

  const renderInput: AutoCompleteProps<T>['renderInput'] = useCallback((params) => {
    return (
      <RenderInput<T>
        value={value}
        placeholder={placeholder}
        getAdornment={getAdornment}
        renderInputProps={renderInputProps}
        {...params}
      />
    )
  }, [inputValue, value, loading])

  const renderOption: AutoCompleteProps<T>['renderOption'] = useCallback((props, option, state, ownerState) => (
    <RenderOption<T>
      props={props}
      state={state}
      ownerState={ownerState}
      option={renderOptionProps(option)}
    />
  ), [])

  useEffect(() => {
    if (inputValue && onInputValueChange) {
      debounce(() => onInputValueChange(inputValue))
    }
  }, [inputValue])

  return (
    <MuiAutocomplete
      loading={loading}
      loadingText={(
        <Loading
          showLoading
          message='Загрузка...'
          circularProgressProps={{ size: '16px' }}
          containerProps={{ sx: { justifyContent: 'flex-start' } }}
        />
      )}
      clearOnBlur={false}
      options={clientSide || inputValue ? data || [] : []}
      getOptionLabel={getOptionLabel}
      noOptionsText={inputValue ? 'Нет данных' : 'Начните печатать, чтобы получить результаты'}
      disableCloseOnSelect={multiple}
      multiple={multiple}
      renderInput={renderInput}
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderOption={renderOptionProps ? renderOption : undefined}
      {...rest}
    />
  )
}

export default AutoComplete
export { type AutoCompleteProps }
