import { Alert, Box, Grid, Snackbar } from '@mui/material'
import Loading from 'components/Loading/Loading'
import PreviewButton from 'components/controls/PreviewButton/PreviewButton'
import useActions from 'hooks/useActions'
import { t } from 'i18next'
import NodeType, { DocumentNodeType } from 'models/node.model'
import { useEffect, useState } from 'react'
import { isEditable } from 'utils/checkRight'
import { ToolbarItem } from '../../../../components/Toolbar/Toolbar'
import Preview from '../Entities/Preview/Preview'
import NoAccess from '../NoAccess/NoAccess'
import NodeToolbar from '../NodeToolbar/NodeToolbar'
import DocumentGeneral from './DocumentCardBlocks/DocumentGeneral'
import DocumentRelatedDocs from './DocumentCardBlocks/DocumentRelatedDocs'
import DocumentResponsible from './DocumentCardBlocks/DocumentResponsible'

interface DocumentProps {
  node: DocumentNodeType
  error: any
  onArchive: (nodes: NodeType[]) => void
  onRestore: (nodes: NodeType[]) => void
}

const Document = ({ node, error, onArchive, onRestore }: DocumentProps) => {
  const { download } = useActions(node)
  const { open } = useActions(node)

  const [enablePreview, setEnablePreview] = useState(undefined)
  const [downloadAlert, showDownloadAlert] = useState(false)

  const handleTogglePreview = () => setEnablePreview((v) => !v)

  const noAccess = error?.status === 403

  const handleDownload = async () => {
    const result = await download(node)
    showDownloadAlert(!result)
  }

  const handleRestore = () => {
    onRestore([node])
  }

  const handleArchive = () => {
    onArchive([node])
  }

  const toolbarItems: ToolbarItem[] = [
    { icon: 'share', disabled: true },
    {
      icon: 'download',
      disabled: node?.loadStatus !== 'READY',
      onClick: handleDownload,
    },
    ...(node?.isDeleted
      ? [
        {
          icon: 'restore_from_trash',
          disabled: !isEditable(node),
          onClick: handleRestore,
        },
      ]
      : [
        {
          icon: 'delete',
          disabled: !isEditable(node),
          onClick: handleArchive,
          primaryColor: 'primary.mosmetro',
        },
      ]),
    { expander: true },
    {
      component: (
        <PreviewButton
          active={enablePreview}
          disabled={node?.loadStatus !== 'READY'}
          label={node.loadStatus !== 'READY' ? t('kbl.buttons.noPreviewDocument') : enablePreview ? t('kbl.buttons.hidePreviewDocument') : t('kbl.buttons.previewDocument')}
          onClick={handleTogglePreview}
        />
      ),
    },
  ]

  useEffect(() => {
    setEnablePreview(node?.loadStatus === 'READY')
  }, [node])

  return (
    <>
      <Snackbar
        open={downloadAlert}
        autoHideDuration={6000}
        onClose={() => showDownloadAlert(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => showDownloadAlert(false)}
          severity='error'
          variant='filled'
          sx={{ width: '100%' }}
        >
          Ошибка загрузки
        </Alert>
      </Snackbar>

      <Loading showLoading={enablePreview === undefined}>
        <Box
          id='document'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
          <NodeToolbar items={toolbarItems} />

          {noAccess ? (
            <NoAccess message={error?.data?.message} />
          ) : (
            <Loading showLoading={!node?.id}>
              <Box
                id='document-content'
                flexGrow={1}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: enablePreview ? '30% auto 70%' : '1fr',
                  overflow: 'auto',
                  width: '100%',
                }}
              >
                <Box sx={{ flexGrow: 1, paddingBlock: '16px', overflow: 'auto' }}>
                  <Grid container spacing='16px'>
                    <Grid item xs={enablePreview ? 12 : 6}>
                      <DocumentGeneral node={node} />
                    </Grid>
                    <Grid item xs={enablePreview ? 12 : 6}>
                      <DocumentResponsible node={node} />
                    </Grid>

                    <Grid item xs={12}>
                      <DocumentRelatedDocs node={node} />
                    </Grid>
                  </Grid>
                </Box>

                <Preview
                  enablePreview={enablePreview}
                  previewContent={node}
                  onClose={() => setEnablePreview(false)}
                />
              </Box>
            </Loading>
          )}
        </Box>
      </Loading>
    </>
  )
}

export default Document
