import { commonApi } from "config/common-api"
import { API_URL, NODE_TYPE_ORDER } from "config/constants"
import NodeType, { DocumentNodeType, NodeManyType } from "models/node.model"
import { ResponsibleType } from "models/responsible.model"
import { extraNames } from "./utils"

const apiUrl = `${API_URL}/kbl/node`

export const node = commonApi.injectEndpoints({
  endpoints: builder => ({

    getAllTrends: builder.query<NodeType[], void>({
      query: () => ({
        url: `${apiUrl}/trends`,
      }),
      providesTags: ['TRENDS'],
    }),

    getNodeEntity: builder.query<NodeType, { id: string }>({
      query: ({ id }) => ({
        url: `${apiUrl}/${id}`,
      }),
      providesTags: (result, _, arg) => result ? [{ type: 'NODE', id: arg.id }] : [],
    }),

    getNodesFiltering: builder.mutation<NodeManyType, any>({
      query: (body) => ({
        method: 'POST',
        url: `${apiUrl}/filtering`,
        body
      }),
    }),

    getNodesSearch: builder.mutation<NodeType[], any>({
      query: (body) => ({
        method: 'POST',
        url: `${apiUrl}/search-linked`,
        body
      }),
      transformResponse: (result: NodeType[]) => result?.map((item: NodeType) => ({
        ...item,
        createdBy: { ...item.createdBy, ...extraNames(item.createdBy) },

      })),
    }),

    getNode: builder.query<NodeType, { id: string, showArchived?: boolean }>({
      query: ({ id, showArchived }) => ({
        url: `${apiUrl}/${id}/content?${showArchived ? 'showArchived=true&' : ''}size=500`,
      }),
      providesTags: (_response, _error, { id }) => [{ type: 'NODE', id }],
      transformResponse: (node: NodeType) => ({
        ...node,
        children: {
          ...node.children,
          content: node?.children?.content.map((x: NodeType) => ({ ...x, nodeTypeOrder: NODE_TYPE_ORDER[x.nodeType], author: x?.createdBy?.name }))
        }
      }),
    }),

    getDocumentNode: builder.query<DocumentNodeType, { id: string }>({
      query: ({ id }) => ({
        url: `${apiUrl}/${id}/document`,
      }),
      transformResponse: (result: DocumentNodeType) => ({
        ...result,
        createdBy: {
          ...result.createdBy,
          ...extraNames(result.createdBy)
        },
        lastModifiedBy: {
          ...result.lastModifiedBy,
          ...extraNames(result.lastModifiedBy)
        },
        deletedBy: {
          ...result.deletedBy,
          ...extraNames(result.deletedBy)
        },
      }),
      providesTags: (result, _, arg) => result ? [{ type: 'NODE', id: arg.id }] : [],
    }),

    updateTrends: builder.mutation<NodeType[], NodeType[]>({
      query: (body) => ({
        method: 'POST',
        url: `${apiUrl}/trends`,
        body
      }),
      invalidatesTags: ['NODE', 'TRENDS', 'FAVORITES', 'QUICK_NODES']
    }),

    createFolderNode: builder.mutation<NodeType, NodeType>({
      query: (body) => ({
        method: 'POST',
        url: `${apiUrl}/create/folder`,
        body
      }),
      invalidatesTags: (result) => result ? ['NODE', 'TRENDS'] : []
    }),

    createDocumentNode: builder.mutation<NodeType, NodeType>({
      query: (body) => ({
        method: 'POST',
        url: `${apiUrl}/create/document`,
        body
      }),
      invalidatesTags: (result) => result ? ['NODE', 'TRENDS'] : []
    }),

    renameNode: builder.mutation<NodeType, NodeType>({
      query: ({ id, name }) => ({
        method: 'POST',
        url: `${apiUrl}/${id}/rename`,
        body: { id, name }
      }),
      invalidatesTags: (result) => result ? ['FAVORITES', 'QUICK_NODES', 'NODE'] : []
    }),

    archiveNode: builder.mutation<any, NodeType>({
      query: ({ id }) => ({
        method: 'POST',
        url: `${apiUrl}/archive`,
        body: { id }
      }),
      //invalidatesTags: ['FAVORITES', 'QUICK_NODES', 'NODE']
    }),

    restoreNode: builder.mutation<any, NodeType>({
      query: ({ id }) => ({
        method: 'POST',
        url: `${apiUrl}/restore`,
        body: { id }
      }),
      //invalidatesTags: ['FAVORITES', 'NODE']
    }),

    copyNode: builder.mutation<any, { nodeId: string, destinationId: string }>({
      query: ({ nodeId, destinationId }) => ({
        method: 'POST',
        url: `${apiUrl}/${nodeId}/copy`,
        body: { id: destinationId }
      }),
    }),

    moveNode: builder.mutation<any, { nodeId: string, destinationId: string }>({
      query: ({ nodeId, destinationId }) => ({
        method: 'POST',
        url: `${apiUrl}/${nodeId}/move`,
        body: { id: destinationId }
      }),
    }),

    getNodeResponsible: builder.query<ResponsibleType[], { id: string }>({
      query: ({ id }) => ({
        url: `${apiUrl}/${id}/responsible`,
      }),
      transformResponse: (result: ResponsibleType[]) => result?.map((item: ResponsibleType) => ({
        ...item,
        ...extraNames(item)
      })),
      providesTags: (result) => result
        ? [{ type: 'RESPONSIBLE', id: 'LIST' }, ...result.map(({ id }) => ({ type: 'RESPONSIBLE' as const, id }))]
        : [{ type: 'RESPONSIBLE', id: 'LIST' }],
    }),

    linkNode: builder.mutation<any, any>({
      query: ({ nodeId, ...body }) => ({
        method: 'POST',
        url: `${apiUrl}/${nodeId}/follow`,
        body
      }),
      invalidatesTags: (_result, error, args) => !error ? [{ type: 'NODE', id: args.nodeId }] : []
    }),

    reorderNode: builder.mutation<any, any>({
      query: ({ parentId, ...body }) => ({
        method: 'POST',
        url: `${apiUrl}/update-order`,
        body
      }),
      invalidatesTags: (_result, error, args) => !error ? [{ type: 'NODE', id: args.parentId }] : []
    }),

  })
})

export const {
  useGetAllTrendsQuery,
  useGetNodeQuery,
  useGetNodesFilteringMutation,
  useGetNodeEntityQuery,
  useGetDocumentNodeQuery,
  useLazyGetNodeQuery,
  useGetNodeResponsibleQuery,
  useUpdateTrendsMutation,
  useCreateFolderNodeMutation,
  useCreateDocumentNodeMutation,
  useArchiveNodeMutation,
  useRestoreNodeMutation,
  useRenameNodeMutation,
  useCopyNodeMutation,
  useMoveNodeMutation,
  useLinkNodeMutation,
  useGetNodesSearchMutation,
  useReorderNodeMutation,
} = node

export default node
