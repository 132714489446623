import { commonApi } from "config/common-api"
import { API_URL } from "config/constants"
import { Extension } from "models/extension.model"
import { SearchRequestParams } from "models/search.model"

const apiUrl = `${API_URL}/open-search`

export const search = commonApi.injectEndpoints({
  endpoints: builder => ({
    search: builder.mutation<any, SearchRequestParams[]>({
      query: (body) => ({
        url: `${apiUrl}/kbl/search`,
        method: 'POST',
        body
      }),
    }),
    getExtensions: builder.query<Extension[], void>({
      query: () => ({
        url: `${apiUrl}/extension`
      })
    })
  })
})

export const { useSearchMutation, useGetExtensionsQuery } = search
