export const docSmallIcon = (
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.12569 0C2.50113 0 2 0.50364 2 1.12819V24.3289C2 24.9534 2.50113 25.4545 3.12569 25.4545H20.5455C21.17 25.4545 21.6364 24.9534 21.6364 24.3289V6.18182L15.4545 0H3.12569Z" fill="#ECEDEE" />
    <path d="M15.4545 6.18182H21.6364L15.4545 0V6.18182Z" fill="#CDD1D3" />
    <path d="M0 11.5484C0 10.6932 0.693236 10 1.54839 10H22.4516C23.3068 10 24 10.6932 24 11.5484V21.871C24 22.7261 23.3068 23.4194 22.4516 23.4194H1.54839C0.693236 23.4194 0 22.7261 0 21.871V11.5484Z" fill="#1754B6" />
    <path d="M8.50719 16.7345C8.50719 17.4171 8.37782 17.9869 8.11907 18.4439C7.86582 18.9008 7.50797 19.2422 7.04552 19.4679C6.58306 19.6936 6.03803 19.8065 5.41042 19.8065H3.50281V13.9102H5.54255C6.17567 13.9102 6.71244 14.0203 7.15287 14.2405C7.5933 14.4552 7.92913 14.7718 8.16036 15.1902C8.39158 15.6086 8.50719 16.1234 8.50719 16.7345ZM6.85558 16.7923C6.85558 16.4344 6.80603 16.1371 6.70694 15.9004C6.61334 15.6637 6.4702 15.4875 6.27752 15.3719C6.09033 15.2563 5.85085 15.1985 5.55906 15.1985H5.09661V18.5017H5.45171C5.93618 18.5017 6.29128 18.3613 6.517 18.0805C6.74272 17.7997 6.85558 17.3703 6.85558 16.7923Z" fill="white" />
    <path d="M15.0837 16.8501C15.0837 17.307 15.0287 17.7227 14.9185 18.097C14.8084 18.4659 14.6378 18.7852 14.4065 19.055C14.1753 19.3247 13.878 19.5312 13.5147 19.6743C13.1568 19.8175 12.7247 19.889 12.2182 19.889C11.7227 19.889 11.2933 19.8175 10.9299 19.6743C10.5721 19.5312 10.2748 19.3247 10.038 19.055C9.80681 18.7852 9.63339 18.4659 9.51777 18.097C9.40767 17.7227 9.35261 17.3043 9.35261 16.8418C9.35261 16.2307 9.45446 15.6994 9.65816 15.248C9.86186 14.7911 10.1757 14.4387 10.5996 14.191C11.0235 13.9432 11.5658 13.8194 12.2264 13.8194C12.8981 13.8194 13.4431 13.946 13.8615 14.1992C14.2854 14.447 14.5937 14.7993 14.7864 15.2563C14.9846 15.7077 15.0837 16.239 15.0837 16.8501ZM11.029 16.8501C11.029 17.2024 11.0675 17.508 11.1446 17.7667C11.2272 18.02 11.3566 18.2154 11.5327 18.353C11.7089 18.4907 11.9374 18.5595 12.2182 18.5595C12.5099 18.5595 12.7412 18.4907 12.9118 18.353C13.088 18.2154 13.2146 18.02 13.2917 17.7667C13.3688 17.508 13.4073 17.2024 13.4073 16.8501C13.4073 16.316 13.3192 15.8949 13.1431 15.5866C12.9669 15.2783 12.6613 15.1241 12.2264 15.1241C11.9401 15.1241 11.7089 15.1957 11.5327 15.3388C11.3566 15.4765 11.2272 15.6747 11.1446 15.9334C11.0675 16.1867 11.029 16.4922 11.029 16.8501Z" fill="white" />
    <path d="M18.774 15.1324C18.5868 15.1324 18.4189 15.1737 18.2703 15.2563C18.1216 15.3333 17.995 15.4489 17.8904 15.6031C17.7858 15.7517 17.706 15.9334 17.6509 16.1481C17.5959 16.3628 17.5684 16.6078 17.5684 16.8831C17.5684 17.252 17.6152 17.563 17.7087 17.8163C17.8023 18.0695 17.9455 18.2622 18.1382 18.3943C18.3308 18.5209 18.5731 18.5843 18.8649 18.5843C19.1236 18.5843 19.3741 18.5485 19.6164 18.4769C19.8641 18.4053 20.1118 18.3172 20.3596 18.2126V19.5587C20.1008 19.6743 19.8338 19.7569 19.5585 19.8065C19.2888 19.8615 18.9998 19.889 18.6915 19.889C18.0528 19.889 17.5298 19.7624 17.1224 19.5092C16.715 19.2559 16.415 18.9036 16.2223 18.4521C16.0296 17.9952 15.9333 17.4694 15.9333 16.8748C15.9333 16.4289 15.9938 16.0215 16.1149 15.6526C16.2416 15.2783 16.426 14.9562 16.6682 14.6865C16.9105 14.4112 17.2078 14.1992 17.5601 14.0506C17.9179 13.9019 18.3281 13.8276 18.7905 13.8276C19.0768 13.8276 19.3769 13.8606 19.6907 13.9267C20.0045 13.9928 20.3128 14.0974 20.6156 14.2405L20.1284 15.4957C19.9136 15.3911 19.6962 15.3058 19.476 15.2397C19.2558 15.1682 19.0218 15.1324 18.774 15.1324Z" fill="white" />
  </>
)
