import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import NodeType from 'models/node.model'

type ClipboardActions = 'cut' | 'copy' | 'upload' | 'archive' | 'restore'
type ClipboardItemType = 'node' | 'file' | 'url'
export type Downloadable = {
  name: string,
  url: string
}
export interface ClipboardItem {
  id: string,
  type: ClipboardItemType,
  data: NodeType | File | Downloadable
}
interface ClipboardState {
  op: ClipboardActions,
  source: any,
  selected: NodeType[],
  data: ClipboardItem[]
}

const initialState: ClipboardState = {
  op: undefined,
  source: undefined,
  selected: undefined,
  data: undefined
}

const clipboard = createSlice({
  name: 'clipboard',
  initialState,
  reducers: {
    setSelected: (state: ClipboardState, { payload }: PayloadAction<{ selected: NodeType[] } | undefined>) => {
      state.selected = payload?.selected
    },
    setClipboard: (state: ClipboardState, { payload }: PayloadAction<{ op: ClipboardActions, source?: any, data: any } | undefined>) => {
      state.op = payload?.op
      state.source = payload?.source
      state.data = payload?.data
    }
  }
})

export const {
  setSelected,
  setClipboard
} = clipboard.actions

export default clipboard
