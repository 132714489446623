import { Stack, TextField } from '@mui/material'
import Button from 'components/ui/Button/Button'
import FontIcon from 'components/ui/FontIcon'
import { t } from 'i18next'
import { AccessGroupType } from 'models/access-group.model'
import { useEffect, useState } from 'react'

interface AccessGroupNameProps {
  accessGroup: AccessGroupType
  onEditName: (name: string) => Promise<void>
}

const AccessGroupName = ({ accessGroup, onEditName }: AccessGroupNameProps) => {

  const [name, setName] = useState('')

  useEffect(() => {
    setName(accessGroup?.name || '')
  }, [accessGroup])

  return (
    <Stack spacing='8px' direction='row' alignItems='center' sx={{ pt: '10px', overflow: 'hidden' }}>
      <TextField
        placeholder={t('kbl.labels.accessGroupNameRequired')}
        label='Название группы'
        autoFocus
        size='small'
        fullWidth
        value={name || ''}
        onChange={(event) => setName(event.target.value)}
      />
      <Button color='success' size='small' disabled={name?.length < 1} onClick={() => onEditName(name)}>
        <FontIcon icon='check' sx={{ color: name?.length > 0 ? 'green' : 'action.disabled' }} />
      </Button>
    </Stack>
  )
}

export default AccessGroupName
