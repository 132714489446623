import { Box } from '@mui/material'
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, gridExpandedRowCountSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-premium'
import Button from 'components/ui/Button/Button'
import FontIcon from 'components/ui/FontIcon'
import { useTranslation } from 'react-i18next'
import { DataGridToolbarProps } from '../types'
import { useRef } from 'react'

const DataGridToolbar = (props: DataGridToolbarProps) => {

  const { disableExport = false, setColumnsButtonEl, renderFilters, onAdd } = props 
  const { t } = useTranslation()
  
  const apiRef = useGridApiContext()

  return (
    <GridToolbarContainer id='datagrid-toolbar' sx={{ display: 'flex', width: '100%', alignItems: 'center', padding: 0 }}>

      {Boolean(renderFilters) && (
        <Box sx={{ flex: 1 }}>
          {renderFilters(apiRef)}
        </Box>
      )}        

      <GridToolbarFilterButton
        componentsProps={{
          button: {
            variant: 'outlined',
            size: 'small'
          }
        }}
      />

      <GridToolbarColumnsButton size='small' variant='outlined'
        ref={setColumnsButtonEl}
      />
      
      {!disableExport && (
        <GridToolbarExport
          variant='outlined'
          csvOptions={{ disableToolbarButton: true }}
          printOptions={{ disableToolbarButton: true }}
        />
      )}      

      {onAdd && (
        <Button onClick={onAdd} variant="contained" size='small' startIcon={<FontIcon icon='add' />}>
          {t('kbl.buttons.add')}
        </Button>
      )}

    </GridToolbarContainer>
  )
}

export default DataGridToolbar
