import { Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material"
import FontIcon from "components/ui/FontIcon"
import { AccessGroupType } from "models/access-group.model"
import { EmployeeType } from "models/employee.model"
import { useMemo } from "react"

const Item = ({ fullName, department }: EmployeeType) => (
  <ListItem sx={{ height: '32px', pl: '32px' }}>
    <ListItemIcon sx={{ minWidth: '20px', mr: '8px' }}>
      <FontIcon icon='person' />
    </ListItemIcon>
    <ListItemText primaryTypographyProps={{ variant: 'overline' }}>
      {fullName}
    </ListItemText>
    <ListItemText primaryTypographyProps={{ variant: 'overline' }}>
      {department}
    </ListItemText>
  </ListItem>
)

const AccessGroupsItemContent = (props: AccessGroupType) => {

  const { employee } = props

  const employees = useMemo(() => {
    return [...employee].sort((a, b) => a.lastName.localeCompare(b.lastName))
  }, [employee])

  return (
    <Box
      sx={{
        //backgroundColor: 'rgba(17, 35, 48, 0.05)',
        marginBlock: '0 8px'
      }}
    >
      <List dense sx={{ maxHeight: '224px', overflow: 'auto', borderBottom: '1px solid', borderColor: 'primary.line' }}>
        {employees
          .map(employee => {
            return (
              <Item key={employee.id} {...employee} />
            )
          })}
      </List>
    </Box>
  )
}

export default AccessGroupsItemContent
