import { FormControlLabel, Stack, Switch } from "@mui/material"
import Button from "components/ui/Button/Button"
import ConfirmInput from "components/ui/ConfirmInput/ConfirmInput"
import { ConfirmInputRenderInputParams } from "components/ui/ConfirmInput/types"
import FontIcon from "components/ui/FontIcon"
import { NodeUserRightsType } from "models/node-user-rights"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useGetMeQuery } from "reducers/api/employee.api"
import { AccessGroupRenderInput } from "./AccessGroupRenderInput/AccessGroupRenderInput"
import { EmployeeRenderInput } from "./EmployeeRenderInput/EmployeeRenderInput"

interface NodeRightsToolbarProps {
  onAddEntity: (value: NodeUserRightsType) => void
  onAddRecursiveEntity: (value: NodeUserRightsType) => void
}

const NodeRightsToolbar = ({ onAddEntity, onAddRecursiveEntity }: NodeRightsToolbarProps) => {

  const { t } = useTranslation()
  const [showAddEmployee, setShowAddEmployee] = useState(false)
  const [showAddGroup, setShowAddGroup] = useState(false)
  const [isRecursive, setIsRecursive] = useState(false)

  const { data: meData } = useGetMeQuery()

  const isControlVisible = showAddEmployee || showAddGroup

  const employeeRenderInput = useCallback(({ onChange }: ConfirmInputRenderInputParams<NodeUserRightsType>) => {
    return (
      <EmployeeRenderInput onChange={onChange} />
    )
  }, [])

  const accessGroupRenderInput = useCallback(({ onChange }: ConfirmInputRenderInputParams<NodeUserRightsType>) => {
    return (
      <AccessGroupRenderInput onChange={onChange} />
    )
  }, [])

  return (
    <Stack spacing='10px'>
      {showAddEmployee && (
        <ConfirmInput<NodeUserRightsType>
          renderInput={employeeRenderInput}
          onClose={() => setShowAddEmployee(false)}
          onConfirm={isRecursive ? onAddRecursiveEntity : onAddEntity}
        />
      )}

      {showAddGroup && (
        <ConfirmInput<NodeUserRightsType>
          renderInput={accessGroupRenderInput}
          onClose={() => setShowAddGroup(false)}
          onConfirm={isRecursive ? onAddRecursiveEntity : onAddEntity}
        />
      )}

      {isControlVisible && meData?.role.recordType === 'SUPPORT_ADMIN' && (
        <FormControlLabel
          control={
            <Switch
              size='small'
              onChange={(e) => setIsRecursive(e.target.checked)}
            />
          }
          label={t('kbl.buttons.isRecursive')}
        />
      )}

      {!isControlVisible && (
        <Stack direction='row' spacing='4px'>
          <Button
            size='small'
            variant='outlined'
            disabled={showAddGroup}
            startIcon={<FontIcon icon='group_add' />}
            onClick={() => setShowAddGroup(true)}
            sx={{ width: 'fit-content' }}
          >
            {t('kbl.buttons.addGroup')}
          </Button>
          <Button
            size='small'
            variant='outlined'
            disabled={showAddEmployee}
            startIcon={<FontIcon icon='person_add' />}
            onClick={() => setShowAddEmployee(true)}
            sx={{ width: 'fit-content' }}
          >
            {t('kbl.buttons.addEmployee')}
          </Button>
        </Stack>
      )
      }
    </Stack>
  )
}

export default NodeRightsToolbar
