import { Box, SxProps, Typography } from "@mui/material";

const renderEndAdornment = (adornment: React.ReactNode, sx?: SxProps) => (
  adornment ? (
    <Box sx={{ maxWidth: '40%', display: 'flex', alignItems: 'center' }}>
      {typeof adornment === 'string'
        ? (
          <Typography
            noWrap
            variant='overline'
            sx={{
              opacity: '0.7',
              ...sx,
            }}
          >
            {adornment}
          </Typography>
        )
        : adornment
      }
    </Box>
  )
    : null
)

export default renderEndAdornment
