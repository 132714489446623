import { Stack, Typography } from "@mui/material"
import { GridColDef, GridToolbarQuickFilter, GridValueGetterParams } from "@mui/x-data-grid-premium"
import Button from "components/ui/Button/Button"
import DataGrid from "components/ui/DataGrid/DataGrid"
import { RenderFiltersType } from "components/ui/DataGrid/types"
import FontIcon from "components/ui/FontIcon"
import { renderCell, renderHeader } from "components/utils/datagrid"
import { NodeUserRightsType } from "models/node-user-rights"
import { RightsType } from "models/rights.model"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { remove } from "utils/arrayUtils"
import { getNodeRightsId } from "../utils"
import NodeRightsItemAction from "./NodeAccessItemAction/NodeAccessItemAction"
import NodeRightsItemName from "./NodeRightsItemName/NodeRightsItemName"
import NodeRightsItemRights from "./NodeRightsItemRights/NodeRightsItemRights"

interface NodeRightsListProps {
  data: NodeUserRightsType[]
  isLoading?: boolean
  onRightChange: (data: NodeUserRightsType) => Promise<void>
  onRemove: (data: NodeUserRightsType) => Promise<void>
}

const NodeRightsList = ({ data, isLoading = false, onRightChange: onRightsChange, onRemove }: NodeRightsListProps) => {

  const { t } = useTranslation()
  const [pending, setPending] = useState<string[]>(null)

  const [view, setView] = useState([true, true])

  const isPending = (id: string) => pending?.includes(id)

  const handleRemove = async (nodeRights: NodeUserRightsType) => {
    const id = getNodeRightsId(nodeRights)
    setPending(v => [...(v || []), id])
    await onRemove(nodeRights)
  }

  const handleRightChange = async (nodeRights: NodeUserRightsType, right: RightsType) => {
    const id = getNodeRightsId(nodeRights)
    setPending(v => [...(v || []), id])
    await onRightsChange({ ...nodeRights, right })
    setPending(v => remove(id, v))
  }

  const columns: GridColDef[] = useMemo(() => {

    return [
      {
        headerName: '', field: 'type', minWidth: 30, maxWidth: 46, align: 'center',
        resizable: false,
        valueGetter: (params: GridValueGetterParams) => params.row.employee ? 'employee' : 'accessGroupKbl',
        renderCell: (cell) => <FontIcon icon={cell.value === 'accessGroupKbl' ? 'group' : 'person'} />
      },
      {
        headerName: t('kbl.dataGrid.access'), field: 'name', flex: 1,
        renderHeader: (cell) => {
          return (
            <Stack direction='row' alignItems='center'>
              <Typography sx={{ pr: '16px' }}>{t('kbl.dataGrid.firstName')}</Typography>
              <Button iconOnly size='small' onClick={(e) => { e.stopPropagation(); setView(v => [!v[0], v[1]]) }}>
                <FontIcon icon='group' sx={{ color: view[0] ? 'primary.main' : 'primary.text03' }} />
              </Button>

              <Button iconOnly size='small' onClick={(e) => { e.stopPropagation(); setView(v => [v[0], !v[1]]) }}>
                <FontIcon icon='person' sx={{ color: view[1] ? 'primary.main' : 'primary.text03' }} />
              </Button>
            </Stack>
          )
        },
        valueGetter: (params: GridValueGetterParams) => params.row.employee?.name || params.row.accessGroupKbl?.name,
        renderCell: (cell) => (
          <NodeRightsItemName
            nodeRights={cell.row}
            pending={isPending(getNodeRightsId(cell.row))}
          />
        )
      },
      {
        headerName: t('kbl.dataGrid.rights'), field: 'right', flex: 1,
        resizable: false,
        valueGetter: (params) => params.row.right.name,
        renderCell: (cell) => (
          <NodeRightsItemRights
            nodeRights={cell.row}
            defaultValue={cell.row.right}
            pending={isPending(getNodeRightsId(cell.row))}
            onChange={handleRightChange}
          />
        )
      },
      {
        field: 'actions', type: 'actions', width: 30,
        renderCell: (cell) => (
          <NodeRightsItemAction
            isPending={isPending(getNodeRightsId(cell.row))}
            onClick={() => handleRemove(cell.row)}
          />
        )
      }
    ].map((column: any) => ({ renderCell, renderHeader, ...column }))
  }, [data, pending, view])

  const renderFilters: RenderFiltersType = (apiRef) => {
    return (
      <GridToolbarQuickFilter sx={{ width: '100%', paddingBottom: 0 }} />
    )
  }

  const visibleData = useMemo(() => (
    data?.filter(item => (view[0] && item?.accessGroupKbl) || (view[1] && item?.employee))
  ), [data, view])

  return (
    <DataGrid
      columns={columns}
      rows={visibleData}
      stateKey='node-rights'
      stateStorage='localStorage'
      density='compact'
      disableColumnFilter
      disableColumnMenu
      disableRowSelectionOnClick
      disableColumnSelector
      getRowId={getNodeRightsId}
      loading={isLoading}
      sortingOrder={['asc', 'desc']}
      initialState={{
        sorting: {
          sortModel: [{ field: 'type', sort: 'asc' }, { field: 'name', sort: 'asc' }, { field: 'right', sort: 'asc' }],
        },
      }}
      slotProps={{
        toolbar: {
          disableExport: true,
          renderFilters
        },
      }}
      sx={{
        '& .MuiBadge-badge': {
          display: 'none'
        }
      }}
    />
  )
}

export default NodeRightsList
