import { commonApi } from "config/common-api"
import { API_URL } from "config/constants"

const apiUrl = `${API_URL}/document/attachment`

export const attachment = commonApi.injectEndpoints({
  endpoints: builder => ({

    getAttachment: builder.query<{ url: string }, { attachmentId: string }>({
      query: ({ attachmentId }) => ({
        url: `${apiUrl}/${attachmentId}/presigned-get`,
      }),
    }),

    createAttachment: builder.mutation<{ url: string, attachmentId: string }, { name: string }>({
      query: ({ name }) => ({
        url: `${apiUrl}/presigned-put`,
        method: 'POST',
        body: { name },
      }),
    }),
  })
})

export const {
  useLazyGetAttachmentQuery,
  useCreateAttachmentMutation
} = attachment

export default attachment
