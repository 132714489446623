import ChainStack from "components/ui/ChainStack/ChainStack"
import FontIcon from "components/ui/FontIcon"
import NodeType from "models/node.model"

interface NodeBadgeProps {
  node: NodeType
  color?: string
}

const NodeBadge = ({ node, color = 'white' }: NodeBadgeProps) => {

  const { isQuick, 
			// isHomepage, 
			isFavorite } = node

  const badgeIcons: string[] = [
    // ...(isHomepage ? ['house'] : []),
    ...(isFavorite ? ['star'] : []),
    ...(isQuick ? ['bolt'] : []),
  ]

  return badgeIcons.length > 0
    ? (
      <ChainStack spacing='2px' sx={{ height: 'min-content' }}>
        {badgeIcons.map(badgeIcon => <FontIcon key={badgeIcon} icon={badgeIcon} fill sx={{ color }} />)}
      </ChainStack>
    )
    : null
}

export default NodeBadge
