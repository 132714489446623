import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface SettingsState {
  showDeleted: boolean
}

const initialState: SettingsState = {
  showDeleted: window.localStorage.getItem('settings.showDeleted') === 'true',
}

const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setShowDeleted: (state: SettingsState, { payload }: PayloadAction<boolean>) => {
      window.localStorage.setItem('settings.showDeleted', payload ? 'true' : 'false')
      state.showDeleted = payload
    },
  }
})

export const {
  setShowDeleted,
} = settings.actions

export default settings
