import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { DateRangePickerProps, DateRangePicker as MuiDateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'
import FontIcon from 'components/ui/FontIcon'
import { Dayjs } from 'dayjs'
import 'dayjs/locale/ru'

const DateRangePicker = (props: DateRangePickerProps<Dayjs>) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
      <MuiDateRangePicker
        slots={{ field: SingleInputDateRangeField }}
        slotProps={{
          field: { clearable: true },
          clearButton: { size: 'small' },
          textField: {
            fullWidth: true,
            size: 'small',
            InputProps: { endAdornment: <FontIcon icon='calendar_today' sx={{ ml: '8px', color: 'primary.text03' }} /> }
          }
        }}
        calendars={1}
        {...props}
      />
    </LocalizationProvider>            
  )
}

export default DateRangePicker
