import { Card, CardContent, CardHeader } from "@mui/material"
import React from "react"

interface DocumentCardProps {
  title: string
  children: React.ReactNode
  actions?: React.ReactNode
}

const DocumentCard = (props: DocumentCardProps) => {

  const { title, children, actions } = props

  return (
    <Card variant='outlined'
      sx={{
        height: '100%',
        borderColor: 'rgba(245, 246, 247, 1)',
      }}
    >
      <CardHeader
        title={title}
        titleTypographyProps={{
          variant: 'h2'
        }}
        action={actions}
        sx={{ padding: '12px 16px 0 12px' }}
      />
      <CardContent sx={{ padding: actions ? '0 16px 0 8px' : '16px' }}>
        {children}
      </CardContent>
    </Card>
  )
}

export default DocumentCard
