import { Button, ButtonProps, styled } from '@mui/material'
import { Ref, forwardRef } from 'react'

export interface CustomButtonProps extends ButtonProps {
  iconOnly?: boolean
}

const StyledButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'iconOnly' })<CustomButtonProps>(({ size, fullWidth, iconOnly }) => ({
  width: fullWidth ? '100%' : 'fit-content',
  ...(iconOnly && { paddingInline: size === 'large' ? '10px' : size === 'medium' ? '7px' : '7px' })
}))

const CustomButton = forwardRef(({ iconOnly, size = 'large', fullWidth, children, ...rest }: CustomButtonProps, ref: Ref<HTMLButtonElement>) => {
  return (
    <StyledButton
      ref={ref}
      size={size}
      iconOnly={iconOnly}
      fullWidth={fullWidth}
      {...rest}
    >
      {children}
    </StyledButton>
  )
})

export default CustomButton
