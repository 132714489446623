import { CircularProgress, Divider, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Stack, Typography } from '@mui/material'
import NodeIcon from 'components/Icons/NodeIcon/NodeIcon'
import Button from 'components/ui/Button/Button'
import FontIcon from 'components/ui/FontIcon'
import Link from 'components/ui/Link/Link'
import useActions from 'hooks/useActions'
import { useSort } from 'hooks/useSort'
import NodeType from 'models/node.model'
import { ResponsibleType } from 'models/responsible.model'
import React from 'react'
import { EntitiesSortMode } from 'reducers/toolbar'
//import sortNodes from 'utils/sortNodes'

interface ItemProps {
  icon?: React.ReactNode
  node: NodeType
  pending: boolean
  onDelete: (id: string) => Promise<void>
}

const Item = ({ icon, node, pending, onDelete }: ItemProps) => {
  const { open } = useActions(node)
  return (
    <ListItem
      sx={(theme) => ({
        alignItems: 'flex-start',
        borderBottom: `1px solid ${theme.palette.primary.line}`
      })}
    >
      <ListItemIcon sx={{ minWidth: '20px', mr: '8px' }}>
        {icon}
      </ListItemIcon>
      <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} sx={{ pr: '8px' }}>
        <Link to='#' color={pending ? 'primary.text03' : undefined} onClick={(e) => { e.preventDefault(); open() }}>{node.name}</Link>
      </ListItemText>
      <ListItemSecondaryAction>
        <Button disableRipple onClick={pending ? undefined : () => onDelete(node.id)}>
          {pending ? <CircularProgress size={12} /> : <FontIcon icon='delete' sx={{ color: 'primary.mosmetro' }} />}
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

interface ResponsibleNodeListProps {
  responsible: ResponsibleType
  pending: string[]
  onDelete: (nodeId: string) => Promise<void>
}

const ResponsibleNodeList = ({ responsible, pending = [], onDelete }: ResponsibleNodeListProps) => {

  const sortNodes = useSort()

  return (
    <Stack>
      <Typography variant='h2' sx={{ marginBottom: '8px' }}>{responsible.fullName}</Typography>
      <Typography variant='subtitle1'>{responsible.department}</Typography>
      <Typography variant='subtitle2' sx={{ marginBottom: '16px' }}>{responsible.post}</Typography>
      <Divider />
      <List dense disablePadding>
        {sortNodes(responsible?.node, EntitiesSortMode.TYPE)?.map(item => (
          <Item
            key={item.id}
            icon={<NodeIcon node={item} small />}
            node={item}
            pending={pending?.includes(item.id)}
            onDelete={onDelete}
          />
        ))}
      </List>
    </Stack>
  )
}

export default ResponsibleNodeList
