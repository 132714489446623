import { CircularProgress, Stack } from '@mui/material'
import Button from 'components/ui/Button/Button'
import FontIcon from 'components/ui/FontIcon'

interface AccessGroupsItemActionProps {
  isPending: boolean
  onEdit: () => void
  onDelete: () => void
}

const AccessGroupsItemAction = ({ isPending, onEdit, onDelete }: AccessGroupsItemActionProps) => {
  return (
    isPending
      ? (
        <CircularProgress size={12} />
      )
      : (
        <Stack direction='row'>
          
          <Button iconOnly onClick={onEdit}>
            <FontIcon
              icon='edit'
              sx={{ color: 'primary.main' }}
            />
          </Button>

          <Button iconOnly onClick={onDelete}>
            <FontIcon
              icon='delete'
              sx={{ color: 'primary.mosmetro' }}
            />
          </Button>
        </Stack>
      )
  )
}

export default AccessGroupsItemAction
