import { CircularProgress } from '@mui/material'
import Button from 'components/ui/Button/Button'
import FontIcon from 'components/ui/FontIcon'

interface NodeRightsItemActionProps {
  isPending: boolean
  onClick: () => void
}

const NodeRightsItemAction = ({ isPending, onClick }: NodeRightsItemActionProps) => {
  return (
    isPending
      ? (
        <CircularProgress size={12} />
      )
      : (
        <Button iconOnly onClick={onClick}>
          <FontIcon
            icon='delete'
            sx={{ color: 'primary.mosmetro' }}
          />
        </Button>
      )
  )
}

export default NodeRightsItemAction
