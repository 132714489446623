import { FormControlLabel, Switch } from '@mui/material'
import CheckBox from 'components/controls/CheckBox/CheckBox'
import ChainStack from 'components/ui/ChainStack/ChainStack'
import FontIcon from 'components/ui/FontIcon/FontIcon'
import { useTranslation } from 'react-i18next'

interface SearchToolbarProps {
  searchInResults: boolean
  searchInResultsDisabled: boolean
  advancedSearchChecked: boolean
  setAnchorEl: any
  onSearchInResultsClick: () => void
  onAdvancedSearchClick: () => void
}

const SearchToolbar = ({ searchInResults, searchInResultsDisabled, advancedSearchChecked, setAnchorEl, onSearchInResultsClick, onAdvancedSearchClick }: SearchToolbarProps) => {

  const { t } = useTranslation()

  return (
    <ChainStack gap='16px' justifyContent='space-between' paddingInline='4px'>
      <FormControlLabel
        control={
          <Switch
            disabled={searchInResultsDisabled}
            checked={searchInResults}
            onChange={onSearchInResultsClick}
          />
        }
        label={t('kbl.buttons.searchInResults')}
      />

      <CheckBox
        ref={setAnchorEl}
        label={t('kbl.buttons.advancedSearch')}
        icon={<FontIcon icon='expand_more' sx={{ color: 'primary.texx' }} />}
        checkedIcon={<FontIcon icon='expand_less' sx={{ color: 'primary.main' }} />}
        checked={advancedSearchChecked}
        onClick={onAdvancedSearchClick}
      />
    </ChainStack>
  )
}

export default SearchToolbar
