
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ru from './ru/common.json'

export const resources = {
  ru: {
    translation: ru
  },
} as const

i18n
  .use(initReactI18next)
  .init({
    debug: false,
    lng: 'ru',
    resources,
  })

export default i18n
