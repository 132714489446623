import { Stack } from '@mui/material'
import NodeIcon from 'components/Icons/NodeIcon/NodeIcon'
import ContextMenu from 'components/ui/ContextMenu'
import Link from 'components/ui/Link/Link'
import { CONTEXT_MENU_ITEMS } from 'config/constants'
import { useAppSelector } from 'config/store'
import useActions from 'hooks/useActions'

const contextItems = [
  CONTEXT_MENU_ITEMS.OPEN,
  CONTEXT_MENU_ITEMS.QUICK_NODE,
]

function QuickNode({ item, selected }: any) {

  const contextId = `quick-node-${item.id}`

  const {
    renderItems,
  } = useActions(item)

  const currentContextId = useAppSelector(state => state.context.currentContextId)

  return (
    <ContextMenu
      contextId={contextId}
      items={renderItems(contextItems)}
    >
      <Stack
        spacing='6px'
        direction='row'
        sx={{
          alignItems: 'center',
          borderWidth: '0 0 1px 0',
          borderStyle: 'solid',
          borderColor: 'transparent',
          ...(currentContextId === `quick-node-${item.id}` && {
            pointerEvents: 'none',
            borderColor: 'primary.text03',
            '& .MuiTypography-root': {
              color: 'primary.text03'
            }
          }),
          '& .MuiIcon-root': {
            color: 'text.primary',
          },
          ...(contextId === currentContextId && {
            borderColor: 'primary.main',
            '& .MuiTypography-root': {
              color: 'primary.main',
            },
            '& .MuiIcon-root': {
              color: 'primary.main',
            }
          }),
          '&:hover': {
            cursor: 'pointer',
            borderColor: 'primary.main',
            '& .MuiTypography-root': {
              color: 'primary.main',
            },
            '& .MuiIcon-root': {
              color: 'primary.main',
            }
          }
        }}
      >
        <NodeIcon node={item} width={16} />
        <Link to={`/${item.nodeType === 'DOCUMENT' ? 'document' : 'content'}/${item.id}`}
          sx={{
            textDecoration: 'none',
            color: 'text.primary',
            whiteSpace: 'nowrap',
            '&:hover': {
              borderBottom: 'none',
              textDecoration: 'none',
              color: 'text.primary',
            }
          }}
        >
          {item.name}
        </Link>
      </Stack>
    </ContextMenu>
  )
}

export default QuickNode
