import { Box, Checkbox, Stack } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import CheckBox from "components/controls/CheckBox/CheckBox"
import DateRangePicker from "components/controls/DateRangePicker/DateRangePicker"
import EmployeeAutoComplete from "components/controls/EmployeeAutoComplete/EmployeeAutoComplete"
import AutoComplete from "components/ui/auto-complete/AutoComplete"
import Button from "components/ui/Button/Button"
import FontIcon from "components/ui/FontIcon"
import renderOption from "components/utils/render-option"
import { EmployeeType } from "models/employee.model"
import { Extension } from "models/extension.model"
import { Controller, useFormContext } from "react-hook-form"
import { useGetExtensionsQuery } from "reducers/api/search.api"

const AdvancedSearchForm = () => {

  const { control, getValues, reset } = useFormContext()

  const { data: extensionsData } = useGetExtensionsQuery()

  const handleReset = () => {
    reset({ text: getValues('text') })
  }

  return (
    <Grid container spacing='12px'>
      <Grid xs={12} display='flex' flexDirection='row' alignItems='flex-start' justifyContent='space-between'>
        <Stack direction='row' gap='8px' flexWrap='wrap'>
          <Controller
            name='isStrict'
            control={control}
            render={({ field: { value = false, onChange } }) => (
              <CheckBox checked={value} onChange={onChange} label='Побуквенное соответствие' />
            )}
          />

          <Controller
            name='excludeContent'
            control={control}
            disabled={getValues('isAnnotation')}
            render={({ field: { disabled, value = false, onChange } }) => (
              <CheckBox checked={value} disabled={disabled} onChange={onChange} label='Не искать в содержании' />
            )}
          />

          <Controller
            name='isAnnotation'
            disabled={getValues('excludeContent')}
            control={control}
            render={({ field: { disabled, value = false, onChange } }) => (
              <CheckBox checked={value} disabled={disabled} onChange={onChange} label='Искать в начале  документа' />
            )}
          />

          <Controller
            name='includeArchive'
            control={control}
            render={({ field: { value = false, onChange } }) => (
              <CheckBox checked={value} onChange={onChange} label='Искать в удаленных' />
            )}
          />

        </Stack>
        <Box>
          <Button size='small' variant='text' sx={{ whiteSpace: 'nowrap', mr: '-8px' }} onClick={handleReset}>
            Очистить форму
          </Button>
        </Box>
      </Grid>

      <Grid xs={4}>
        <Controller
          name='author'
          control={control}
          render={({ field: { onChange } }) => (
            <EmployeeAutoComplete
              multiple
              limitTags={4}
              disableClearable={false}
              onChange={(_, value: EmployeeType[]) => onChange(value)}
              renderInputProps={{
                label: 'Автор'
              }}
            />
          )}
        />
      </Grid>

      {/* <Grid xs={6}>
        <TextField size='small' fullWidth placeholder='Направления' />
      </Grid> */}
      <Grid xs={4}>
        <Controller
          control={control}
          name='createDate'
          render={({ field: { value = [null, null], onChange } }) => {
            return (
              <DateRangePicker
                label='Дата создания'
                value={value}
                onChange={value => onChange((value?.[0] === null && value?.[1] === null) ? undefined : value)}
              />
            )
          }}
        />
      </Grid>

      {/* <Grid xs={6}>
        <TextField size='small' fullWidth placeholder='Конкретная папка' />
      </Grid> */}

      <Grid xs={4}>
        <Controller
          name='extension'
          control={control}
          render={({ field: { onChange } }) => (
            <AutoComplete<Extension>
              clientSide
              fullWidth
              multiple
              size='small'
              data={extensionsData}
              disableCloseOnSelect
              renderInputProps={{
                label: 'Тип документа'
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<FontIcon icon='check_box_outline_blank' sx={{ color: 'primary.text03' }} />}
                    checkedIcon={<FontIcon icon='check_box' sx={{ color: 'primary.main' }} />}
                    checked={selected}
                    sx={{
                      padding: 0
                    }}
                  />
                  {renderOption({ label: option.name })}
                </li>
              )}
              onChange={(_, value: Extension[]) => onChange(value)}
            />
          )}
        />
      </Grid>

    </Grid>
  )
}

export default AdvancedSearchForm
