import { Box, Divider, ListItemText } from '@mui/material'
import FontIcon from 'components/ui/FontIcon'
import NodeType from 'models/node.model'
import { useTranslation } from 'react-i18next'

interface ITreeExpand{
	toggleExpand: () => void; 
	data: NodeType[]; 
	expanded: number[]; 
}

const TreeExpand = (props: ITreeExpand) => {
	const { t } = useTranslation();
	const { toggleExpand, data, expanded } = props;
  return (
    <>
      <Divider />
      <Box
        onClick={toggleExpand}
        sx={{display: 'flex', 
									alignItems: 'center', 
									color: '#4696EC', 
									paddingRight: '16px',
									paddingLeft: '12px',
									cursor: 'pointer'}}
      >
        <ListItemText>
          {expanded.length === data.length
            ? t('kbl.title.expandLess')
            : t('kbl.title.expandMore')}
        </ListItemText>
        {
          <FontIcon
            icon={
              expanded.length === data.length
                ? 'expand_less'
                : 'expand_more'
            }
          />
        }
      </Box>
      <Divider />
    </>
  );
};

export default TreeExpand
