import { NodeUserRightsType } from "models/node-user-rights"
import { RightsType } from "models/rights.model"
import RightsAutoComplete from "../../components/RightAutoComplete/RightAutoComplete"

interface NodeRightsItemRightsProps {
  nodeRights: NodeUserRightsType
  defaultValue: RightsType
  pending: boolean
  onChange: (data: NodeUserRightsType, right: RightsType) => Promise<void>
}

const NodeRightsItemRights = ({ nodeRights, defaultValue, pending, onChange }: NodeRightsItemRightsProps) => {

  return (
    <RightsAutoComplete
      disabled={pending}
      defaultValue={defaultValue || ''}
      renderInputProps={{
        variant: 'standard',
        InputProps: {
          disableUnderline: true,
        }
      }}
      sx={{
        '& input': {
          padding: '2px 4px 2px 0 !important',
          cursor: 'pointer',
          fontSize: '13px',
          '&::placeholder': {
            fontSize: '13px'
          }
        }
      }}
      onChange={(_, value) => onChange(nodeRights, value as NodeUserRightsType)}
    />
  )
}

export default NodeRightsItemRights
