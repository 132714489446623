import { Paper, PaperProps, Typography } from "@mui/material"

  const RecentList = ({ children, ...rest }: PaperProps) => {

    return (
      <Paper {...rest}>
        <Typography color='primary.text03' sx={{ padding: `12px 0 12px 16px` }}>
          Последние запросы
        </Typography>
        {children}
      </Paper>
    )
  }

  export default RecentList
