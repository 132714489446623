import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'

interface SplitterProps {
  disabled?: boolean
  initialWidth: string
  minWidth: number
  maxWidth: number
  children: React.ReactNode | React.ReactNode[]
}

export default function Splitter(props: SplitterProps) {

  const { disabled = false, initialWidth, minWidth, maxWidth, children } = props

  const [isResizing, setIsResizing] = useState(false)
  const [newWidth, setNewWidth] = useState(initialWidth)

  const handleMousedown = () => {
    setIsResizing(true)
  }

  const handleMousemove = (e: MouseEvent) => {
    // we don't want to do anything if we aren't resizing.

    if (!isResizing) {
      return
    }

    if (e.clientX > minWidth && e.clientX < maxWidth) {
      setNewWidth(`${e.clientX}px`)
    }
  }

  const handleMouseup = () => {
    setIsResizing(false)
  }

  useEffect(() => {
    document.addEventListener('mousemove', handleMousemove)
    document.addEventListener('mouseup', handleMouseup)
    return () => {
      document.removeEventListener('mousemove', handleMousemove)
      document.removeEventListener('mouseup', handleMouseup)
    }
  }, [isResizing])

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `${newWidth || initialWidth} auto 1fr`,
        overflow: 'auto'
      }}
    >
      <Box sx={{
        display: 'flex',
        overflow: 'auto'
      }}>
        {children[0]}
      </Box>
      <Box
        id="dragger"
        onMouseDown={handleMousedown}
        onDoubleClick={(e) => setNewWidth(initialWidth)}
        sx={{
          width: '5px',
          padding: '4px 0 0',
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: '100',
          ...(disabled
            ? { pointerEvents: 'none' }
            : {
              '&:active, &:hover': {
                cursor: 'ew-resize',
                backgroundColor: 'primary.main',
                opacity: 0.3
              }
            }
          )
        }}
      />
      <Box sx={{
        display: 'flex',
        overflow: 'auto'
      }}>
        {children[1]}
      </Box>
    </Box>
  )
}
