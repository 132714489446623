import { Checkbox } from "@mui/material"
import AutoComplete, { AutoCompleteProps } from "components/ui/auto-complete/AutoComplete"
import FontIcon from "components/ui/FontIcon"
import renderOption from "components/utils/render-option"
import { ResponsibleType } from "models/responsible.model"
import { useEffect } from "react"
import { useGetFilteredResponsibleMutation } from "reducers/api/responsible.api"

const ResponsibleAutoComplete = (props: AutoCompleteProps<ResponsibleType>) => {

  const [getFilteredResponsible, { data: responsibleData, isLoading: isResponsibleLoading }] = useGetFilteredResponsibleMutation()

  useEffect(() => {
    getFilteredResponsible([])
  }, [])

  return (
    <AutoComplete<ResponsibleType>
      open
      freeSolo
      size='small'
      clientSide
      multiple
      limitTags={3}
      data={responsibleData?.content}
      loading={isResponsibleLoading}
      optionLabel='shortName'
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<FontIcon icon='check_box_outline_blank' sx={{ color: 'primary.text03' }} />}
            checkedIcon={<FontIcon icon='check_box' sx={{ color: 'primary.main' }} />}
            checked={selected}
            sx={{
              padding: 0
            }}
          />
          {renderOption({ label: option?.fullName, description: option?.department })}
        </li>
      )}
      {...props}
    />
  )
}

export default ResponsibleAutoComplete
