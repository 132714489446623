import { Stack, Typography } from "@mui/material"
import Loading from "components/Loading/Loading"
import Button from "components/ui/Button/Button"
import { RECENT_GLOBAL_SEARCH, RECENT_GLOBAL_SEARCH_MAX } from "config/constants"
import dayjs from "dayjs"
import { SearchFormParams, SearchRequestParams } from "models/search.model"
import { useEffect, useMemo, useState } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { useSearchMutation } from "reducers/api/search.api"
import { remove } from "utils/arrayUtils"
import AdvancedSearch from "./AdvancedSearch/AdvancedSearch"
import GlobalSearchResults from "./GlobalSearchResults/GlobalSearchResults"
import SearchToolbar from "./SearchToolbar/SearchToolbar"
import SearchAutoComplete from "./components/SearchAutoComplete/SearchAutoComplete"

const GlobalSearch = () => {

	const [searchParams, setSearchParams] = useSearchParams()

	const { t } = useTranslation()

	const [search, { data: searchData, isLoading: isSearchDataFetching, isError: isSearchDataError }] = useSearchMutation()

	const results = useMemo(() => {
		return [...searchData?.content || []].sort((a, b) => {
			if (a.type === 'FOLDER' && b.type !== 'FOLDER') return -1
			return 1
		})
	}, [searchData])

	const [anchorEl, setAnchorEl] = useState<HTMLElement>()
	const [searchInResults, setSearchInResults] = useState(false)
	const [showAdvancedSearch, setShowAdvancedSearch] = useState(false)
	const [recentList, setRecentList] = useState<string[]>(JSON.parse(localStorage.getItem(RECENT_GLOBAL_SEARCH)) || [])

	const [recentQueries, setRecentQueries] = useState<SearchRequestParams[]>([])

	const [searchText, setSearchText] = useState(null)

	const methods = useForm<SearchFormParams>({ defaultValues: { text: '' } })
	const { control, handleSubmit, getValues, setValue, watch, reset } = methods

	const updateRecentList = (text: string) => {
		if (recentList.includes(text) && recentList.length !== RECENT_GLOBAL_SEARCH_MAX) {
			// удаляем text 
			setRecentList(list => [...remove(text, list)])
		}
		else if (recentList.length === RECENT_GLOBAL_SEARCH_MAX) {
			// удаляем последний элемент
			setRecentList(list => [...list.slice(0, list.length - 1)])
		}
		// ставим text на первое место
		setRecentList(list => [text, ...list])
	}

	const removeRecent = (text: string) =>
		setRecentList(list => remove(text, list))

	const handleSearchSubmit = handleSubmit(async ({ text, ...queryParams }: SearchFormParams) => {

		const searchQuery: SearchRequestParams = {
			text,
			parametrizedRequest: true,
			...(showAdvancedSearch
				? {
					...queryParams,
					author: queryParams?.author?.map(aut => aut.userId),
					extension: queryParams?.extension?.map(ext => ext.type),
					createDate: queryParams?.createDate
						? {
							startDate: queryParams.createDate?.[0].format("YYYY-MM-DD"),
							endDate: queryParams?.createDate?.[1].format("YYYY-MM-DD")
						}
						: undefined,
					modifiedDate: queryParams?.modifiedDate
						? {
							startDate: queryParams?.modifiedDate?.[0].format("YYYY-MM-DD"),
							endDate: queryParams?.modifiedDate?.[1].format("YYYY-MM-DD")
						}
						: undefined
				}
				: undefined
			)
		}

		const buildQuery = (query: SearchRequestParams) => recentQueries.reduceRight((next, current) => ({
			...current,
			subRequest: next
		}), { ...query })

		if (!text) return

		if (searchInResults)
			setRecentQueries(list => [...list, searchQuery])
		else
			setRecentQueries([searchQuery])

		const body = searchInResults ? buildQuery(searchQuery) : searchQuery

		setSearchText(null)
		await search([body])
		setSearchParams('text=' + text)
		updateRecentList(text)
		setSearchText(text)
	})

	useEffect(() => {
		if (searchParams.get('text')) {
			setValue('text', searchParams.get('text'))
			handleSearchSubmit()
		}
	}, [])

	useEffect(() => {
		if (recentList.length > 0) {
			localStorage.setItem(RECENT_GLOBAL_SEARCH, JSON.stringify(recentList))
		}
	}, [recentList])

	useEffect(() => {
		if (!searchInResults)
			setRecentQueries([])
	}, [searchInResults])

	watch()

	useEffect(() => {
		const defaultValues = getValues()
		if (defaultValues?.createDate) {
			defaultValues.createDate = [dayjs(defaultValues.createDate[0]), dayjs(defaultValues.createDate[1])]
			reset(defaultValues)
		}
	}, [])

	return (
		<Stack spacing='8px' sx={{ padding: '20px 20px 10px 20px' }} overflow='auto'>
			<Typography variant="h1">{t('kbl.title.search')}</Typography>

			<Stack spacing='16px'>

				<form onSubmit={handleSearchSubmit}>
					<Stack direction='row' spacing='16px' alignItems='center' flex={1}>
						<Controller
							control={control}
							name='text'
							defaultValue={''}
							render={({ field: { value, onChange } }) => (
								<SearchAutoComplete
									data={recentList}
									value={value}
									removeRecent={removeRecent}
									onInputValueChange={(value) => onChange(value)}
									onChange={(_, value) => { onChange(value); handleSearchSubmit() }}
								/>
							)}
						/>

						<Button
							type='submit'
							variant='contained'
							disabled={!getValues('text')}
							onClick={handleSearchSubmit}
						>
							{t('kbl.buttons.search')}
						</Button>
					</Stack>
				</form>

				<SearchToolbar
					searchInResults={searchInResults}
					searchInResultsDisabled={recentQueries.length === 0}
					advancedSearchChecked={showAdvancedSearch}
					setAnchorEl={setAnchorEl}
					onSearchInResultsClick={() => setSearchInResults(v => !v)}
					onAdvancedSearchClick={() => setShowAdvancedSearch(v => !v)}
				/>
			</Stack>

			<FormProvider {...methods}>
				<AdvancedSearch
					anchorEl={anchorEl}
					show={showAdvancedSearch}
				/>
			</FormProvider>

			<Loading showLoading={isSearchDataFetching}>
				{searchData && searchText && (
					<GlobalSearchResults
						searchText={searchText}
						rows={results || []}
					/>
				)}
			</Loading>

			{isSearchDataError && <Typography variant='h6'>Произошла ошибка</Typography>}

		</Stack>
	)
}

export default GlobalSearch
