import { Typography } from '@mui/material'
import { NodeUserRightsType } from 'models/node-user-rights'

interface NodeRightsItemNameProps {
  nodeRights: NodeUserRightsType
  pending: boolean
}

const NodeRightsItemName = ({ nodeRights, pending }: NodeRightsItemNameProps) => {
  const name = nodeRights.accessGroupKbl?.name || nodeRights.employee?.name
  return (
    <Typography
      noWrap
      variant='overline'
      sx={{
        ...(pending && { color: 'primary.text03' }),
        fontWeight: nodeRights?.accessGroupKbl ? '600' : '400'
      }}
    >
      {name}
    </Typography>
  )
}

export default NodeRightsItemName
