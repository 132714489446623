import Button from "components/ui/Button/Button"
import FontIcon from "components/ui/FontIcon"
import { useTranslation } from "react-i18next"

interface ResponsibleToolbarProps {
  onAddAccessGroup: () => void
}

const AccessGroupsToolbar = ({ onAddAccessGroup }: ResponsibleToolbarProps) => {

  const { t } = useTranslation()

  return (
    <Button
      size='small'
      variant='outlined'
      startIcon={<FontIcon icon='add' />}
      onClick={() => onAddAccessGroup()}
      sx={{ width: 'fit-content' }}
    >
      {t('kbl.buttons.addGroup')}
    </Button>
  )
}

export default AccessGroupsToolbar
