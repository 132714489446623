const add = (elements: any[], array: Array<typeof elements[number]>) => {
  const set = new Set(array)
  elements.forEach(el => {
    if (!set.has(el))
      set.add(el)
  })
  return Array.from(set) as Array<typeof elements[number]>
}

const remove = (el: any, array: Array<typeof el>) => {
  const set = new Set(array)
  if (set.has(el))
    set.delete(el)
  return Array.from(set) as Array<typeof el>
}

export { add, remove }

export const getArrayTypeKeys = (obj: object) => {
  return Object.keys(obj) as Array<keyof typeof obj>
}

export const getArrayTypeValues = (obj: object) => {
  return Object.values(obj) as Array<keyof typeof obj>
}
