import { Stack, Typography } from "@mui/material"
import { DataGridPremiumProps, GRID_REORDER_COL_DEF } from "@mui/x-data-grid-premium"
import NodeIcon from "components/Icons/NodeIcon/NodeIcon"
import ChainStack from "components/ui/ChainStack/ChainStack"
import CustomDataGrid from "components/ui/DataGrid/DataGrid"
import { renderCell, renderHeader } from "components/utils/datagrid"
import { useAppSelector } from "config/store"
import useActions from "hooks/useActions"
import NodeType from "models/node.model"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useReorderNodeMutation } from "reducers/api/node.api"
//import sortNodes from "utils/sortNodes"
import dayjs from "dayjs"
import { useSort } from "hooks/useSort"
import { OnSelectType } from "view/types/node"
import Node from "../Node"
import NodeRenaming from "../Node/NodeRenaming/NodeRenaming"

interface EntitiesTableProps {
  entities: NodeType[]
  contextHeader?: string[]
  contextItems: string[]
  enablePreview?: boolean
  onNodesSelect: OnSelectType
}

const EntitiesTable = (props: EntitiesTableProps) => {

  const { entities, enablePreview, onNodesSelect } = props

  const { open } = useActions()

  const [reorder] = useReorderNodeMutation()

  const [apiRef, setApiRef] = useState(null)

  const renaming = useAppSelector(state => state.node.renaming)
  const selected = useAppSelector(state => state.clipboard?.selected)

  const sortNodes = useSort()

  const selectedIds = useMemo(() => selected?.map(x => x.id), [selected])

  const rows = useMemo(() => sortNodes(entities).map(x => ({ ...x, __reorder__: x.name })), [entities])

  const pinnedRows = useMemo(() => entities.filter(x => x.nodeType === 'TREND'), [entities])

  const hasTrends = useMemo(() => pinnedRows?.length > 0, [pinnedRows])

  const columns = useMemo(() => {

    const typeColumn = ({
      headerName: 'Тип', field: 'document', minWidth: hasTrends ? 120 : 70, width: hasTrends ? 120 : 70, resizable: false,
      headerClassName: 'hideRightSeparator',
      renderCell: ({ row }) => {
        const types = {
          'ROOT': 'Направление',
          'TREND': 'Направление',
          'FOLDER': 'Папка',
          'DOCUMENT': row.document?.attachment.extension,
        }
        return (
          <ChainStack spacing='8px' alignItems='center'>
            <Typography>{types[row.nodeType]}</Typography>
          </ChainStack>
        )
      }
    })

    const nameColumn = {
      headerName: 'Имя', field: 'name', flex: 1, minWidth: 130,
      renderCell: ({ row }) => {
        return (
          <Stack spacing='8px' direction='row' alignItems='center'>
            <NodeIcon node={row} small />
            <Node
              key={row.id}
              node={row}
              textOnly={({ onRenamingConfirm }) => renaming && renaming.id === row.id
                ? <NodeRenaming node={row} renaming={renaming} onConfirm={onRenamingConfirm} />
                : (
                  <Typography variant='body1'>
                    {row.name}
                  </Typography>
                )}
            />
          </Stack>
        )
      }
    }

    return [
      {
        ...GRID_REORDER_COL_DEF, // Already contains the right field
        width: 30,
        minWidth: 30,
        headerClassName: 'hideRightSeparator',
      },
      nameColumn,
      ...(!enablePreview ? [typeColumn] : []),
      {
        headerName: 'Дата создания', field: 'createdDate', width: enablePreview ? 120 : 150,
        headerClassName: 'hideRightSeparator',
        valueGetter: ({ row }) => dayjs(row?.createdDate, { locale: 'ru-RU' }).format(enablePreview ? 'DD-MM-YY HH:mm' : 'DD MMM YYYY HH:mm'),
      },
      {
        headerName: 'Автор', field: 'author', width: enablePreview ? 150 : 160,
        headerClassName: 'hideRightSeparator',
      }
    ].map((column: any) => ({ renderCell, renderHeader, sortable: false, ...column }))
  }, [open, renaming, enablePreview, hasTrends])

  const handleRowSelection = useCallback((ids: string[]) => {
    const selectedIDs = new Set(ids)
    const selectedRowData = entities?.filter((row) =>
      selectedIDs.has(row.id.toString())
    )
    onNodesSelect(selectedRowData)
  }, [selected, entities])

  const handleRowOrderChange: DataGridPremiumProps['onRowOrderChange'] = useCallback((data) => {
    reorder({ id: data.row.id, order: rows[data.targetIndex]?.order ?? 1 })
  }, [rows, reorder])

  const handleRowClick: DataGridPremiumProps['onRowClick'] = (params, event) => {
    event.stopPropagation()
    const isRowSelected = selectedIds && selectedIds.includes(params.row.id)
    handleRowSelection(isRowSelected ? selectedIds.filter(x => x !== params.row.id) : [...(selectedIds || []), params.row.id])
  }

  const handleRowDoubleClick: DataGridPremiumProps['onRowDoubleClick'] = (params, event) => {
    event.stopPropagation()
    open(params.row)
  }

  useEffect(() => {
    if (renaming) {
      apiRef?.current?.scrollToIndexes({ colIndex: 0, rowIndex: rows?.length - 1 })
    }
  }, [apiRef, renaming])

  return (
    <CustomDataGrid
      columns={columns}
      rows={rows ?? []}
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      disableColumnReorder
      disableRowSelectionOnClick
      density='compact'
      rowReordering
      sortingOrder={['asc', 'desc']}
      checkboxSelection
      slotProps={{
        toolbar: { disableExport: true }
      }}
      sx={{
        width: '100%',
        '& .MuiDataGrid-pinnedRows': {
          boxShadow: 'none',
          //position: 'relative',
          //backgroundColor: 'transparent',
        },
        '& .MuiDataGrid-cellCheckbox': {
          minWidth: '40px !important',
        },
        '& .MuiDataGrid-columnHeaderCheckbox': {
          minWidth: '40px !important',
          width: '40px !important',
        },
      }}
      pinnedRows={{ top: pinnedRows }}
      rowSelectionModel={selectedIds ?? []}
      getApiRef={setApiRef}
      onRowClick={handleRowClick}
      onRowDoubleClick={handleRowDoubleClick}
      onRowSelectionModelChange={handleRowSelection}
      onRowOrderChange={handleRowOrderChange}
    />
  )
}

export default EntitiesTable
