import { Typography, TypographyProps } from "@mui/material"

interface NodeNameProps extends TypographyProps {
  name: string
}

const NodeName = ({ name, ...rest }: NodeNameProps) => {
  return (
    <Typography
      sx={{
        variant: 'subtitle1',
        overflow: 'hidden',
        wordWrap: 'break-word',
        display: '-webkit-box',
        lineHeight: 'normal',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        textAlign: 'center',
        width: '100px',
        ...rest?.sx,
      }}
    >
      {name}
    </Typography>
  )
}

export default NodeName
