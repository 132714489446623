import { IconButton, Typography } from "@mui/material"
import ChainStack from "components/ui/ChainStack/ChainStack"
import FontIcon from "components/ui/FontIcon"
import NodeType from "models/node.model"

interface PreviewHeaderProps {
  node: NodeType
  isFullscreen: boolean
  onFullscreen: () => void
}

const PreviewHeader = ({ node, isFullscreen, onFullscreen }: PreviewHeaderProps) => {
  return (
    <ChainStack id='preview-header' justifyContent='space-between'>
      <Typography variant='h5' noWrap>{node.document?.name || ""}</Typography>
      <IconButton onClick={onFullscreen}>
        <FontIcon icon={isFullscreen ? 'fullscreen_exit' : 'fullscreen'} />
      </IconButton>
    </ChainStack>
  )
}

export default PreviewHeader
