export const pdfSmallIcon = (
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.76192 0C3.13736 0 2.63623 0.50364 2.63623 1.12819V24.3289C2.63623 24.9534 3.13736 25.4545 3.76192 25.4545H21.1817C21.8062 25.4545 22.2726 24.9534 22.2726 24.3289V6.18182L16.0908 0H3.76192Z" fill="#ECEDEE" />
    <path d="M16.0908 6.18182H22.2726L16.0908 0V6.18182Z" fill="#CDD1D3" />
    <path d="M0 10.6744C0 9.74966 0.749663 9 1.67442 9H22.3256C23.2503 9 24 9.74966 24 10.6744V21.8372C24 22.762 23.2503 23.5116 22.3256 23.5116H1.67442C0.749662 23.5116 0 22.762 0 21.8372V10.6744Z" fill="#8B131D" />
    <path d="M6.38613 13.2285C7.1958 13.2285 7.80008 13.4041 8.19897 13.7554C8.6038 14.1066 8.80622 14.6067 8.80622 15.2556C8.80622 15.5473 8.76455 15.8272 8.6812 16.0951C8.59785 16.357 8.46092 16.5952 8.27041 16.8095C8.08585 17.0179 7.83878 17.1846 7.5292 17.3096C7.21962 17.4287 6.83859 17.4882 6.38613 17.4882H5.9039V19.6047H4.18036V13.2285H6.38613ZM6.35041 14.6216H5.9039V16.0861H6.24324C6.39208 16.0861 6.52901 16.0623 6.65404 16.0147C6.78501 15.9611 6.8892 15.8778 6.96659 15.7647C7.04399 15.6456 7.08269 15.4908 7.08269 15.3003C7.08269 15.0979 7.02315 14.9341 6.90408 14.8091C6.78501 14.6841 6.60045 14.6216 6.35041 14.6216Z" fill="white" />
    <path d="M15.2345 16.2826C15.2345 17.0208 15.0946 17.637 14.8148 18.1312C14.5409 18.6253 14.154 18.9944 13.6539 19.2385C13.1538 19.4826 12.5644 19.6047 11.8857 19.6047H9.8228V13.2285H12.0286C12.7132 13.2285 13.2937 13.3475 13.77 13.5857C14.2462 13.8179 14.6094 14.1602 14.8595 14.6127C15.1095 15.0651 15.2345 15.6218 15.2345 16.2826ZM13.4485 16.3451C13.4485 15.9581 13.3949 15.6367 13.2877 15.3807C13.1865 15.1247 13.0317 14.9341 12.8234 14.8091C12.6209 14.6841 12.362 14.6216 12.0464 14.6216H11.5463V18.1937H11.9303C12.4542 18.1937 12.8382 18.0419 13.0823 17.7382C13.3264 17.4346 13.4485 16.9702 13.4485 16.3451Z" fill="white" />
    <path d="M18.0777 19.6047H16.3809V13.2285H20.1495V14.6127H18.0777V15.8272H19.9888V17.2113H18.0777V19.6047Z" fill="white" />
  </>
)
