// import { debounce } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { GridActionsCellItem, GridColDef, GridFilterModel, GridRowGroupingModel, GridToolbarQuickFilter, ruRU } from '@mui/x-data-grid-premium'
import DataGrid from 'components/ui/DataGrid/DataGrid'
import { RenderFiltersType } from 'components/ui/DataGrid/types'
import FontIcon from 'components/ui/FontIcon'
import { renderHeader } from 'components/utils/datagrid'
import useDebounce from 'hooks/useDebounce'
import { t } from 'i18next'
import { ResponsibleType } from 'models/responsible.model'
import React, { useMemo, useState } from 'react'

interface ResponsibleReferenceTableProps {
  data: ResponsibleType[]
  onResponsibleClick: (data: ResponsibleType) => void
  showResponsibleNode: (data: ResponsibleType) => void
  updateResponsible: (data: ResponsibleType) => void
  deleteResponsible: (data: ResponsibleType) => void
  handleAddResponsible: () => void
  setQueryOptions: React.Dispatch<any>
}

 const ResponsibleReferenceTable = (props: ResponsibleReferenceTableProps) => {
		const [groupingExpansion, setGroupingExpansion] = useState(0);
		const [expansionsInfo, setExpansionsInfo] = useState(
      t('kbl.dataGrid.buttons.expandRows')
    );
  const { debounce } = useDebounce();
  const { data, handleAddResponsible, onResponsibleClick, showResponsibleNode, updateResponsible, deleteResponsible, setQueryOptions } = props
  
		const [rowGroupingModel, setRowGroupingModel] =
   React.useState<GridRowGroupingModel>(['forGroup', 'department']);

  const changeExpansions = ()=>{
			setGroupingExpansion(pre => pre === -1 ? 0 : -1)
			setExpansionsInfo((pre) =>
        pre === t('kbl.dataGrid.buttons.collapseRows')
          ? t('kbl.dataGrid.buttons.expandRows')
          : t('kbl.dataGrid.buttons.collapseRows')
      );
		}

  const referenceColumns: GridColDef<ResponsibleType>[] = useMemo(
    () =>
      [
        {
          field: 'forGroup',
          headerName: t('kbl.dataGrid.group'),
          sortable: false,
          width: 30,
        },
        {
          field: 'department',
          headerName: t('kbl.dataGrid.department'),
          flex: 1,
          sortable: false,
        },
								{
          field: 'fullName',
          headerName: t('kbl.dataGrid.fullName'),
          flex: 1,
          sortable: false,
        },
        {
          field: 'post',
          headerName: t('kbl.dataGrid.post'),
          flex: 1,
          sortable: false,
        },
        {
          field: 'address',
          headerName: t('kbl.dataGrid.address'),
          flex: 1.5,
          align: 'center',
          headerAlign: 'center',
          sortable: false,
        },
        {
          field: 'functional',
          headerName: t('kbl.dataGrid.functional'),
          flex: 1.5,
          sortable: false,
        },
        {
          field: 'email',
          headerName: t('kbl.dataGrid.email'),
          flex: 1,
          sortable: false,
        },
        {
          field: 'phone',
          headerName: t('kbl.dataGrid.phone'),
          flex: 1,
          sortable: false,
        },
        {
          field: 'delegation',
          headerName: t('kbl.dataGrid.delegatingFunctions'),
          headerClassName: 'hideRightSeparator',
          flex: 1.9,
          sortable: false,
        },
        {
          field: 'actions',
          type: 'actions',
          align: 'center',
          headerAlign: 'center',
          getActions: (params) =>
            params.row.id
              ? [
                  <GridActionsCellItem
                    icon={<FontIcon icon='person' />}
                    label={t('kbl.buttons.showResponsibility')}
                    onClick={() => showResponsibleNode(params.row)}
                    showInMenu
                  />,
                  <GridActionsCellItem
                    icon={<FontIcon icon='edit' />}
                    label={t('kbl.buttons.edit')}
                    onClick={() => updateResponsible(params.row)}
                    showInMenu
                  />,
                  <GridActionsCellItem
                    icon={
                      <FontIcon
                        icon='delete'
                        sx={{ color: 'primary.mosmetro' }}
                      />
                    }
                    label={t('kbl.buttons.deleteThisResponsible')}
                    onClick={() => deleteResponsible(params.row)}
                    showInMenu
                  />,
                ]
              : [],
        },
      ].map((column: any) => ({ renderHeader, ...column })),
    []
  );

  const renderFilters: RenderFiltersType = (apiRef) => {
    return (
      <GridToolbarQuickFilter sx={{ width: '100%', paddingBottom: 0 }} />
    )
  }

  // const handleSelectionModelChange = (model: GridRowSelectionModel) => {
  //   console.log(44, model)
  // }

  const handleFilterModelChange = (model: GridFilterModel) => {
				const searchValue = model.quickFilterValues[0]
    const options = searchValue
      ? [
          {
            logicalOperator: 'or',
            field: 'firstName',
            operator: 'like',
            value: searchValue,
          },
          {
            logicalOperator: 'or',
            field: 'lastName',
            operator: 'like',
            value: searchValue,
          },
          {
            logicalOperator: 'or',
            field: 'department',
            operator: 'like',
            value: searchValue,
          },
          {
            logicalOperator: 'or',
            field: 'post',
            operator: 'like',
            value: searchValue,
          },
          {
            logicalOperator: 'or',
            field: 'email',
            operator: 'like',
            value: searchValue,
          },
        ]
      : [];

    model.items.forEach(item => {
      if (item.value)
        options.push({
          logicalOperator: 'and',
          field: item.field,
          operator: 'like',
          value: item.value
        })
    })

    debounce(() => setQueryOptions(options))
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Button size='small' onClick={changeExpansions}>
								{expansionsInfo}
      </Button>
      <DataGrid
        stateKey='responsible'
        stateStorage='localStorage'
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        rows={data || []}
        columns={referenceColumns}
        sx={{ '--DataGrid-cellOffsetMultiplier': 4 }}
        groupingColDef={{
          hideDescendantCount: true,
        }}
        defaultGroupingExpansionDepth={groupingExpansion}
        showDetailOnRowClick
        rowGroupingModel={rowGroupingModel}
        onRowGroupingModelChange={(model) => setRowGroupingModel(model)}
        initialState={{
          columns: {
            columnVisibilityModel: {
              forGroup: false,
              __check__: false,
              department: false,
            },
          },
          sorting: {
            sortModel: [
              { field: '__row_group_by_columns_group__', sort: 'asc' },
              { field: 'number', sort: 'asc' },
            ],
          },
        }}
        disableColumnFilter
        hideFooter
        disableColumnReorder
        autoPageSize
        disableColumnMenu
        disableRowSelectionOnClick
        filterMode='server'
        loading={!data}
        slotProps={{
          toolbar: {
            onAdd: handleAddResponsible,
            renderFilters,
          },
        }}
        onFilterModelChange={handleFilterModelChange}
        onRowDoubleClick={({ row }) => row.id && onResponsibleClick(row)}
      />
    </Box>
  );
}

export default ResponsibleReferenceTable
