import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Account from './Account'
import Tree from './Tree'
import styles from './sidebar.module.scss'
const { version } = require('../../../package.json')

function Sidebar() {

  const { t } = useTranslation()

  return (
    <div className={styles.sidebar}>

      <div className={styles.account}>
        <Account />
      </div>

      <div className={styles.content}>
        <Tree />
      </div>

      <div className={styles.footer}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem'
        }}>
          <img src='/i/mosmetro_logo_only.svg' width='34' /> {t('kbl.header')}
        </Box>
        <Typography sx={{ fontSize: '12px' }}>{version}</Typography>
      </div>
    </div>
  )
}

export default Sidebar
