import { commonApi } from "config/common-api"
import { API_URL } from "config/constants"
import { RightsManyType } from "models/rights.model"

const apiUrl = `${API_URL}/kbl/rights`

export const rights = commonApi.injectEndpoints({
  endpoints: builder => ({

    getRights: builder.query<RightsManyType, void>({
      query: () => ({
        url: `${apiUrl}`,
      }),
      providesTags: ['NODE_RIGHTS'],
    }),
  })
})

export const {
  useGetRightsQuery
} = rights

export default rights
