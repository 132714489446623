import { Box, Stack } from '@mui/material'
import Sidebar from 'components/Sidebar'
import Splitter from 'components/ui/Splitter/Splitter'
import { useAppSelector } from 'config/store'
import usePath from 'hooks/usePath'
import { Route, Routes } from 'react-router'
import Content from './Content'
import GlobalSearch from './Content/GlobalSearch'

function Main() {

  const path = usePath()

  const currentContextId = useAppSelector(state => state.context.currentContextId)

  return (
    <Stack
      sx={{
        display: 'grid',
        gridTemplateColumns: 'auto',
        overflow: 'auto',
        height: '100%',
        width: '100%'
      }}
    >
      <Splitter initialWidth='340px' minWidth={240} maxWidth={600} disabled={Boolean(currentContextId)}>
        <Box
          sx={{
            display: 'grid',
            overflow: 'auto',
            width: '100%'          
          }}
        >
          <Sidebar />
        </Box>
        <Box
          sx={{
            display: 'grid',
            overflow: 'auto',
            width: '100%'          
          }}
        >
          <Routes>
            <Route path='/search' element={<GlobalSearch />} />
            <Route path='/*' element={<Content />} />
          </Routes>
        </Box>
      </Splitter>
    </Stack>
  )
}

export default Main
