import { Icon, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FontIconProps } from './types'

const excludedProps = ['fill', 'strikeThrough', 'rounded', 'tooltip']

const FontIcon = styled(Icon, { shouldForwardProp: (prop) => !excludedProps.includes(prop as string) })<FontIconProps>(({ color, fill, rounded, strikeThrough, fontSize, theme }) => {
  const cssColor: string = color ? theme.palette.action[color] : theme.palette.text.primary
  return ({
    position: 'relative',
    fontSize: '1.3rem !important',
    color,
    ...(fill && { fontVariationSettings: "'FILL' 1" }),
    ...(rounded && {
      color: 'white',
      '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: '50%',
        backgroundColor: cssColor,
        opacity: '0.2',
        zIndex: '-1',
      }
    }),
    ...(strikeThrough && {
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '-2px',
        left: '9px',
        width: '1px',
        height: '120%',
        borderRight: rounded ? '1.5px solid' : '1.2px solid',
        borderColor: color ? color : theme.palette.primary.main,
        backgroundColor: rounded ? undefined : 'rgba(247, 247, 247, 1)',
        transform: 'rotate(135deg)'
      }
    })
  })
})

export default function StyledCustomization({ icon, color, fill, tooltip, strikeThrough, ...rest }: FontIconProps) {
  return (
    <Tooltip title={tooltip}>
      <FontIcon baseClassName="material-symbols-outlined" color={color} fill={fill} strikeThrough={strikeThrough} {...rest}>{icon}</FontIcon>
    </Tooltip>
  )
}
