import { Divider, Stack } from "@mui/material"

export interface ToolbarItem {
  icon?: string
  disabled?: boolean
  divider?: boolean
  expander?: boolean
  primaryColor?: string
  component?: React.ReactNode
  onClick?: any
}


interface ToolbarProps {
  children: React.ReactNode
}

const Toolbar = ({ children }: ToolbarProps) => {
  return (
    <Stack>
      <Divider />
      <Stack
        id='node-toolbar'
        direction='row'
        alignItems='center'
        sx={{
          padding: '2px',
          backgroundColor: 'rgba(0, 0, 0, 0.03)',
        }}
      >
        {children}
      </Stack>
    </Stack>
  )
}

export default Toolbar
