import { GridCellParams, GridToolbarQuickFilter } from "@mui/x-data-grid-premium"
import DataGrid from "components/ui/DataGrid/DataGrid"
import { RenderFiltersType } from "components/ui/DataGrid/types"
import { renderCell, renderHeader } from "components/utils/datagrid"
import { AccessGroupType } from "models/access-group.model"
import { EmployeeType } from "models/employee.model"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { remove } from "utils/arrayUtils"
import ItemName from "../components/ItemName/ItemName"
import AccessGroupItemAction from "./AccessGroupItemAction/AccessGroupItemAction"

interface AccessGroupListProps {
  accessGroup: AccessGroupType
  isLoading: boolean
  onRemove: (employee: EmployeeType) => Promise<void>
}

const AccessGroupList = ({ accessGroup, isLoading, onRemove }: AccessGroupListProps) => {

  const { t } = useTranslation()
  const [pending, setPending] = useState<string[]>(null)

  const handleRemove = async (data: EmployeeType) => {
    setPending(v => [...(v || []), data.id])
    await onRemove(data)
    setPending(v => remove(data.id, v))
  }

  const columns = useMemo(() => [
    {
      headerName: t('kbl.dataGrid.fullName'), field: 'fullName', flex: 1,
      headerClassName: 'hideRightSeparator',
      renderCell: (cell: GridCellParams) => (
        <ItemName name={cell.row.fullName} pending={pending?.includes(cell.row.id)} />
      )
    },
    {
      field: 'actions', type: 'actions', width: 30,
      renderCell: (cell: GridCellParams) => (
        <AccessGroupItemAction
          isPending={pending?.includes(cell.row.id)}
          onDelete={() => handleRemove(cell.row)}
        />
      )
    }
  ].map((column: any) => ({ renderCell, renderHeader, ...column })), [accessGroup, pending])

  const renderFilters: RenderFiltersType = (apiRef) => {
    return (
      <GridToolbarQuickFilter sx={{ width: '100%', paddingBottom: 0 }} />
    )
  }

  return (
    <DataGrid
      columns={columns}
      rows={accessGroup?.employee || []}
      stateKey='access-group'
      stateStorage='localStorage'
      density='compact'
      disableColumnFilter
      disableColumnMenu
      disableRowSelectionOnClick
      disableColumnSelector
      disableColumnReorder
      disableColumnResize
      hideFooter
      autoPageSize
      loading={isLoading}
      showDetailOnRowClick
      sortingOrder={['asc', 'desc']}
      slotProps={{
        toolbar: {
          disableExport: true,
          renderFilters
        },
      }}
    />
  )
}

export default AccessGroupList
