import { Alert, AlertTitle, Button } from '@mui/material'
import FontIcon from 'components/ui/FontIcon'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'

const NoAccess = ({ message }: any) => {

  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const { state } = location

  return (
    <Alert severity="error" sx={{ margin: '20px', height: 'min-content' }}>
      <AlertTitle>
        Ошибка
      </AlertTitle>
      {message || t('kbl.noNodeAccess')}
      {state?.returnUrl && (
        <Button
          variant='text'
          size='small'
          startIcon={<FontIcon icon='arrow_back' sx={{ color: 'primary.main' }} />}
          onClick={() => navigate(state.returnUrl)}>{state?.returnMessage}
        </Button>
      )}
    </Alert>
  )
}

export default NoAccess
