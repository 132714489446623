
export default function FolderIcon({ small }: { small?: boolean }) {
  return (
    small ? (
      <>
        <path d="M2.4 21.1273H9.6C10.9255 21.1273 12 20.0528 12 18.7273V6.72727L8.0941 4.61597C7.74356 4.42649 7.35134 4.32727 6.95286 4.32727H2.4C1.07452 4.32727 0 5.40179 0 6.72727V18.7273C0 20.0528 1.07452 21.1273 2.4 21.1273Z" fill="#FF9F00" />
        <path d="M0 9.12728C0 7.80179 1.07452 6.72728 2.4 6.72728L12 6.72727L21.6 6.72728C22.9255 6.72728 24 7.80179 24 9.12728V18.7273C24 20.0528 22.9255 21.1273 21.6 21.1273L2.4 21.1273C1.07452 21.1273 0 20.0528 0 18.7273V9.12728Z" fill="#FFC928" />
      </>
    )
      : (
        <>
          <path d="M10 69.5141H40C45.5228 69.5141 50 65.0369 50 59.5141V10.3592L33.7048 1.67498C32.2572 0.903517 30.642 0.5 29.0017 0.5H10C4.47715 0.5 0 4.97715 0 10.5V59.5141C0 65.0369 4.47715 69.5141 10 69.5141Z" fill="#FF9F00" />
          <rect y="10.3591" width="100" height="60.1408" rx="10" fill="#FFC928" />
        </>
      )
  )
}
