import AutoComplete, { AutoCompleteProps } from "components/ui/auto-complete/AutoComplete"
import { AccessGroupType } from "models/access-group.model"
import { useTranslation } from "react-i18next"
import { useGetAccesibleGroupsQuery } from "reducers/api/access-group.api"

const GroupAutoComplete = (props: AutoCompleteProps<AccessGroupType>) => {

  const { t } = useTranslation()

  const { data: groupsData, isLoading: isGroupsLoading } = useGetAccesibleGroupsQuery()

  return (
    <AutoComplete<AccessGroupType>
      clientSide
      disableClearable
      size='small'
      placeholder={t('kbl.labels.chooseAccessGroup')}
      data={groupsData?.content}
      loading={isGroupsLoading}
      optionLabel='name'
      {...props}
    />
  )
}

export default GroupAutoComplete
