import { commonApi } from "config/common-api"
import { API_URL } from "config/constants"
import { LinkTypeManyType } from "models/link-type.model"

const apiUrl = `${API_URL}/kbl/kind-link`

export const linkType = commonApi.injectEndpoints({
  endpoints: builder => ({

    getLinkTypes: builder.query<LinkTypeManyType, void>({
      query: () => ({
        url: apiUrl,
      }),
    }),
  }),
})

export const {
  useGetLinkTypesQuery
} = linkType

export default linkType
