import { Typography } from "@mui/material"
import Button, { CustomButtonProps } from "components/ui/Button/Button"
import Dialog from "components/ui/Dialog/Dialog"
import React, { createContext, useCallback, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import useKeysPress from './useKeysPress'

export interface UseConfirmProps {
  title: string
  message?: React.ReactNode
  acceptLabel?: string
  rejectLabel?: string
  acceptColor?: CustomButtonProps['color']
  pending?: boolean
  onAccept?: () => void | Promise<void>
  onReject?: () => void | Promise<void>
  onClose?: () => void
}

const ConfirmDialog = createContext(null)

export const ConfirmDialogProvider = ({ children }) => {

  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const [state, setState] = useState<Partial<UseConfirmProps>>({})
  const { title, message, rejectLabel = t('kbl.buttons.cancel'), acceptLabel = t('kbl.buttons.confirm'), acceptColor, pending, onReject = () => { }, onAccept = () => { }, onClose = () => { } } = state

  const confirm = useCallback((props: UseConfirmProps) => {
    setOpen(true)
    setState(prevProps => ({ ...prevProps, ...props }))
  }, [setOpen, setState])

  const close = () => {
    setOpen(false)
    onClose()
  }

  const handleReject = async () => {
    await onReject()
    close()
  }

  const handleAccept = async () => {
    await onAccept()
    close()
  }

		useKeysPress({ open, handleReject, handleAccept });

  return (
    <ConfirmDialog.Provider value={confirm}>
      {children}
      {open
        ? (
          <Dialog
            open={true}
            onClose={handleReject}
            title={title}
            actions={
              <>
                <Button fullWidth variant='outlined' onClick={handleReject}>{rejectLabel as string}</Button>
                < Button fullWidth variant='contained' color={acceptColor} onClick={handleAccept}>{acceptLabel as string}</Button>
              </>
            }
          >
            <Typography>{message}</Typography>
          </Dialog>
        )
        : null
      }
    </ConfirmDialog.Provider>
  )
}

export default function useConfirm() {
  return useContext(ConfirmDialog)
}
