import { CircularProgress } from '@mui/material'
import Button from 'components/ui/Button/Button'
import FontIcon from 'components/ui/FontIcon'

interface AccessGroupItemActionProps {
  isPending: boolean
  onDelete: () => void
}

const AccessGroupItemAction = ({ isPending, onDelete }: AccessGroupItemActionProps) => {
  return (
    isPending
      ? (
        <CircularProgress size={12} />
      )
      : (
        <Button iconOnly onClick={onDelete}>
          <FontIcon
            icon='delete'
            sx={{ color: 'primary.mosmetro' }}
          />
        </Button>
      )
  )
}

export default AccessGroupItemAction
