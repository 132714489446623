import { Box, Stack, Typography } from "@mui/material"

interface RenderOptions {
  label: React.ReactNode
  description?: React.ReactNode
}

const renderOption = ({ label, description }: RenderOptions) => {
  return (
    <Box
      sx={{
        padding: '2px 14px !important',
        width: '100%',
      }}
    >
      <Stack spacing='2px'>
        {typeof label === 'string'
          ? (
            <Typography variant='subtitle2'>
              {label}
            </Typography>
          )
          : label
        }
        {typeof description === 'string'
          ? (
            <Typography variant='subtitle2' sx={{ opacity: '0.5' }}>
              {description}
            </Typography>
          )
          : description}
      </Stack>
    </Box>
  )
}

export default renderOption
