import { useTranslation } from "react-i18next"

interface ResponsibleHeaderProps {
}

const ResponsibleHeader = (props: ResponsibleHeaderProps) => {

  const { t } = useTranslation()

  return (
    <>
      {`${t('kbl.dialog.title.nodeResponsible')}`}
    </>
  )
}

export default ResponsibleHeader
