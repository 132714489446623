import { FormControl, IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material'
import { useState } from 'react'
import FontIcon from '../FontIcon'

// const PasswordField = styled(TextField)<TextFieldProps>(({ color, theme }) => {
//   return ({
//     fontSize: '1.3rem !important',
//     color: color ? undefined : theme.palette.text.primary
//   })
// })

export default function PasswordField({ sx, ...rest }: TextFieldProps) {

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  };

  return (
    <FormControl variant="outlined" sx={sx}>
      <TextField
        variant='outlined'
        type={showPassword ? 'text' : 'password'}
        InputProps={{
        endAdornment: 
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              { showPassword ? <FontIcon icon='visibility_off' /> : <FontIcon icon='visibility' /> }
            </IconButton>
          </InputAdornment>
        }}
        {...rest}
      />
    </FormControl>    
  )
}
