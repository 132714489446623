import { LoadingButton } from "@mui/lab"
import { Stack, TextField, Typography } from "@mui/material"
import NodeIcon from "components/Icons/NodeIcon/NodeIcon"
import AutoComplete, { AutoCompleteProps } from "components/ui/auto-complete/AutoComplete"
import Button from "components/ui/Button/Button"
import ChainStack from "components/ui/ChainStack/ChainStack"
import Dialog from "components/ui/Dialog"
import FontIcon from "components/ui/FontIcon"
import useActions from "hooks/useActions"
import { LinkType } from "models/link-type.model"
import { LinkedNodeCreate } from "models/node.model"
import { DocumentDetails } from "modules/Main/Content/Document/DocumentCardBlocks/DocumentGeneral"
import { NavigatorPath } from "modules/Main/Content/TopPanel/Navigator/Navigator"
import { useEffect, useMemo, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router"
import { useGetLinkTypesQuery } from "reducers/api/link-type.api"
import { useGetDocumentNodeQuery, useGetNodesSearchMutation, useLinkNodeMutation } from "reducers/api/node.api"

interface RelatedDocumentsProps {
  callbackUrl?: string
}

const SearchResultLabel = ({ name }: any) => {
  return (
    <ChainStack spacing='2px'>
      <NodeIcon node={{ nodeType: "DOCUMENT" }} small />
      <Typography noWrap>{name}</Typography>
    </ChainStack>
  )
}

const SearchResultDescription = (option: any) => {
  const { parents, createdBy, createdDate } = option
  return (
    <Stack overflow='auto'>
      <NavigatorPath
        textOnly
        ancestors={parents}
        typographyProps={{
          variant: 'overline'
        }}
      />
      <ChainStack justifyContent='space-between' sx={{ width: '100%' }}>
        <Typography noWrap variant='overline' color='text.disabled'>
          {createdBy?.fullName}
        </Typography>
        <Typography variant='overline' color='text.disabled'>
          {new Date(createdDate).toLocaleDateString()}
        </Typography>
      </ChainStack>
    </Stack>
  )
}

const RelatedDocuments = (props: RelatedDocumentsProps) => {

  const { callbackUrl } = props

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { nodeId } = useParams()

  const [inputValue, setInputValue] = useState<string | undefined>()

  const { data: node } = useGetDocumentNodeQuery({ id: nodeId })

  const { data: linkTypesData } = useGetLinkTypesQuery()

  const [search, { data: searchData, isLoading: isSearchLoading }] = useGetNodesSearchMutation()

  const [linkNode, { isLoading: isLinkLoading, isSuccess: isLinkSuccess }] = useLinkNodeMutation()

  const methods = useForm<LinkedNodeCreate>()

  const { open } = useActions()

  const { register, control, handleSubmit } = methods

  const handleSearchInputChange: AutoCompleteProps<any>['onInputValueChange'] = (value: string) => {
    setInputValue(undefined)

    search({
      originalNodeId: nodeId,
      name: value,
      nodeTypeSet: [
        "DOCUMENT"
      ]
    })
  }

  const handleFormSubmit = handleSubmit(async (data: any) => {
    const body = {
      id: data.linkedNode.id,
      name: data.linkName,
      kindLinkId: data.linkType.id
    }
    await linkNode({ nodeId: node.id, ...body })
  })

  const rows = useMemo(() => [
    { title: t('kbl.title.name'), value: node?.name },
    { title: t('kbl.title.author'), value: node?.createdBy?.fullName },
    { title: t('kbl.title.createdDate'), value: new Date(node?.createdDate).toLocaleDateString() },
    { title: t('kbl.title.updatedDate'), value: new Date(node?.lastModifiedDate).toLocaleDateString() },
    { title: t('kbl.title.isArchive'), value: node?.isDeleted ? t('kbl.yes') : t('kbl.no') },
  ], [node])

  useEffect(() => {
    if (isLinkSuccess)
      open(node)
  }, [isLinkSuccess])

  return (
    <Dialog
      fullHeight
      onClose={() => open(node)}
      open={true}
      title={'Добавление связи'}
      actions={
        <>
          <Button fullWidth variant='outlined' onClick={() => open(node)}>
            {t('kbl.buttons.cancel')}
          </Button>
          <LoadingButton
            disabled={isLinkLoading}
            loading={isLinkLoading}
            loadingPosition="start"
            startIcon={<FontIcon icon='check' sx={{ color: 'primary.contrastText' }} />}
            fullWidth
            variant='contained'
            color='success'
            onClick={handleFormSubmit}
          >
            {t('kbl.buttons.save')}
          </LoadingButton>
        </>
      }
    >
      <Stack spacing='16px'>
        <Stack spacing='8px'>
          <Typography variant='subtitle1'>
            Исходный документ
          </Typography>
          <DocumentDetails rows={rows} />
        </Stack>

        <Controller
          control={control}
          name='linkedNode'
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <AutoComplete<any>
              fullWidth
              data={searchData}
              loading={isSearchLoading}
              renderInputProps={{
                label: 'Связать с документом',
                InputProps: {
                  startAdornment: inputValue && <NodeIcon node={node} small />,
                },
              }}
              renderOptionProps={(option) => ({
                id: option.id,
                label: <SearchResultLabel {...option} />,
                description: <SearchResultDescription {...option} />,
              })}
              getAdornment={value => value?.path?.[value?.path.length - 1]?.name}
              onChange={(_event, value) => { setInputValue(value); onChange(value) }}
              onInputValueChange={handleSearchInputChange}
            />
          )}
        />

        <Controller
          control={control}
          name='linkType'
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <AutoComplete<LinkType>
              fullWidth
              clientSide
              renderInputProps={{
                label: 'Тип связи',
              }}
              data={linkTypesData?.content}
              onChange={(_event, value) => onChange(value)}
            />
          )}
        />

        <TextField fullWidth label='Наименование связи' {...register('linkName', { required: true })} />
      </Stack>
    </Dialog>
  )
}

export default RelatedDocuments
