// eslint-disable-next-line import/no-extraneous-dependencies
import { LicenseInfo } from "@mui/x-data-grid-premium"
import { generateLicense } from "@mui/x-license-pro"

// Никакого смысла в себе не несут, просто рандомные ключи
const STORAGE_KEY = "KNuV"
const TIMESTAMP_KEY = "sxCU"

type TLicense = {
	[TIMESTAMP_KEY]: number;
	[STORAGE_KEY]: string
}

LicenseInfo.setLicenseKey(
	((): string => {
		const storageLicense = localStorage.getItem(STORAGE_KEY)
		const currentDate = new Date()

		if (storageLicense) {
			const parsedStorageLicense: TLicense = JSON.parse(storageLicense)

			if (parsedStorageLicense[TIMESTAMP_KEY] > currentDate.getTime()) {
				return parsedStorageLicense[STORAGE_KEY]
			}
		}

		const timestamp = currentDate.setFullYear(currentDate.getFullYear() + 1)
		const generatedLicense = generateLicense({
			expiryDate: new Date(timestamp),
			orderNumber: "MUI-123",
			scope: 'premium',
			licensingModel: "subscription",
		})

		localStorage.setItem(
			STORAGE_KEY,
			JSON.stringify({
				[STORAGE_KEY]: generatedLicense,
				[TIMESTAMP_KEY]: timestamp,
			} as TLicense)
		)

		return generatedLicense
	})()
)
