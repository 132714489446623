import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FontIcon from "../../../../components/ui/FontIcon";

interface TitleOfDialogProps {
  employeeUserId?: string
  handleBackClick: () => void
}

const Header = ({ employeeUserId, handleBackClick }: TitleOfDialogProps) => {
  
  const { t } = useTranslation()

  return(
    <Stack direction='row' alignItems='center' spacing='16px'>
      {employeeUserId && (
        <Stack direction='row' alignItems='center' sx={{ color: 'primary.main', cursor: 'pointer' }} onClick={handleBackClick}>
          <FontIcon icon='chevron_left' sx={{ color: 'primary.main' }} />
          <Typography variant='subtitle2'>
            {t('kbl.buttons.toBack')}
          </Typography>
        </Stack>
      )}
      <Box>
        {employeeUserId === '-' && t('kbl.dialog.title.general.createUser')}
        {!employeeUserId && t('kbl.menu.items.employees')}
        {employeeUserId && employeeUserId !== '-' && t('kbl.title.updateUser')}
      </Box>
    </Stack>)
}

export default Header
