import NodeType, { DocumentNodeType } from "models/node.model"

const checkRight = (node: NodeType | DocumentNodeType, right: string) => {
  return node?.right?.[right]
}

const isEditable = (node: NodeType | DocumentNodeType) => checkRight(node, "edit")

export default checkRight
export { isEditable }
