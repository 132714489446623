import { Stack } from "@mui/material"
import Loading from "components/Loading/Loading"
import Dialog from "components/ui/Dialog"
import { AccessGroupType } from "models/access-group.model"
import { EmployeeType } from "models/employee.model"
import { NodeUserRightsType } from "models/node-user-rights"
import { RightsType } from "models/rights.model"
import { useState } from "react"
import { useNavigate, useParams } from "react-router"
import { useCreateNodeRightsMutation, useCreateRecursiveNodeRightsMutation, useGetNodeRightsQuery, useRemoveNodeRightsMutation, useUpdateNodeRightsMutation } from "reducers/api/node-user-rights.api"
import { useGetNodeEntityQuery } from "reducers/api/node.api"
import NodeAccessHeader from "./NodeAccessHeader/NodeAccessHeader"
import NodeAccessName from "./NodeAccessName/NodeAccessName"
import NodeRightsList from "./NodeRightsList/NodeRightsList"
import NodeRightsToolbar from "./NodeRightsToolbar/NodeRightsToolbar"

interface NodeRightsProps {
  callbackUrl?: string
}

export type NodeRightsItem = {
  id?: string
  name: string
  rightName?: string
  accessGroupKbl?: AccessGroupType
  employee?: EmployeeType
  right?: RightsType
}

const NodeRights = (props: NodeRightsProps) => {

  const { callbackUrl } = props

  const navigate = useNavigate()
  const { id: nodeId } = useParams()

  const [newAccessGroupKbl, setNewAccessGroupKbl] = useState<AccessGroupType[]>([])
  const [newEmployees, setNewEmployees] = useState<EmployeeType[]>([])

  const { data: nodeData, isLoading: isNodeLoading } = useGetNodeEntityQuery({ id: nodeId }, {
    skip: !nodeId
  })

  const { data: nodeRightsData, isFetching: isNodeRightsFetching } = useGetNodeRightsQuery({ id: nodeId }, {
    skip: !nodeId
  })

  const [createNodeRights, { isLoading: isCreateNodeRightsLoading }] = useCreateNodeRightsMutation()
  const [createRecursiveNodeRights, { isLoading: isCreateRecursiveNodeRightsLoading }] = useCreateRecursiveNodeRightsMutation()
  const [updateNodeRights, { isLoading: isUpdateNodeRightsLoading }] = useUpdateNodeRightsMutation()
  const [removeNodeRights, { isLoading: isRemoveNodeRightsLoading }] = useRemoveNodeRightsMutation()

  const handleClose = () => navigate(callbackUrl)

  const isLoading = isUpdateNodeRightsLoading || isNodeRightsFetching || isCreateNodeRightsLoading || isCreateRecursiveNodeRightsLoading || isRemoveNodeRightsLoading || isNodeLoading

  const handleAddNodeRights = async (nodeRights: NodeUserRightsType) => {
    const body = {
      ...nodeRights,
      node: { id: nodeData?.id },
    }
    await createNodeRights(body)
  }

  const handleAddRecursiveNodeRights = async (nodeRights: NodeUserRightsType) => {
    const body = {
      ...nodeRights,
      node: { id: nodeData?.id },
    }
    await createRecursiveNodeRights(body)
  }

  const handleUpdateNodeRights = async (nodeRights: NodeUserRightsType) => {
    const body = {
      ...nodeRights,
      node: { id: nodeData?.id },
    }
    await updateNodeRights(body)
  }

  const handleRemoveNodeRights = async (nodeRights: NodeUserRightsType) => {
    await removeNodeRights({ id: nodeRights.id })
  }

  const allData = [
    ...(nodeRightsData || []),
    ...newAccessGroupKbl.map(accessGroupKbl => ({ accessGroupKbl })),
    ...newEmployees.map(employee => ({ employee }))
  ]

  return (
    <Dialog
      fullHeight
      onClose={handleClose}
      open
      title={<NodeAccessHeader />}
    >
      <Loading showLoading={isNodeLoading}>
        <Stack spacing='16px' sx={{ overflow: 'hidden' }}>
          <NodeAccessName node={nodeData} />
          <NodeRightsToolbar
            onAddEntity={handleAddNodeRights}
            onAddRecursiveEntity={handleAddRecursiveNodeRights}
          />
          <NodeRightsList
            data={allData}
            isLoading={isLoading}
            onRightChange={handleAddNodeRights}
            onRemove={handleRemoveNodeRights}
          />
        </Stack>
      </Loading>
    </Dialog>
  )
}

export default NodeRights
