import { Box, useMediaQuery } from '@mui/material'
import { ToolbarItem } from 'components/Toolbar/Toolbar'
import PreviewButton from 'components/controls/PreviewButton/PreviewButton'
import Button from 'components/ui/Button/Button'
import FontIcon from 'components/ui/FontIcon'
import InputFileUpload from 'components/ui/InputFileUpload'
import DropMenu from 'components/ui/drop-menu/drop-menu'
import { CONTEXT_MENU_ITEMS } from 'config/constants'
import { useAppSelector } from 'config/store'
import useActions from 'hooks/useActions'
import useClipboard from 'hooks/useClipboard'
import useKeysPress from 'hooks/useKeysPress'
import usePath from 'hooks/usePath'
import NodeType from 'models/node.model'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setSelected } from 'reducers/clipboard'
import { EntitiesSortDirection, EntitiesSortMode, setLayout, setSortDirection, setSortMode } from 'reducers/toolbar'
import { add } from 'utils/arrayUtils'
import { isEditable } from 'utils/checkRight'
import NoAccess from '../NoAccess/NoAccess'
import NodeToolbar from '../NodeToolbar/NodeToolbar'
import EntitiesList from './EntitiesList/EntitiesList'
import Preview from './Preview/Preview'
import { EntitesLayout } from './types'

// TODO: не удалется нода из контекстого меню
// TODO: не вставляется клипборд из контекстного меню
// TODO: не слать запрос на /move, если source совпадает с destination
// TODO: сделать выбор нод как в проводнике (ctrl, shift, ...)

function Entities(props: any) {
  const { node = {}, error, onArchive, onRestore, onPaste } = props

  const noAccess = error?.status === 403

  const isWidth1200 = useMediaQuery('(min-width:1200px)')
  const isWidth1000 = useMediaQuery('(min-width:1000px)')

  const path = usePath()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { open, download, handleCreateFolderNode } =
    useActions(node)

  const [enablePreview, setEnablePreview] = useState(false)

  const entitiesLayout = useAppSelector(state => state.toolbar.layout)
  const sortMode = useAppSelector(state => state.toolbar.sortMode)
  const sortDirection = useAppSelector(state => state.toolbar.sortDirection)

  const { upload, cut, copy } = useClipboard(node)

  const clipboard = useAppSelector((state) => state.clipboard)
  const selected = clipboard?.selected

  const selectedOne = useMemo(() => selected?.length === 1 ? selected?.[0] : null, [selected])

  const hasNonEditable = useMemo(() =>
    selected?.filter((item) => !isEditable(item)).length > 0, [selected, isEditable])

  const hasDeleted = useMemo(() => selected?.filter((item) => item.isDeleted).length > 0, [selected])

  const hasDeletedOnly = useMemo(() =>
    selected?.filter((item) => item.isDeleted).length === selected?.length, [selected])

  const hasTrends = useMemo(() =>
    selected?.filter(
      (item) => item.nodeType === 'ROOT' || item.nodeType === 'TREND'
    )?.length > 0, [selected])

  const isDownloadAllowed = useMemo(() => selectedOne
    ? selectedOne.nodeType === 'DOCUMENT' && selectedOne.loadStatus === 'READY'
    : false, [selectedOne])

  const isArchiveAllowed = useMemo(() =>
    selected && !hasTrends && !hasNonEditable && !hasDeleted, [selected, hasTrends, hasNonEditable, hasDeleted])

  const isRestoreAllowed = useMemo(() =>
    selected && !hasTrends && !hasNonEditable && hasDeleted, [selected, hasTrends, hasNonEditable, hasDeleted])

  const isClipboardData = useMemo(() =>
    ['cut', 'copy'].includes(clipboard?.op) && clipboard?.data, [clipboard])

  const isCopyCutAllowed = useMemo(() => selected && !hasTrends && !hasNonEditable, [selected, hasTrends, hasNonEditable])

  const previewContent = useMemo(() =>
    enablePreview &&
      selected?.length === 1 &&
      selectedOne.nodeType === 'DOCUMENT' &&
      selectedOne.loadStatus === 'READY'
      ? selectedOne
      : null, [enablePreview, selected, selectedOne])

  const previewContentError = useMemo(() => enablePreview && selectedOne && selectedOne.loadStatus !== 'READY', [enablePreview, selectedOne])
    ? t('kbl.buttons.noPreviewDocument')
    : null

  //const contextHeader = isClipboardData && isEditable(node) ? [CONTEXT_MENU_HEADER.PASTE] : undefined

  const contextItems = useMemo(() => [
    ...(isEditable(node) ? [CONTEXT_MENU_ITEMS.CREATE_FOLDER] : []),
    ...(isEditable(node) ? [CONTEXT_MENU_ITEMS.RESPONSIBLE] : []),
    ...(isEditable(node) ? [CONTEXT_MENU_ITEMS.NODE_RIGHTS] : []),
  ], [isEditable, node])

  const handleDownload = () => {
    download(selected?.[0])
  }

  const handleArchiveClick = async () => {
    onArchive(selected)
    dispatch(setSelected(undefined))
  }

  const handleRestoreClick = async () => {
    onRestore(selected)
    dispatch(setSelected(undefined))
  }

  const handleParentClick = (e: React.MouseEvent) => {
    if (e.defaultPrevented) {
      return
    }
    dispatch(setSelected(undefined))
  }

  const handleSelect = (nodes: NodeType[], e?: React.MouseEvent) => {

    let selected_: NodeType[] = []

    const shiftKey = e?.shiftKey
    const ctrlKey = e?.ctrlKey || e?.altKey // altKey on MAC works as CTRL on Windows
    if (!shiftKey && !ctrlKey) {
      selected_ = nodes?.length > 0 ? nodes : undefined
    }

    if (ctrlKey) {
      selected_ = add(nodes, selected)
    }

    if (shiftKey) {
      // TODO: add shift mode
    }
    dispatch(setSelected({ selected: selected_ }))
  }

  const handleCreateDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.currentTarget.files)
    upload(files)
    e.currentTarget.value = null
  }

  const handleCut = () => {
    cut(selected)
  }

  const handleCopy = () => {
    copy(selected)
  }

  const handleTogglePreview = () => setEnablePreview((v) => !v)

  const handleSortModeChange = (mode: EntitiesSortMode) => {
    dispatch(setSortMode({ sortMode: mode }))
  }

  const handleSortDirectionChange = (direction: EntitiesSortDirection) => {
    dispatch(setSortDirection({ sortDirection: direction }))
  }

  const sortOptions = useMemo(() => [
    { key: 1, label: t(`kbl.sort.${EntitiesSortMode.ORDER}`), selected: sortMode === EntitiesSortMode.ORDER, command: () => handleSortModeChange(EntitiesSortMode.ORDER) },
    { key: 2, label: t(`kbl.sort.${EntitiesSortMode.NAME}`), selected: sortMode === EntitiesSortMode.NAME, command: () => handleSortModeChange(EntitiesSortMode.NAME) },
    { key: 3, label: t(`kbl.sort.${EntitiesSortMode.TYPE}`), selected: sortMode === EntitiesSortMode.TYPE, command: () => handleSortModeChange(EntitiesSortMode.TYPE) },
    { key: 4, label: t(`kbl.sort.${EntitiesSortMode.DATE}`), selected: sortMode === EntitiesSortMode.DATE, command: () => handleSortModeChange(EntitiesSortMode.DATE) },
    { key: 5, label: t(`kbl.sort.${EntitiesSortMode.AUTHOR}`), selected: sortMode === EntitiesSortMode.AUTHOR, command: () => handleSortModeChange(EntitiesSortMode.AUTHOR) },
    { key: 6, divider: true },
    { key: 7, label: '↑ По возрастанию', selected: sortDirection === EntitiesSortDirection.ASC, command: () => handleSortDirectionChange(EntitiesSortDirection.ASC) },
    { key: 8, label: '↓ По убыванию', selected: sortDirection === EntitiesSortDirection.DESC, command: () => handleSortDirectionChange(EntitiesSortDirection.DESC) },
  ], [sortMode, sortDirection])

  const toolbarItems: ToolbarItem[] = [
    {
      icon: 'content_paste',
      disabled: !isEditable(node) || !isClipboardData,
      onClick: () => onPaste(),
    },
    { icon: 'content_cut', disabled: !isCopyCutAllowed, onClick: handleCut },
    { icon: 'content_copy', disabled: !isCopyCutAllowed, onClick: handleCopy },
    { icon: 'download', disabled: !isDownloadAllowed, onClick: handleDownload },
    ...(hasDeletedOnly && selected
      ? [
        {
          icon: 'restore_from_trash',
          disabled: !isEditable(node) || !isRestoreAllowed,
          onClick: handleRestoreClick,
        },
      ]
      : [
        {
          icon: 'delete',
          disabled: !isEditable(node) || !isArchiveAllowed,
          onClick: handleArchiveClick,
          primaryColor: 'primary.mosmetro',
        },
      ]),
    ...(isEditable(node)
      ? [{ divider: true }, { icon: 'create_new_folder', onClick: handleCreateFolderNode }]
      : []),
    { divider: true },
    ...(entitiesLayout === EntitesLayout.GRID
      ? [{ icon: 'table_rows', onClick: () => dispatch(setLayout({ layout: EntitesLayout.TABLE })) }]
      : [{ icon: 'grid_view', onClick: () => dispatch(setLayout({ layout: EntitesLayout.GRID })) }]
    ),
    { divider: true },
    {
      component: (
        <DropMenu
          button={({ open, disabled, onClick }) => (
            <Button
              size='small'
              disabled={disabled}
              startIcon={(
                <FontIcon
                  icon='sort'
                  sx={{ color: disabled ? 'text.disabled' : 'primary.main' }}
                />
              )}
              endIcon={(
                <FontIcon icon={open ? 'expand_less' : 'expand_more'} />
              )}
              onClick={onClick}
            >
              {isWidth1000
                ? <>{t(`kbl.sort.${sortMode}`)} <FontIcon icon={sortDirection === EntitiesSortDirection.ASC ? 'arrow_upward' : 'arrow_downward'} /></>
                : null}
            </Button>
          )}
          options={sortOptions}
          IconProps={{
            tooltip: 'Сортировка'
          }}
        />
      )
    },
    //{ icon: 'more_vert', disabled: true },
    { expander: true },
    {
      component: (
        <PreviewButton
          active={enablePreview}
          label={enablePreview ? t('kbl.buttons.hidePreviewDocument') : t('kbl.buttons.previewDocument')}
          onClick={handleTogglePreview}
        />
      ),
    },
    ...(node && path[0] === 'content' && isEditable(node)
      ? [
        {
          component: (
            <InputFileUpload
              multiple
              size='small'
              sx={{
                border: 'none',
                fontWeight: '800',
                width: 'max-content',
                '&:hover': {
                  border: 'none',
                },
              }}
              onChange={handleCreateDocument}
            >
              {isWidth1200 ? t('kbl.buttons.addDocument') : null}
            </InputFileUpload>
          ),
        },
      ]
      : []),
  ]

  useEffect(() => {
    dispatch(setSelected(undefined))
  }, [path[1]])

  const keysPressActions = {
    onOpen: selectedOne ? () => open(selectedOne) : undefined,
    onEscape: () => dispatch(setSelected(undefined)),
    onDelete: selected ? () => { if (!isEditable(node) || !isArchiveAllowed) return; handleArchiveClick() } : undefined,
    onKeyCopy: selected ? () => { if (!isCopyCutAllowed) return; handleCopy() } : undefined,
    onKeyCut: selected ? () => { if (!isCopyCutAllowed) return; handleCut() } : undefined,
    onKeyPaste: () => { if (!isEditable(node) || !isClipboardData) return; onPaste() },
  }

  useKeysPress({
    ...keysPressActions,
  })

  return (
    <Box
      id='entities'
      sx={{
        display: 'grid',
        overflow: 'auto',
        gridTemplateRows: 'auto 1fr',
        gridAutoRows: 'min-content',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.01)',
        paddingBottom: '20px',
      }}
    >
      {path?.length > 0 && <NodeToolbar items={toolbarItems} />}

      {noAccess ? (
        <NoAccess message={error?.data?.message} />
      ) : (
        <Box
          id='entities-content'
          sx={{
            display: 'grid',
            gridTemplateColumns: enablePreview ? '30% auto 70%' : '1fr',
            overflow: 'auto',
            height: '100%',
          }}
        >
          <Box overflow='auto'>
            <EntitiesList
              parent={node}
              //contextHeader={contextHeader}
              contextItems={contextItems}
              enablePreview={enablePreview}
              onClick={handleParentClick}
              onNodesSelect={handleSelect}
              onPaste={onPaste}
              selectedOne={selectedOne}
            />
          </Box>

          <Preview
            enablePreview={enablePreview}
            previewContent={previewContent}
            previewContentError={previewContentError}
            onClose={() => setEnablePreview(false)}
          />
        </Box>
      )}
    </Box>
  )
}

export default Entities
