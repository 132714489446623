
export default function TrendIcon({ small }: { small?: boolean }) {
  return (
    small
      ? (
        <>
          <path d="M2.88 17.4H24V3.47999H2.88V17.4Z" fill="#41805A" />
          <path d="M1.68 0.599994H24C23.2047 0.599994 22.56 1.2447 22.56 2.03999C22.56 2.83528 23.2047 3.47999 24 3.47999H1.68C0.88471 3.47999 0.24 2.83528 0.24 2.03999C0.24 1.2447 0.88471 0.599994 1.68 0.599994Z" fill="#EEE6D9" />
          <path d="M2.88 17.4H1.44C0.64471 17.4 0 16.7553 0 15.96V2.03999C0 1.2447 0.64471 0.599994 1.44 0.599994H1.68C0.88471 0.599994 0.24 1.2447 0.24 2.03999C0.24 2.83528 0.88471 3.47999 1.68 3.47999H2.88V17.4Z" fill="#346247" />
        </>
      )
      : (
        <>
          <path d="M12 70H100V12H12V70Z" fill="#41805A" />
          <path d="M7 0H100C96.6863 0 94 2.68629 94 6C94 9.31371 96.6863 12 100 12H7C3.68629 12 1 9.31371 1 6C1 2.68629 3.68629 0 7 0Z" fill="#EEE6D9" />
          <path d="M12 70H6C2.68629 70 0 67.3137 0 64V6C0 2.68629 2.68629 0 6 0H7C3.68629 0 1 2.68629 1 6C1 9.31371 3.68629 12 7 12H12V70Z" fill="#346247" />

        </>
      )
  )
}
