import { Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import Button from "../../../../../../components/ui/Button/Button"
import FontIcon from "../../../../../../components/ui/FontIcon"

const ActionAddButton = (enabled: boolean, onClick: () => void) => {
  const { t } = useTranslation()

  return enabled ? (
    <Button
      startIcon={<FontIcon icon='add' sx={{ color: "primary.main" }} />}
      variant="text"
      onClick={onClick}
    >
      <Typography
        sx={{ color: "primary.main", fontWeight: 'normal' }}
        variant="h2"
      >
        {t('kbl.buttons.add')}
      </Typography>
    </Button>
  ) : null
}

export default ActionAddButton
