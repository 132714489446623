import { Box, Stack, Typography } from "@mui/material"
import Loading from "components/Loading/Loading"
import { ACCESS_TOKEN } from "config/constants"
import useActions from "hooks/useActions"
import NodeType from "models/node.model"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
//import { Document, Page } from 'react-pdf'
import DocViewer, { PDFRenderer } from "@cyntler/react-doc-viewer"
import PreviewHeader from "./PreviewHeader/PreviewHeader"
interface PreviewPanelProps {
  node: NodeType
  onClose: () => void
}

const PreviewPanel = (props: PreviewPanelProps) => {

  const { node, onClose } = props

  const { t } = useTranslation()

  const { getPreviewUrl } = useActions(node)

  const [fullscreen, setFullscreen] = useState(false)
  const [previewUrl, setPreviewUrl] = useState<string>()
  const [previewError, setPreviewError] = useState(false)

  const requestHeaders = {
    "Authorization": `Bearer ${sessionStorage.getItem(ACCESS_TOKEN)}`
  }

  const loadPreview = async (document: NodeType) => {
    setPreviewError(false)
    const url = getPreviewUrl(document)
    if (url) {
      setPreviewUrl(url)
    }
    else {
      setPreviewError(true)
    }
  }

  const handleFullscreen = () => setFullscreen(v => !v)

  useEffect(() => {
    if (node?.id) {
      setPreviewUrl(undefined)
      setPreviewError(false)
      loadPreview(node)
    }
  }, [node?.id])

  return (
    <>
      <Stack
        id='preview'
        sx={{
          position: 'relative',
          display: 'grid',
          height: '100%',
          width: '100%',
          overflow: 'auto',
          ...(fullscreen && {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            background: 'white'
          })
        }}
      >
        {!previewError && (
          <>
            {previewUrl
              ? (
                <Stack padding={fullscreen ? '12px' : '12px 0 12px 12px'} overflow='auto'>
                  <PreviewHeader node={node} isFullscreen={fullscreen} onFullscreen={handleFullscreen} />

                  {/* <Document file={previewUrl} options={options}>
                    <Page pageNumber={1} />
                  </Document> */}

                  <DocViewer
                    key={previewUrl + fullscreen}
                    documents={[{ uri: previewUrl, fileType: 'pdf' }]}
                    pluginRenderers={[PDFRenderer]}
                    prefetchMethod="GET"
                    requestHeaders={requestHeaders}
                    config={{
                      header: { disableHeader: true },
                      loadingRenderer: {
                        overrideComponent: () => <Loading showLoading />,
                      },
                      pdfVerticalScrollByDefault: true
                    }}
                    style={{ overflow: 'auto' }}
                  />
                </Stack>
              ) : (
                <Box sx={{ position: 'absolute', height: '100%', width: '100%' }}>
                  <Loading showLoading />
                </Box>
              )}
          </>
        )}
        {previewError && (
          <Box
            id='preview-error'
            sx={{
              display: 'grid',
              width: '100%',
              height: '100%',
              overflow: 'auto',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography color='primary.mosmetro'>{t('kbl.loadingError')}</Typography>
          </Box>
        )}
      </Stack>
    </>
  )
}

export default PreviewPanel
