import Button from "components/ui/Button/Button"
import FontIcon from "components/ui/FontIcon"
import { useTranslation } from "react-i18next"

interface UsersToolbarProps {
  onAddUser: () => void
}

const UsersToolbar = ({ onAddUser }: UsersToolbarProps) => {

  const { t } = useTranslation()

  return (
    <Button
      size='small'
      variant='outlined'
      startIcon={<FontIcon icon='add' />}
      onClick={() => onAddUser()}
      sx={{ width: 'fit-content' }}
    >
      {t('kbl.buttons.addUser')}
    </Button>
  )
}

export default UsersToolbar
