import { Box, ListItemText, SxProps } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import FontIcon from 'components/ui/FontIcon'
import { NOOP } from 'config/constants'
import NodeType from 'models/node.model'
import { ElementType, useCallback, useRef, useState } from 'react'
import { DragDropContext, Draggable, DraggableProvidedDragHandleProps, DropResult, Droppable } from 'react-beautiful-dnd'
import TreeSubItem from '../TreeSubItem'

export interface TreeItemProps {
  node: NodeType
  item?: React.ReactNode
  component?: ElementType
  showActions?: 'always' | 'hover' | 'click'
  draggable?: boolean
  dragHandleProps?: DraggableProvidedDragHandleProps
  icon?: React.ReactNode
  expanded?: boolean
  selected?: boolean
  selectedSubItemId?: string
  setItem?: (node: NodeType) => React.ReactNode
  setSx?: (node: NodeType) => SxProps
  setActions?: (node: NodeType) => React.ReactNode
  onExpand?: (id: string) => void
  onClick?: (node: NodeType) => void
  onDragEnd?: (result: DropResult) => void
}

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  ...draggableStyle,
  userSelect: 'none',
  // change background colour if dragging
  background: isDragging ? 'rgba(0, 0, 0, 0.05)' : undefined,
  // styles we need to apply on draggables
})

export default function TreeItem(props: TreeItemProps) {
  const { component: Component = TreeSubItem, showActions, draggable = false, dragHandleProps = {}, node, item, icon, expanded = false, selected = false, selectedSubItemId, setItem, setSx = NOOP, setActions, onExpand = NOOP, onClick, onDragEnd = NOOP } = props

  const itemRef = useRef(null)

  const actions = setActions ? setActions(node) : undefined

  const [isDragging, setIsDragging] = useState(false)

  const handleClick = () => {
    onClick(node)
  }

  const handleCollapseClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onExpand(node.id)
  }

  const buildChildren = useCallback((items: NodeType[] = []) => {

    const sortedItems = [...items].sort((a, b) => a.order > b.order ? 1 : -1)

    const renderComponent = (treeitem: NodeType, last: boolean, provided?: any) => (
      <Component
        draggable={draggable}
        dragHandleProps={provided?.dragHandleProps}
        isDragging={isDragging}
        showActions={showActions}
        key={treeitem?.id}
        node={treeitem}
        item={setItem(treeitem)}
        last={last}
        selected={treeitem?.id === selectedSubItemId}
        onClick={onClick}
        setActions={setActions}
        setSx={setSx}
      />
    )

    return (
      <>
        {sortedItems.map((treeitem: NodeType, idx: number) => {
          const last = idx === items.length - 1
          return (
            draggable
              ? <Draggable key={treeitem.id} draggableId={treeitem.id} index={idx}>
                {(provided, snapshot) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    {renderComponent(treeitem, last, provided)}
                  </Box>
                )}
              </Draggable>
              : renderComponent(treeitem, last)
          )
        })}
      </>
    )
  }, [node, item, isDragging, selectedSubItemId])

  const handleDragStart = () => {
    setIsDragging(true)
  }

  const handleDragEnd = (result: DropResult) => {
    setIsDragging(false)
    onDragEnd(result)
  }

  // useEffect(() => {
  //   if (selected)
  //     itemRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
  // }, [selected])

  return (
    <>
      <ListItemButton
        ref={itemRef}
        selected={selected}
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto auto 1fr auto auto',
          '&.Mui-focusVisible': {
            backgroundColor: 'transparent',
          },
          '&:hover': {
            color: 'primary.main',
            backgroundColor: 'primary.main01',
            ...(actions &&
              showActions === 'hover' && {
                '& .MuiBox-root:last-of-type': {
                  display: 'flex',
                },
              }),
          },
          ...(draggable && { paddingLeft: 0 }),
          transition: 'all 0.3s',
          ...setSx(node),
        }}
        onClick={handleClick}
      >
        <Box
          {...dragHandleProps}
          sx={{
            width: draggable ? '1rem' : 0,
            marginLeft: draggable ? '8px' : 0,
            marginRight: draggable ? '8px' : 0,
            transition: 'all 0.3s',
          }}
        >
          {draggable && <FontIcon icon='drag_indicator' />}
        </Box>

        <ListItemIcon
          sx={{
            minWidth: '39px',
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            noWrap: true,
            variant: 'h2',
          }}
        >
          {item || node.name}
        </ListItemText>

        {actions && (
          <Box
            component='div'
            sx={{
              padding: '0 0 0 5px',
              display: showActions === 'always' ? 'flex' : 'none',
            }}
          >
            {actions}
          </Box>
        )}

        {node.children?.length > 0 && (
          <Box component='div' onClick={handleCollapseClick} sx={{ padding: '5px 0 0 5px' }}>
            {expanded ? <FontIcon icon='expand_less' /> : <FontIcon icon='expand_more' />}
          </Box>
        )}
      </ListItemButton>

      {node.children && (
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          {draggable ? (
            <DragDropContext
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
              <Droppable droppableId={node.id}>
                {(provided, snapshot) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    //style={getListStyle(snapshot.isDraggingOver)}
                  >
                    <List component='div' disablePadding>
                      {buildChildren(node.children)}
                    </List>
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <List component='div' disablePadding>
              {buildChildren(node.children)}
            </List>
          )}
        </Collapse>
      )}
    </>
  );
}
