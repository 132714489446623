export const xlsIcon = (
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.09564 0C7.37812 0 6 1.38501 6 3.10253V66.9044C6 68.6219 7.37812 70 9.09564 70H57C58.7175 70 60 68.6219 60 66.9044V17L43 0H9.09564Z" fill="#ECEDEE" />
    <path d="M43 17H60L43 0V17Z" fill="#CDD1D3" />
    <path d="M0 33C0 31.3431 1.34315 30 3 30H40C41.6569 30 43 31.3431 43 33V53C43 54.6569 41.6569 56 40 56H3C1.34314 56 0 54.6569 0 53V33Z" fill="#0E7A40" />
    <path d="M17.9653 49H14.3973L12.1733 45.432L9.96531 49H6.47731L10.2693 43.16L6.70131 37.576H10.1253L12.1893 41.112L14.1733 37.576H17.6933L14.0773 43.4L17.9653 49Z" fill="white" />
    <path d="M19.2398 49V37.576H22.3278V46.504H26.7278V49H19.2398Z" fill="white" />
    <path d="M36.0243 45.528C36.0243 46.1467 35.8643 46.7333 35.5443 47.288C35.235 47.8427 34.7496 48.296 34.0883 48.648C33.4376 48.9893 32.5896 49.16 31.5443 49.16C31.0216 49.16 30.563 49.1333 30.1683 49.08C29.7843 49.0373 29.4216 48.9627 29.0803 48.856C28.739 48.7493 28.387 48.6107 28.0243 48.44V45.688C28.643 45.9973 29.267 46.2373 29.8963 46.408C30.5256 46.568 31.0963 46.648 31.6083 46.648C31.9176 46.648 32.1683 46.6107 32.3603 46.536C32.5523 46.4613 32.6963 46.36 32.7923 46.232C32.8883 46.104 32.9363 45.96 32.9363 45.8C32.9363 45.5973 32.867 45.4267 32.7283 45.288C32.5896 45.1493 32.371 45.0053 32.0723 44.856C31.7736 44.7067 31.3736 44.52 30.8723 44.296C30.4456 44.104 30.0616 43.9013 29.7203 43.688C29.379 43.4747 29.0856 43.2347 28.8403 42.968C28.6056 42.7013 28.4243 42.392 28.2963 42.04C28.1683 41.6773 28.1043 41.2507 28.1043 40.76C28.1043 40.024 28.2856 39.4107 28.6483 38.92C29.011 38.4187 29.5123 38.0453 30.1523 37.8C30.803 37.544 31.555 37.416 32.4083 37.416C33.155 37.416 33.827 37.5013 34.4243 37.672C35.0216 37.832 35.555 38.0187 36.0243 38.232L35.0803 40.616C34.5896 40.392 34.1043 40.216 33.6243 40.088C33.155 39.9493 32.7176 39.88 32.3123 39.88C32.0456 39.88 31.827 39.912 31.6563 39.976C31.4856 40.04 31.3576 40.1307 31.2723 40.248C31.1976 40.3547 31.1603 40.4773 31.1603 40.616C31.1603 40.7973 31.2296 40.9573 31.3683 41.096C31.507 41.2347 31.7363 41.3893 32.0563 41.56C32.387 41.72 32.835 41.928 33.4003 42.184C33.955 42.4293 34.4243 42.7013 34.8083 43C35.203 43.288 35.5016 43.6347 35.7043 44.04C35.9176 44.4347 36.0243 44.9307 36.0243 45.528Z" fill="white" />
  </>
)
