import { commonApi } from "config/common-api"
import { API_URL } from "config/constants"
import { AccessGroupManyType, AccessGroupType } from "models/access-group.model"
import { EmployeeType } from "models/employee.model"
import { extraNames } from "./utils"

const apiUrl = `${API_URL}/kbl/access-group-kbl`

export const accessGroups = commonApi.injectEndpoints({
  endpoints: builder => ({

    getGroups: builder.query<AccessGroupManyType, void>({
      query: () => ({
        url: `${apiUrl}`,
      }),
      providesTags: ['ACCESS_GROUPS'],
      transformResponse: (result: AccessGroupManyType) => ({
        ...result,
        content: result.content.map((item: AccessGroupType) => ({
          ...item,
          employee: item.employee.map((employee: EmployeeType) => ({
            ...employee,
            test: 1,
            ...extraNames(employee)
          }))
        }))
      }),
    }),

    getAccesibleGroups: builder.query<AccessGroupManyType, void>({
      query: () => ({
        url: `${apiUrl}/accessible`,
      }),
      providesTags: ['ACCESS_GROUPS'],
    }),

    getGroup: builder.query<AccessGroupType, { id: string }>({
      query: ({ id }) => ({
        url: `${apiUrl}/${id}`,
      }),
      providesTags: ['ACCESS_GROUPS'],
      transformResponse: (result: AccessGroupType) => ({
        ...result, employee: result.employee.map((item: EmployeeType) => ({
          ...item,
          ...extraNames(item)
        }))
      }),
    }),

    getFilteredGroups: builder.mutation<AccessGroupManyType, any>({
      query: (body) => ({
        method: 'POST',
        url: `${apiUrl}/filtering?size=500`,
        body,
      }),
    }),

    createGroup: builder.mutation<any, { name: string }>({
      query: ({ name }) => ({
        method: 'POST',
        url: `${apiUrl}`,
        body: { name },
      }),
      invalidatesTags: ['ACCESS_GROUPS']
    }),

    updateGroup: builder.mutation<any, AccessGroupType>({
      query: (body) => ({
        method: 'PUT',
        url: `${apiUrl}/${body.id}`,
        body,
      }),
      invalidatesTags: ['ACCESS_GROUPS']
    }),

    removeGroup: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        method: 'DELETE',
        url: `${apiUrl}/${id}`,
      }),
      invalidatesTags: ['ACCESS_GROUPS']
    }),

    addEmployee: builder.mutation<any, { employeeId: string, accessGroupId: string }>({
      query: ({ employeeId, accessGroupId }) => ({
        method: 'POST',
        url: `${apiUrl}/add-employee/${employeeId}`,
        body: { id: accessGroupId }
      }),
      invalidatesTags: ['ACCESS_GROUPS']
    }),

    removeEmployee: builder.mutation<any, { employeeId: string, accessGroupId: string }>({
      query: ({ employeeId, accessGroupId }) => ({
        method: 'POST',
        url: `${apiUrl}/remove-employee/${employeeId}`,
        body: { id: accessGroupId }
      }),
      invalidatesTags: ['ACCESS_GROUPS']
    })

  })
})

export const {
  useGetGroupsQuery,
  useGetAccesibleGroupsQuery,
  useGetGroupQuery,
  useGetFilteredGroupsMutation,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useRemoveGroupMutation,
  useAddEmployeeMutation,
  useRemoveEmployeeMutation
} = accessGroups

export default accessGroups
