import { IconButton, Stack, TextField } from "@mui/material"
import Button from 'components/ui/Button/Button'
import FontIcon from "components/ui/FontIcon/FontIcon"
import { useAppDispatch, useAppSelector } from "config/store"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { setLocalSearchValue } from "reducers/localSearch"

interface SearchControlsProps {
	showLocalSearch: boolean
}

const SearchControls = ({ showLocalSearch }: SearchControlsProps) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const { value: searchValue, disabled: localSearchDisabled } = useAppSelector(state => state.localSearch)

	const setSearchValue = (value: string) => {
		dispatch(setLocalSearchValue(value))
	}

	const handleGlobalSearch = () => {
		navigate(searchValue ? `/search?text=${searchValue}` : '/search')
	}

	return (
		<Stack spacing='16px' id='search'>
			<Stack direction='row' gap='16px' alignItems='flex-end' justifyContent='flex-end'>
				{showLocalSearch && (
					<TextField
						id='node-search'
						variant='standard'
						placeholder={t('kbl.labels.searchInCurrFolder')}
						value={searchValue}
						disabled={localSearchDisabled}
						InputProps={{
							endAdornment: searchValue?.length > 0 ? (
								<IconButton size='small' onClick={() => setSearchValue('')}>
									<FontIcon icon='close' />
								</IconButton>
							) : ''
						}}
						onChange={(e) => {
							setSearchValue(e.target.value)
						}}
						sx={{
							width: '200px'
						}}
					/>
				)}
				<Button variant='outlined' size='small' onClick={handleGlobalSearch} sx={{ width: 'max-content' }}>
					{t('kbl.buttons.globalSearch')}
				</Button>
			</Stack>
		</Stack>
	)
}

export default SearchControls
