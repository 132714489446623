import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface LocalSearchState {
  value: string
  disabled: boolean
}

const initialState: LocalSearchState = {
  value: '',
  disabled: true
}

const localSearch = createSlice({
  name: 'localSearch',
  initialState,
  reducers: {
    setLocalSearchDisabled: (state: LocalSearchState, { payload }: PayloadAction<boolean>) => {
      state.disabled = payload
    },
    setLocalSearchValue: (state: LocalSearchState, { payload }: PayloadAction<string>) => {
      state.value = payload
    },
  }
})

export const {
  setLocalSearchDisabled,
  setLocalSearchValue,
} = localSearch.actions

export default localSearch
