import { useRef } from "react"

interface UseDebounceProps {
  delay: number
}

const useDebounce = (props?: UseDebounceProps) => {

  const { delay = 500 } = props || {} as UseDebounceProps

  const timerDebounceRef = useRef(null)

  const debounce = (onDebounce?: Function) => {

    if (!onDebounce) {
      clearTimeout(timerDebounceRef.current)
      return
    }

    if (timerDebounceRef.current) {
      clearTimeout(timerDebounceRef.current)
    }
    timerDebounceRef.current = setTimeout(onDebounce, delay)
  }

  return { timerDebounceRef, debounce }
}

export default useDebounce
