import { Typography } from "@mui/material"
import { GridCellParams, GridColumnHeaderParams } from "@mui/x-data-grid-premium"

export const renderHeader = (header: GridColumnHeaderParams) => {
  return (
    <Typography variant='overline' color='text.disabled'>
      {header.colDef?.headerName}
    </Typography>
  )
}

export const renderCell = (cell: GridCellParams) => (
  <Typography variant='body1' noWrap>
    {cell.value as string}
  </Typography>
)
