import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import { NOOP } from 'config/constants'
import React from 'react'
import FontIcon from '../FontIcon'
export interface DialogProps extends Omit<MuiDialogProps, 'title'> {
  title?: React.ReactNode
  fullHeight?: boolean
  children?: React.ReactNode
  actions?: React.ReactNode
  onClose?: () => void
}

export default function Dialog(props: DialogProps) {

  const { open, title, fullHeight = false, children, actions, onClose = NOOP, ...rest } = props

  return (
    <MuiDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={fullHeight
        ? {
          sx: {
            minHeight: 'calc(100% - 64px)',
            '& .MuiDialogContent-root': {
              display: 'grid',
              overflow: 'auto',
              paddingBottom: 0
            }
          }
        }
        : undefined
      }    
      {...rest}
    >
      <DialogTitle id="customized-dialog-title" variant='h1'>
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 4,
          top: 4,
        }}
      >
        <FontIcon icon='close' sx={{ fontSize: '24px !important' }} />
      </IconButton>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        {children}
      </DialogContent>
      {actions && (
        <DialogActions>
          {actions}
        </DialogActions>
      )}
    </MuiDialog>
  )
}
