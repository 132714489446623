import Button, { ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import FontIcon from '../FontIcon'

interface InputFileUploadProps extends Omit<ButtonProps, 'onChange'> {
  multiple: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

export default function InputFileUpload({ children, multiple, onChange, ...rest }: InputFileUploadProps) {
  return (
    <Button
      component="label"
      variant="outlined"
      startIcon={<FontIcon icon='note_add' />}
      {...rest}
    >
      {children}
      <VisuallyHiddenInput multiple={multiple} type="file" onChange={onChange} />
    </Button>
  )
}
