import { Stack } from "@mui/material"
import NodeType from "models/node.model"
import QuickNode from "./QuickNode/QuickNode"

interface QuickNodesListProps {
  parent: NodeType
  data: NodeType[]
}

const QuickNodesList = ({ parent, data }: QuickNodesListProps) => {
  return (
    <Stack
      id='quick-nodes-list'
      direction='row'
      gap='16px'
      sx={{
        overflow: 'auto',
        paddingBottom: '10px',
      }}
    >
      {data.map((item) => {
        return (
          <QuickNode key={item.id} item={item} selected={item.id === parent?.id} />
        )
      })}
    </Stack>
  )
}

export default QuickNodesList
