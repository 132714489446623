export const pngSmallIcon = (
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.30733 0C2.68277 0 2.18164 0.50364 2.18164 1.12819V24.3289C2.18164 24.9534 2.68277 25.4545 3.30733 25.4545H20.7271C21.3516 25.4545 21.818 24.9534 21.818 24.3289V6.18182L15.6362 0H3.30733Z" fill="#ECEDEE" />
    <path d="M15.6362 6.18182H21.818L15.6362 0V6.18182Z" fill="#CDD1D3" />
    <path d="M0 11.3333C0 10.597 0.596954 10 1.33333 10H22.6667C23.403 10 24 10.597 24 11.3333V22C24 22.7364 23.403 23.3333 22.6667 23.3333H1.33333C0.596954 23.3333 0 22.7364 0 22V11.3333Z" fill="#FF9F00" />
    <path d="M5.91612 14.0776C6.61948 14.0776 7.1444 14.2301 7.49091 14.5353C7.84259 14.8404 8.01842 15.2748 8.01842 15.8385C8.01842 16.092 7.98222 16.335 7.90982 16.5678C7.83741 16.7953 7.71847 17.0022 7.55297 17.1884C7.39265 17.3694 7.17802 17.5142 6.90909 17.6228C6.64016 17.7262 6.30917 17.7779 5.91612 17.7779H5.49721V19.6165H4V14.0776H5.91612ZM5.88509 15.2878H5.49721V16.56H5.792C5.92129 16.56 6.04024 16.5393 6.14885 16.4979C6.26263 16.4514 6.35313 16.379 6.42036 16.2807C6.4876 16.1773 6.52121 16.0428 6.52121 15.8773C6.52121 15.7015 6.46949 15.5593 6.36606 15.4507C6.26263 15.3421 6.1023 15.2878 5.88509 15.2878Z" fill="white" />
    <path d="M14.1767 19.6165H12.214L10.1893 15.7144H10.1582C10.1686 15.8023 10.1763 15.9058 10.1815 16.0247C10.1919 16.1437 10.1996 16.2678 10.2048 16.3971C10.21 16.5264 10.2151 16.6531 10.2203 16.7772C10.2255 16.9013 10.2281 17.0151 10.2281 17.1185V19.6165H8.90152V14.0776H10.8564L12.8734 17.9253H12.8967C12.8915 17.8374 12.8837 17.7366 12.8734 17.6228C12.8682 17.5038 12.863 17.3849 12.8579 17.2659C12.8527 17.1418 12.8475 17.0203 12.8424 16.9013C12.8424 16.7824 12.8424 16.6764 12.8424 16.5833V14.0776H14.1767V19.6165Z" fill="white" />
    <path d="M17.5976 16.3738H19.987V19.3605C19.7025 19.4587 19.3741 19.5389 19.0018 19.601C18.6346 19.663 18.2493 19.6941 17.8459 19.6941C17.3132 19.6941 16.8452 19.5906 16.4418 19.3838C16.0435 19.1769 15.7306 18.8614 15.5031 18.4373C15.2807 18.0133 15.1695 17.478 15.1695 16.8315C15.1695 16.2523 15.2807 15.7506 15.5031 15.3265C15.7255 14.9025 16.0513 14.5766 16.4805 14.3491C16.915 14.1164 17.4477 14 18.0786 14C18.4251 14 18.7535 14.0336 19.0638 14.1008C19.3741 14.1629 19.6431 14.2457 19.8706 14.3491L19.3974 15.5205C19.2009 15.4222 18.994 15.3498 18.7768 15.3033C18.5596 15.2516 18.3294 15.2257 18.0864 15.2257C17.7554 15.2257 17.489 15.3007 17.2873 15.4507C17.0856 15.6006 16.9382 15.7998 16.8452 16.048C16.7572 16.2962 16.7133 16.5703 16.7133 16.8703C16.7133 17.2116 16.7624 17.5013 16.8607 17.7392C16.9589 17.9771 17.1012 18.1581 17.2873 18.2822C17.4735 18.4011 17.7011 18.4606 17.97 18.4606C18.0321 18.4606 18.1019 18.458 18.1795 18.4528C18.257 18.4477 18.332 18.4399 18.4044 18.4296C18.482 18.4192 18.5415 18.4089 18.5829 18.3985V17.5297H17.5976V16.3738Z" fill="white" />
  </>
)
