import { Box, Checkbox, Stack, Typography } from "@mui/material"
import FontIcon from "components/ui/FontIcon"
import { RenderOptionProps } from "../types"

const RenderOption = <T,>(props: RenderOptionProps<T>) => {

  const { props: ownProps, option, state, ownerState } = props

  const { id, label, description } = option

  const { selected } = state
  const { multiple } = ownerState
  return (
    <Box
      key={id}
      component="li"
      sx={{
        padding: '16px !important',
      }}
      {...ownProps}
    >
      <Stack direction='row' spacing='8px' overflow='auto'>
        {multiple && (
          <Checkbox
            icon={<FontIcon icon='check_box_outline_blank' fontSize='large' sx={{ color: 'primary.text03' }} />}
            checkedIcon={<FontIcon icon='check_box' fontSize='large' sx={{ color: 'primary.main' }} />}
            checked={selected}
            sx={{
              padding: 0,
            }}
          />
        )}
        <Stack spacing='4px' overflow='auto'>
          {typeof label === 'string'
            ? (
              <Typography variant='body2' noWrap>
                {label}
              </Typography>
            )
            : label
          }
          {description && (
            <>
              {typeof description === 'string'
                ? (
                  <Typography variant='overline' noWrap sx={{ opacity: '0.5' }}>
                    {description}
                  </Typography>
                )
                : description
              }
            </>
          )}
        </Stack>
      </Stack>
    </Box>
  )
}

export default RenderOption
