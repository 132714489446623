export const BASE_URL = process.env.REACT_APP_BASE_URL
export const API_URL = `${BASE_URL}/api`

export const APRYSE_LICENSE_KEY = 'demo:1693402474610:7c3d1ed70300000000fe6cab29c3fbd8bf64ce00bc3cda4cb157942465'
export const APRYSE_WEBVIEWER_PATH = '/webviewer'

export const MANAGERS = ['TREND_ADMIN', 'SUPPORT_ADMIN']
export const ADMINS = ['ADMIN', 'SUPPORT_ADMIN']

export const ACCESS_TOKEN = "kbl_access_token"
export const REFRESH_TOKEN = "kbl_refresh_token"

export const RECENT_GLOBAL_SEARCH = 'kbl_recent_global_search'
export const RECENT_GLOBAL_SEARCH_MAX = 10

export const NODE_TYPE_ORDER = {
  'ROOT': 1,
  'TREND': 2,
  'FOLDER': 3,
  'DOCUMENT': 4,
}

export const ENDPOINTS = {
}

export const ruLocale = {
  startsWith: "Начинается с",
  contains: "Содержит",
  notContains: "Не содержит",
  endsWith: "Заканчиватся на",
  equals: "Равно",
  notEquals: "Не равно",
  noFilter: "Без фильтра",
  lt: "Меньше чем",
  lte: "Меньше или равно чем",
  gt: "Больше чем",
  gte: "Больше или равно чем",
  dateIs: "Дата равна",
  dateIsNot: "Дата не равна",
  dateBefore: "Дата до",
  dateAfter: "Дата после",
  custom: "Custom",
  clear: "Очистить",
  apply: "Применить",
  matchAll: "Учитывать все правила",
  matchAny: "Учитывать любое правило",
  addRule: "Добавить правило",
  removeRule: "Удалить правило",
  accept: "Да",
  reject: "нет",
  choose: "Выбрать",
  upload: "Загрузить",
  cancel: "Отмена",
  firstDayOfWeek: 1,
  dayNames: [
    "Воскресенье",
    "Понедельник",
    "Вторник",
    "Среда",
    "Четверг",
    "Пятница",
    "Суббота",
  ],
  dayNamesShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
  dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
  monthNames: [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ],
  monthNamesShort: [
    "янв",
    "фев",
    "мар",
    "апр",
    "май",
    "июн",
    "июл",
    "авг",
    "сен",
    "окт",
    "ноя",
    "дек",
  ],
  today: "Сегодня",
  dateFormat: "dd.mm.yy",
  weekHeader: "н",
  weak: "Слабый",
  medium: "Средний",
  strong: "Сильный",
  passwordPrompt: "Введите пароль",
  emptyFilterMessage: "Нет результатов фильтра",
  emptyMessage: "Нет данных",
}

export const NOOP = () => { }

/*
  "controlTrend": true,
  "visibilityTrend": true,
  "controlFolder": true,
  "visibility": true,
  "edit": true,
  "create": true,
  "remove": true,
  "copy": true,
  "transfer": true,
  "copyLink": true,
  "download": true,
  "management": true
*/

export const CONTEXT_MENU_HEADER = {
  EXPANDER: 'expander',
  CUT: 'content_cut',
  COPY: 'content_copy',
  PASTE: 'content_paste',
  DOWNLOAD: 'download',
  EDIT: 'edit',
  DELETE: 'delete',
  MANAGE_TRENDS: 'manageTrends',
  RESTORE: 'restore',
}

export const CONTEXT_MENU_ITEMS = {
  OPEN: 'open',
  // HOMEPAGE: 'homepage',
  FAVORITE: 'favorite',
  QUICK_NODE: 'quickNode',
  MANAGE_TRENDS: 'manageTrends',
  CREATE_FOLDER: 'createFolder',
  ARCHIVE: 'archive',
  RESPONSIBLE: 'responsible',
  NODE_RIGHTS: 'nodeRights',
  RESTORE: 'restore',
}

export const PASTE_HEADER = {
  'upload': 'Загрузка',
  'archive': 'Удаление',
  'restore': 'Восстановление',
  'cut': 'Вставка',
  'copy': 'Копирование',
}
