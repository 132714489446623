import { Stack } from '@mui/material'
import NodeType from 'models/node.model'
import ContentTitle from './ContentTitle/ContentTitle'
import Navigator from "./Navigator"
import QuickNodes from './QuickNodes/QuickNodes'
import SearchControls from "./SearchControls"

function TopPanel({ node, error }: { node: NodeType, error: string | number }) {
  return (
    <Stack id='top-panel'>

      {node?.id && <QuickNodes parent={node} />}

      <Stack spacing='8px'>

        {!error && <Navigator node={node} />}

        <Stack direction='row' alignItems='flex-end' id='node-name' gap='16px' sx={{ flexWrap: 'wrap', minHeight: '32px' }}>

          <ContentTitle node={node} title={error ? '' : node?.name as string} />
          <SearchControls showLocalSearch={['ROOT', 'TREND', 'FOLDER'].includes(node?.nodeType) && !node.isDirectory} />

        </Stack>
      </Stack>
    </Stack>
  )
}

export default TopPanel
