import { Box, ListItemText } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import FontIcon from 'components/ui/FontIcon'
import { NOOP } from 'config/constants'
import { useRef } from 'react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { TreeItemProps } from '../TreeItem/TreeItem'

export interface TreeSubItemProps extends TreeItemProps {
  last?: boolean
  isDragging?: boolean
  dragHandleProps?: DraggableProvidedDragHandleProps
}

export default function TreeSubItem(props: TreeSubItemProps) {

  const { draggable = false, isDragging, dragHandleProps = {} as DraggableProvidedDragHandleProps, showActions, node, item, last, selected, setSx = NOOP, onClick = NOOP, setActions } = props

  const ref = useRef<HTMLDivElement>(null)

  const actions = setActions ? setActions(node) : undefined

  const handleClick = () => {
    onClick(node)
  }

  // useEffect(() => {
  //   if (selected)
  //     setTimeout(() => ref.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }), 600)
  // }, [selected])

  return (
    <ListItemButton
      ref={ref}
      selected={selected}
      sx={{
        height: '40px',
        padding: '0 15px 0 30px',
        display: 'grid',
        overflow: 'auto',
        gridTemplateColumns: 'auto auto 1fr auto',
        '&.Mui-focusVisible': {
          backgroundColor: 'transparent'
        },
        '&:hover': {
          color: 'primary.main',
          backgroundColor: 'primary.main01',
          '& .MuiBox-root:last-of-type': {
            display: 'flex'
          },
        },
        ...setSx(node)
      }}
      onClick={handleClick}
    >

      <Box
        {...dragHandleProps}
        sx={{
          width: draggable ? '1rem' : 0,
          marginLeft: draggable ? '-8px' : 0,
          marginRight: draggable ? '8px' : 0,
          transition: 'all 0.3s',
        }}
      >
        {draggable && <FontIcon icon='drag_indicator' />}
      </Box>

      <Box
        id={`twig-${node.id}`}
        sx={{
          minWidth: isDragging ? 0 : '1rem',
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
          marginRight: '9px',
          borderLeft: '1px solid transparent',
          transition: 'all 0.3s',
          '&:before': !last && !isDragging && {
            content: '""',
            position: 'absolute',
            width: '1px',
            height: '100%',
            borderLeft: '1px solid rgba(211, 214, 217, 1)',
            top: 0,
            left: 0
          },
          '&:after': !isDragging && {
            content: '""',
            position: 'absolute',
            width: '30px',
            height: '30px',
            borderWidth: '0 0 1px 1px',
            borderStyle: 'solid',
            borderRadius: '10px',
            borderColor: 'rgba(211, 214, 217, 1)',
            top: '-10px',
            left: 0
          }
        }}
      />
      <ListItemText
        primaryTypographyProps={{
          noWrap: true
        }}
      >
        {item || node.name}
      </ListItemText>
      {actions && (
        <Box component='div' sx={{ padding: '0 1px 0 5px', display: showActions === 'always' ? 'flex' : 'none' }}>
          {actions}
        </Box>
      )}
    </ListItemButton>
  )
}
