import { Stack } from "@mui/material"
import Button from "components/ui/Button/Button"
import { useTranslation } from "react-i18next"

interface ActionsOfDialogProps {
  disabled: boolean
  handleFormSubmit: () => void
  handleClose: () => void
}

const ActionsOfDialog = ({ disabled, handleFormSubmit, handleClose }: ActionsOfDialogProps) => {

  const { t } = useTranslation()

  return (
    <Stack direction='row' spacing='16px' width='100%'>
      <Button fullWidth variant='outlined' onClick={handleClose}>
        {t('kbl.buttons.cancel')}
      </Button>
      <Button
        form='my-form'
        type='submit'
        fullWidth
        variant='contained'
        disabled={disabled}
        color='success'
        onClick={handleFormSubmit}
      >
        {t('kbl.buttons.save')}
      </Button>
    </Stack>
  )
}

export default ActionsOfDialog
