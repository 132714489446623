
import { Box, Breadcrumbs, Stack, Typography, TypographyProps } from '@mui/material'
import FontIcon from 'components/ui/FontIcon'
import Link from "components/ui/Link/Link"
import usePath from 'hooks/usePath'
import NodeType, { DocumentNodeType } from 'models/node.model'
import { useCallback, useMemo } from 'react'
import { useTranslation } from "react-i18next"

const Chunk = (props: any) => {
  const { href, chunk, typographyProps } = props
  return (
    <Box>
      <Typography
        noWrap
        variant='body1'
        {...typographyProps}
      >
        {href
          ? <Link to={href} sx={{ textDecoration: 'none', cursor: 'pointer', color: 'primary.main' }}>
            {chunk}
          </Link>
          : chunk
        }
      </Typography>
    </Box>
  )
}

const NavigatorPath = ({ ancestors, textOnly, selfName, typographyProps }: { ancestors: any[], textOnly?: boolean, selfName?: string, typographyProps?: TypographyProps }) => {

  const breadcrumbs: any[] = useMemo(() => ancestors?.map((parent: any) => (
    <Chunk key={parent.id} chunk={parent?.name} href={!textOnly && `/content/${parent.id}`} typographyProps={typographyProps} />
  )) || [], [ancestors])

  const renderPath = useCallback(() => (
    <Breadcrumbs
      maxItems={4}
      separator={<FontIcon icon='chevron_right' sx={{ padding: 0, color: 'rgba(167, 167, 167, 1)' }} />}
      aria-label="breadcrumb"
      sx={{
        overflow: 'hidden',
        '& ol': {
          flexWrap: 'nowrap',
          '& li.MuiBreadcrumbs-separator': {
            margin: 0
          },
          '& li.MuiBreadcrumbs-li': {
            overflow: 'hidden'
          },
        },
      }}
    >
      {breadcrumbs}
    </Breadcrumbs>
  ), [breadcrumbs])

  if (selfName)
    breadcrumbs.push(<Chunk key='self' chunk={selfName} />)

  return (
    <Stack
      gap='22px'
      sx={{
        flex: 1,
        overflow: 'auto',
      }}
    >
      <Box>
        {renderPath()}
      </Box>
    </Stack>
  )
}

function Navigator({ node, textOnly }: { node: NodeType | DocumentNodeType, textOnly?: boolean }) {
  const { t } = useTranslation()
  const path = usePath()

  const ancestors = [...node?.parents || []]

  if (path[0] !== 'document' && path[0] !== 'content')
    ancestors.pop()

  return (
    <NavigatorPath ancestors={ancestors} textOnly={textOnly} selfName={node?.name} />
  )
}

export default Navigator
export { NavigatorPath }
