import { Stack } from "@mui/material"
import Loading from "components/Loading/Loading"
import Dialog from "components/ui/Dialog"
import { ResponsibleType } from "models/responsible.model"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router"
import { useGetNodeEntityQuery, useGetNodeResponsibleQuery } from "reducers/api/node.api"
import responsible, { useRemoveResponsibleNodeMutation, useUpdateResponsibleNodeMutation } from "reducers/api/responsible.api"
import ResponsibleHeader from "./ResponsibleHeader/ResponsibleHeader"
import ResponsibleList from "./ResponsibleList/ResponsibleList"
import ResponsibleNodeName from "./ResponsibleNodeName/ResponsibleNodeName"
import ResponsibleToolbar from "./ResponsibleToolbar/ResponsibleToolbar"
import { commonApi } from "config/common-api"
import { useAppDispatch } from "config/store"

interface ResponsibleProps {
  callbackUrl?: string
}

const NodeResponsible = (props: ResponsibleProps) => {

  const { callbackUrl } = props

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { id: nodeId } = useParams()

  const { data: responsibleData, isLoading: isResponsibleLoading, isFetching: isResponsibleFetching } = useGetNodeResponsibleQuery({ id: nodeId }, {
    skip: !nodeId
  })

  const { data: nodeData, isLoading: isNodeLoading } = useGetNodeEntityQuery({ id: nodeId }, {
    skip: !nodeId
  })

  const [addNodeResponsible, { isLoading: isAddResponsibleLoading }] = useUpdateResponsibleNodeMutation()
  const [removeNodeResponsible] = useRemoveResponsibleNodeMutation()

  const isLoading = isResponsibleLoading || isNodeLoading

  const handleClose = () => navigate(callbackUrl)

  const handleAddResponsible = (values: ResponsibleType[]) => {
    values.forEach(value => {
      addNodeResponsible({ id: value.id, nodeId })
    })
  }

  const handleRemoveResponsible = async (data: ResponsibleType) => {
    await removeNodeResponsible({ id: data.id, nodeId })
  }

  return (
    <Dialog
      fullHeight
      onClose={handleClose}
      open={true}
      title={<ResponsibleHeader />}  
    >
      <Loading showLoading={isLoading}>
        <Stack spacing='16px' sx={{ overflow: 'hidden' }}>
          <ResponsibleNodeName node={nodeData} />
          <ResponsibleToolbar onAddResponsible={handleAddResponsible} />
          <ResponsibleList data={responsibleData} isLoading={isResponsibleFetching || isAddResponsibleLoading} onRemove={handleRemoveResponsible} />
        </Stack>
      </Loading>
    </Dialog>
  )
}

export default NodeResponsible
