import { experimental_extendTheme as extendTheme } from '@mui/material/styles'

const theme = extendTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            minWidth: '550px',
            maxWidth: '550px',
            borderRadius: '20px',
          },
          // '& .MuiDialogContent-root': {
          //   display: 'flex'
          // }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: '13px 46px 13px 20px',
          borderBottom: `1px solid ${theme.palette.primary.line}`,
        })
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '13px 20px 13px 20px'
        }
      }
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingBlock: '0 !important',
          '& input': {
            ...theme.typography.subtitle2,
            height: '100%',
            boxSizing: 'border-box',
          },
          '& .MuiAutocomplete-endAdornment': {
            top: 'initial'
          },
          height: '44px',
          borderRadius: '5px',
          '& fieldset': {
            border: `1px solid ${theme.palette.primary.line}`,
          },
          '&:hover': {
            '& fieldset': {
              border: `1px solid ${theme.palette.primary.main} !important`,
            },
          },
          '&:focus-within': {
            '& fieldset': {
              border: `1px solid ${theme.palette.primary.main} !important`,
            },
          },
          '&.Mui-disabled': {
            pointerEvents: 'none',
            '& fieldset': {
              border: `1px solid ${theme.palette.primary.line} !important`,
            },
          },
          '&.MuiInputBase-adornedStart': {
            paddingLeft: '8px',
            '& input': { paddingLeft: '4px' }
          },
          '&.MuiInputBase-adornedEnd': {
            paddingRight: '8px'
          }
        }),
        sizeSmall: {
          height: '32px',
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.text03,
          top: '-3px !important'
        }),
      },
    },

    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '& svg': {
            width: '20px',
            height: '20px',
          },
        },
      },
    },

    MuiMenu: {
      styleOverrides: {
        'paper': {
          boxShadow: 'none',
          filter: 'drop-shadow(0 0 0.5rem rgba(0,0,0,0.3))',
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        startIcon: {
          margin: 0,
          marginRight: '2px',
          color: 'inherit'
        },
        endIcon: {
          margin: 0,
        },
        root: {
          height: '44px',
          borderRadius: '6px',
          display: 'flex',
          alignItems: 'center',
          textTransform: 'initial',
          fontSize: '16px',
          boxShadow: 'none',
          minWidth: 0,

          '& .MuiButtonBase-root>svg': {
            fontSize: '1rem !important',
          },
        },

        sizeSmall: ({ theme }) => ({
          height: '32px',
          padding: '4px 8px',
          gap: '4px',
          ...theme.typography.subtitle2,
        }),
        contained: ({ theme }) => ({
          '& .MuiIcon-root': {
            color: theme.palette.primary.contrastText,
          },
          '&.Mui-disabled': {
            color: theme.palette.primary.contrastText,
            backgroundColor: 'rgba(39, 69, 104, 0.1)'
          },
        }),
        containedSuccess: {
          backgroundColor: 'rgba(134, 185, 56, 1)',
          color: '#fff',
        },
        outlinedPrimary: ({ theme }) => ({
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
          '& .MuiIcon-root': {
            color: theme.palette.primary.main
          }
        }),
      },
    },
  },
  typography: {
    h1: {
      fontSize: '20px',
      fontWeight: 800
    },
    h2: {
      fontSize: '16px',
      fontWeight: 800
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 300
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 300
    },
    body1: {
      fontSize: '14px',
      fontWeight: 400
    },
    overline: {
      fontSize: '13px',
      fontWeight: 300,
      textTransform: 'initial'
    },
    fontFamily: [
      'Moscow Sans',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(',')
  },
  colorSchemes: {
    light: {
      palette: {
        text: {
          primary: 'rgba(17, 35, 48, 1)',
        },
        primary: {
          text03: 'rgba(17, 35, 48, 0.3)',
          main: 'rgba(70, 150, 236, 1)',
          main01: 'rgba(70, 150, 236, 0.1)',
          mosmetro: 'rgba(203, 50, 52, 1)',
          line: 'rgba(17, 35, 48, 0.1)',
          contrastText: '#fff',
        },
        action: {
          disabled: 'rgba(17, 35, 48, 0.3)'
        }
      }
    }
  }
})

export default theme
