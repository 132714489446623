import { Stack, TextField } from "@mui/material"
import { ResponsibleType } from "models/responsible.model"
import { useFormContext } from "react-hook-form"
import { getArrayTypeKeys, getArrayTypeValues } from "../../ResponsibleNodeDialog/utils"

export enum formTextFieldsName {
	firstName = "Имя",
	middleName= 'Отчество',
	lastName= 'Фамилия',
	forGroup='Группа',
	department= 'Подразделение',
	post = 'Должность',
	address= 'Адрес рабочего места',
	email= 'Эл.почта',
	phone = 'Телефон',
}

enum formTextAreasName {
	functional = 'Функционал',
	delegation = 'Делегирование полномочий',
}

const ResponsibleForm = (props) => {

	const { register } = useFormContext<ResponsibleType>()

	return (
    <form id='responsible-form' autoComplete='off'>
      <Stack spacing='16px'>
        {getArrayTypeKeys(formTextFieldsName).map((el, i) => (
          <TextField
            key={i}
            {...register(
              el,
              el === 'department' ? { required: false } : { required: true }
            )}
            size='medium'
            variant='outlined'
            label={`${getArrayTypeValues(formTextFieldsName)[i]} *`}
            fullWidth
            disabled={el === 'email' && props.editing && true}
          />
        ))}
        {getArrayTypeKeys(formTextAreasName).map((el, i) => (
          <TextField
            key={i}
            variant='outlined'
            fullWidth
            multiline
            rows={3}
            {...register(el, { required: true })}
            label={`${getArrayTypeValues(formTextAreasName)[i]} *`}
            sx={{
              '& .MuiOutlinedInput-root': { height: '88px' },
            }}
          />
        ))}
      </Stack>
    </form>
  );
}

export default ResponsibleForm
