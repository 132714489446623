import { commonApi } from "config/common-api"
import { API_URL } from "config/constants"
import { NodeUserRightsManyType, NodeUserRightsType } from "models/node-user-rights"

const apiUrl = `${API_URL}/kbl/rights-node-user`

export const nodeUserRights = commonApi.injectEndpoints({
  endpoints: builder => ({

    getNodeRights: builder.query<NodeUserRightsManyType, { id: string }>({
      query: ({ id }) => ({
        url: `${apiUrl}/by-node/${id}`,
      }),
      providesTags: ['NODE_RIGHTS'],
    }),

    getFilteredNodeRights: builder.mutation<NodeUserRightsManyType, any>({
      query: (body) => ({
        method: 'POST',
        url: `${apiUrl}/filtering?size=500`,
        body,
      }),
    }),

    createNodeRights: builder.mutation<any, NodeUserRightsType>({
      query: (body) => ({
        method: 'POST',
        url: `${apiUrl}`,
        body,
      }),
      invalidatesTags: ['NODE_RIGHTS']
    }),

    createRecursiveNodeRights: builder.mutation<any, NodeUserRightsType>({
      query: (body) => ({
        method: 'POST',
        url: `${apiUrl}/recursion-add-group`,
        body,
      }),
      invalidatesTags: ['NODE_RIGHTS']
    }),

    updateNodeRights: builder.mutation<any, NodeUserRightsType>({
      query: ({ id, ...rest }) => ({
        method: 'PUT',
        url: `${apiUrl}/${id}`,
        body: { id, ...rest },
      }),
      invalidatesTags: ['NODE_RIGHTS']
    }),

    removeNodeRights: builder.mutation<any, NodeUserRightsType>({
      query: ({ id }) => ({
        method: 'DELETE',
        url: `${apiUrl}/${id}`,
      }),
      invalidatesTags: ['NODE_RIGHTS']
    }),

  })
})

export const {
  useGetNodeRightsQuery,
  useGetFilteredNodeRightsMutation,
  useCreateNodeRightsMutation,
  useCreateRecursiveNodeRightsMutation,
  useUpdateNodeRightsMutation,
  useRemoveNodeRightsMutation
} = nodeUserRights

export default nodeUserRights
