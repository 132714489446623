import { Typography } from '@mui/material'

interface ItemNameProps {
  name: string
  pending: boolean
}

const ItemName = ({ name, pending }: ItemNameProps) => {
  return (
    <Typography
      noWrap
      variant='overline'
      sx={{
        ...(pending && { color: 'primary.text03' })
      }}
    >
      {name}
    </Typography>
  )
}

export default ItemName
