import { Box, Typography } from "@mui/material"
import { AutoCompleteProps } from "components/ui/auto-complete/types"
import Button from "components/ui/Button/Button"
import ChainStack from "components/ui/ChainStack/ChainStack"
import FontIcon from "components/ui/FontIcon"
import React from "react"

export const renderRecentOption: (onClick: (text: string) => void) => AutoCompleteProps<string>['renderOption'] = (onClick) => (props, value) => {

	const handleRemoveClick = (e: React.MouseEvent) => {
		e.stopPropagation()
		onClick(value)
	}

	return (
		<Box
			component='li'
			{...props}
			sx={{
				padding: 0,
			}}
		>
			<ChainStack justifyContent='space-between' width='100%'>
				<Typography>
					{value}
				</Typography>
				<Button iconOnly size='small' sx={{ '&:hover .MuiIcon-root': { color: 'primary.mosmetro' } }} onClick={handleRemoveClick}>
					<FontIcon icon='delete' sx={{ color: 'primary.text03' }} />
				</Button>
			</ChainStack>
		</Box>
	)
}
