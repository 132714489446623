import { Typography } from "@mui/material"
import { GridCellParams, GridColDef } from "@mui/x-data-grid-premium"
import NodeIcon from "components/Icons/NodeIcon/NodeIcon"
import ChainStack from "components/ui/ChainStack/ChainStack"
import DataGrid from "components/ui/DataGrid/DataGrid"
import Link from "components/ui/Link/Link"
import { renderCell, renderHeader } from "components/utils/datagrid"
import useActions from "hooks/useActions"
import { RelatedDocumentNode } from "models/node.model"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useRemoveLinkMutation } from "reducers/api/link-node.api"
import { isEditable } from "utils/checkRight"
import { NavigatorPath } from "../../TopPanel/Navigator/Navigator"
import ActionAddButton from "../components/Actions/ActionAddButton"
import DocumentCard from "../components/DocumentCard/DocumentCard"
import { CardBlocksProps } from "./DocumentCardBlocksProps"
import RelatedDocumentsAction from "./RelatedDocumentsAction.tsx/RelatedDocumentsAction"

const DocumentRelatedDocs = ({ node }: CardBlocksProps) => {
  const { t } = useTranslation()
  const { open, manageRelatedDocuments } = useActions(node)
  const relatedDocuments = node?.relatedDocuments || []

  const [pending, setPending] = useState<string[]>(null)

  const [removeLink] = useRemoveLinkMutation()

  const handleRemove = async ({ id }: RelatedDocumentNode) => {
    setPending(v => [...(v || []), id])
    await removeLink({ id, initId: node.id })
  }

  const columns: GridColDef<RelatedDocumentNode>[] = useMemo(() => [
    { field: 'name', headerName: 'Связь', flex: 1 },
    { field: 'type', headerName: 'Тип', flex: 0.7, valueGetter: ({ row }) => row.kindLink.name },
    {
      field: 'nodeName', headerName: 'Документ', flex: 1.5, valueGetter: ({ row }) => row.node.name,
      renderCell: ({ row }) => {
        return (
          <ChainStack spacing='2px' overflow='auto'>
            <NodeIcon node={row} width={20} />
            <Typography variant='body1' noWrap>
              <Link to='#' sx={{ textDecoration: 'none' }} onClick={(e) => { e.preventDefault(); open(row.node) }}>
                {row.node.name}
              </Link>
            </Typography>
          </ChainStack>
        )
      }
    },
    {
      field: 'path', headerName: t('kbl.title.path'), flex: 2,
      headerClassName: 'hideRightSeparator',
      renderCell: ({ row }) => <NavigatorPath ancestors={row.node.parents} />
    },
    {
      field: 'actions', type: 'actions', width: 30,
      disableReorder: true, sortable: false,
      headerClassName: 'hideRightSeparator',
      renderCell: ({ row }: GridCellParams<RelatedDocumentNode>) => (
        isEditable(node) &&
        <RelatedDocumentsAction
          isPending={pending?.includes(row.id)}
          onClick={() => handleRemove(row)}
        />
      )
    }
  ].map(column => ({ renderCell, renderHeader, ...column })), [relatedDocuments])

  return (
    <DocumentCard
      title='Связанные документы'
      actions={ActionAddButton(isEditable(node), manageRelatedDocuments)}
    >
      {relatedDocuments?.length < 1
        ? (
          <Typography variant="subtitle1" sx={{ textAlign: "center", paddingTop: "16px" }}>
            {t('kbl.relatedDocsNotFound')}
          </Typography>
        )
        : (
          <DataGrid
            columns={columns}
            rows={relatedDocuments}
            hideFooter
            density='compact'
            sx={{
            }}
            slots={{
              toolbar: null
            }}
          />
        )
      }

    </DocumentCard>
  )
}

export default DocumentRelatedDocs
