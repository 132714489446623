import { Button } from "@mui/material"
import Loading from "components/Loading/Loading"
import { ResponsibleType } from "models/responsible.model"
import { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import Dialog from "../../../../../components/ui/Dialog"
import {
	useGetResponsibleQuery, useRemoveResponsibleNodeMutation,
} from "../../../../../reducers/api/responsible.api"
import ResponsibleNodeList from "./ResponsibleNodeList/ResponsibleNodeList"

interface ResponsibleNodeDialogProps {
	responsible?: ResponsibleType
	onClose: () => void
}

const ResponsibleNodeDialog = ({ responsible, onClose }: ResponsibleNodeDialogProps) => {
	
	const { t } = useTranslation()

	const { data: responsibleData, isLoading } = useGetResponsibleQuery({ id: responsible?.id }, { skip: !responsible?.id })
	const [removeResponsibleNode] = useRemoveResponsibleNodeMutation()
	
  const [pending, setPending] = useState<string[]>(null)

	const handleRemoveResponsibleNode = async (nodeId: string) => {
		setPending(v => [...(v || []), nodeId])
		await removeResponsibleNode({ id: responsible?.id, nodeId })
	}
	
	return (
		<Dialog
			fullHeight
			open={true}
			title={t("kbl.dialog.title.general.showResponsibility")}
			onClose={onClose}
		>
			<Loading showLoading={isLoading}>
				<ResponsibleNodeList responsible={responsibleData} pending={pending} onDelete={handleRemoveResponsibleNode} />
			</Loading>
		</Dialog>
	)
}

export default ResponsibleNodeDialog
