export const xlsSmallIcon = (
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.39864 0C2.77408 0 2.27295 0.50364 2.27295 1.12819V24.3289C2.27295 24.9534 2.77408 25.4545 3.39864 25.4545H20.8184C21.443 25.4545 21.9093 24.9534 21.9093 24.3289V6.18182L15.7275 0H3.39864Z" fill="#ECEDEE" />
    <path d="M15.7275 6.18182H21.9093L15.7275 0V6.18182Z" fill="#CDD1D3" />
    <path d="M0 10.6744C0 9.74966 0.749663 9 1.67442 9H22.3256C23.2503 9 24 9.74966 24 10.6744V21.8372C24 22.762 23.2503 23.5116 22.3256 23.5116H1.67442C0.749662 23.5116 0 22.762 0 21.8372V10.6744Z" fill="#0E7A40" />
    <path d="M10.0272 19.6047H8.03571L6.79441 17.6132L5.56204 19.6047H3.61524L5.73171 16.3451L3.74027 13.2285H5.65134L6.80334 15.202L7.91069 13.2285H9.87534L7.8571 16.4791L10.0272 19.6047Z" fill="white" />
    <path d="M10.7385 19.6047V13.2285H12.462V18.2115H14.9178V19.6047H10.7385Z" fill="white" />
    <path d="M20.1066 17.6668C20.1066 18.0121 20.0173 18.3395 19.8387 18.6491C19.666 18.9587 19.3952 19.2117 19.026 19.4082C18.6629 19.5987 18.1896 19.694 17.6061 19.694C17.3144 19.694 17.0584 19.6791 16.8381 19.6493C16.6238 19.6255 16.4214 19.5838 16.2309 19.5243C16.0404 19.4647 15.8439 19.3873 15.6415 19.2921V17.7561C15.9868 17.9287 16.3351 18.0627 16.6863 18.158C17.0376 18.2473 17.3561 18.2919 17.6418 18.2919C17.8145 18.2919 17.9544 18.2711 18.0616 18.2294C18.1687 18.1877 18.2491 18.1312 18.3027 18.0597C18.3563 17.9883 18.3831 17.9079 18.3831 17.8186C18.3831 17.7055 18.3444 17.6102 18.267 17.5328C18.1896 17.4554 18.0675 17.3751 17.9008 17.2917C17.7341 17.2084 17.5109 17.1042 17.2311 16.9792C16.9929 16.872 16.7786 16.7589 16.5881 16.6398C16.3976 16.5207 16.2339 16.3868 16.0969 16.238C15.9659 16.0891 15.8647 15.9165 15.7933 15.72C15.7219 15.5176 15.6861 15.2794 15.6861 15.0056C15.6861 14.5948 15.7873 14.2525 15.9898 13.9786C16.1922 13.6988 16.472 13.4904 16.8292 13.3535C17.1924 13.2106 17.6121 13.1392 18.0884 13.1392C18.5051 13.1392 18.8802 13.1868 19.2136 13.282C19.547 13.3713 19.8446 13.4755 20.1066 13.5946L19.5797 14.9252C19.3059 14.8002 19.035 14.702 18.7671 14.6305C18.5051 14.5531 18.261 14.5144 18.0348 14.5144C17.8859 14.5144 17.7639 14.5323 17.6686 14.568C17.5734 14.6037 17.5019 14.6543 17.4543 14.7198C17.4126 14.7794 17.3918 14.8478 17.3918 14.9252C17.3918 15.0264 17.4305 15.1157 17.5079 15.1931C17.5853 15.2705 17.7133 15.3568 17.8919 15.4521C18.0765 15.5414 18.3265 15.6575 18.642 15.8004C18.9516 15.9373 19.2136 16.0891 19.4279 16.2558C19.6482 16.4166 19.8149 16.61 19.928 16.8363C20.0471 17.0566 20.1066 17.3334 20.1066 17.6668Z" fill="white" />
  </>
)
