import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import NodeType from 'models/node.model'

interface NodeState {
  renaming: NodeType
}

const initialState: NodeState = {
  renaming: undefined,
}

const node = createSlice({
  name: 'node',
  initialState,
  reducers: {
    setRenaming: (state: NodeState, { payload }: PayloadAction<{ node: NodeType } | undefined>) => {
      state.renaming = payload?.node
    },
  }
})

export const {
  setRenaming,
} = node.actions

export default node
