import { Stack, StackProps } from '@mui/material'

const ChainStack = ({ children, ...rest }: StackProps) => {
  return (
    <Stack direction='row' alignItems='center' {...rest}>
      {children}
    </Stack>
  )
}

export default ChainStack
