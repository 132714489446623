import { useAppSelector } from "config/store"
import NodeType from "models/node.model"
import { EntitiesSortDirection, EntitiesSortMode } from "reducers/toolbar"

export const useSort = () => {

  const sortMode = useAppSelector(state => state.toolbar.sortMode)
  const sortDirection = useAppSelector(state => state.toolbar.sortDirection)

  const sortNodes = (nodes: NodeType[] = [], mode: EntitiesSortMode = sortMode) => {
    const _nodes = [...nodes]
    return _nodes?.sort((a, b) => {
      if (a[mode] > b[mode])
        return sortDirection === EntitiesSortDirection.ASC ? 1 : -1
      else
        return sortDirection === EntitiesSortDirection.ASC ? -1 : 1
      // if ((a.nodeType === 'DOCUMENT' && b.nodeType === 'FOLDER')
      //   || (a.nodeType === 'FOLDER' && b.nodeType === 'TREND')
      //   || (a.nodeType === 'DOCUMENT' && b.nodeType === 'TREND')
      // )
      //   return 1
      // else
      //   return -1
    })
  }

  return sortNodes
}
