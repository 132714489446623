import { Box, IconButton } from "@mui/material"
import React, { useState } from "react"
import FontIcon from "../FontIcon"
import Menu from "../Menu"
import { DropMenuProps } from "./types"

const DropMenu = (props: DropMenuProps) => {
  const { disabled, button = 'more_vert', options, ButtonProps, IconProps } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      {typeof button === 'string'
        ? (
          <IconButton disabled={disabled} onClick={handleIconClick} {...ButtonProps}>
            <FontIcon icon={button} fontSize='medium' sx={{ color: disabled ? 'action.disabled' : 'primary.main' }} {...IconProps} />
          </IconButton>
        )
        : button({ onClick: handleIconClick, open, disabled })
      }
      {open && <Menu
        anchorEl={anchorEl}
        open//={open}
        items={options}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            minWidth: '160px',
            overflow: 'visible',
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: '11px',
              width: '10px',
              height: '10px',
              backgroundColor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
      />
      }
    </Box>
  )
}

export default DropMenu
