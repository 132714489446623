import { Divider, Stack } from "@mui/material"
import FontIcon from "components/ui/FontIcon"
import { useSort } from "hooks/useSort"
import NodeType from "models/node.model"
import { useMemo, useState } from "react"
import { useGetQuickNodesQuery } from "reducers/api/employee.api"
import { EntitiesSortMode } from "reducers/toolbar"
import QuickNodesList from "./QuickNodesList/QuickNodesList"

interface QuickNodesProps {
  parent: NodeType
}

const QuickNodes = (props: QuickNodesProps) => {

  const { parent } = props

  const [anchorEl, setAnchorEl] = useState(null)

  const sortNodes = useSort()

  const rootId = parent?.parents?.length > 0 && parent?.parents?.[0]?.id

  const { data: quickNodesData } = useGetQuickNodesQuery({ nodeId: rootId || parent?.id }, {
    skip: !parent?.id
  })

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)

  const quicknodes = useMemo(() => sortNodes(quickNodesData, EntitiesSortMode.TYPE)?.filter(quicknode => quicknode.id !== parent.id) || [], [parent, quickNodesData])

  return quicknodes?.length > 0
    ? (
      <>
        <Stack id='quick-nodes' direction='row' spacing='8px' alignItems='flex-start'
          sx={{
            position: 'relative',
            height: '38px',
            '&::before': {
              position: 'absolute',
              content: "''",
              top: 0,
              right: 0,
              width: '50px',
              height: '24px',
              background: 'linear-gradient(90deg, transparent, white)',
            }
          }}
        >
          <FontIcon icon='bolt' onClick={handleClick} sx={{ paddingBottom: '16px' }} />
          <QuickNodesList data={quicknodes} parent={parent} />
        </Stack>
        <Divider sx={{ marginBottom: '10px' }} />
      </>
    )
    : null
}

export default QuickNodes
