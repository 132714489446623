import { Checkbox, CheckboxProps, FormControlLabel } from "@mui/material"
import FontIcon from "components/ui/FontIcon"
import { forwardRef } from "react"

interface CheckBoxProps extends CheckboxProps {
  label: string
}

const CheckBox = forwardRef<HTMLButtonElement, CheckBoxProps>(({ label, disabled, ...checkboxProps }: CheckBoxProps, ref) => {
  return (
    <FormControlLabel
      control={(
        <Checkbox
          ref={ref}
          icon={<FontIcon icon='check_box_outline_blank' sx={{ color: 'primary.text03' }} />}
          checkedIcon={<FontIcon icon='check_box' sx={{ color: disabled ? 'inherit' : 'primary.main' }} />}
          sx={{
            padding: '4px'
          }}
          {...checkboxProps}
        />
      )}
      disabled={disabled}
      label={label}
      sx={{
        display: 'flex',
        alignItems: 'center',
        margin: '0 !important',
        whiteSpace: 'nowrap',
        '&:hover:not(.Mui-disabled)': {
          color: 'primary.main',
          '& .MuiIcon-root': {
            color: 'primary.main'
          }
        },
        '& .MuiCheckbox-root': {
          marginLeft: '-4px'
        },
        '& .MuiFormControlLabel-label': {
          display: 'block',
          paddingTop: '2px'
        }
      }}
    />
  )
})

export default CheckBox
