import { Stack } from "@mui/material"
import { useState } from "react"
import Button from "../Button/Button"
import FontIcon from "../FontIcon"
import { ConfirmInputProps, ConfirmInputRenderInputParams } from "./types"

const ConfirmInput = <T,>(props: ConfirmInputProps<T>) => {

  const { renderInput, onClose, onConfirm } = props

  const [value, setValue] = useState<T | T[]>()

  const isDisabled = !(value as T) || (value as T[])?.length === 0

  const handleChange: ConfirmInputRenderInputParams<T>['onChange'] = (_, value) => {
    setValue(value)
  }

  const handleClose = () => {
    onClose()
  }

  const handleConfirm = async () => {
    await onConfirm(value)
    onClose()
  }

  return (
    <Stack direction='row' spacing='4px' alignItems='center' display='grid' gridTemplateColumns='1fr auto auto'>
      {renderInput({ onChange: handleChange })}
      <Button iconOnly size='small' onClick={handleClose}>
        <FontIcon icon='close' sx={{ color: 'primary.mosmetro' }} />
      </Button>
      <Button iconOnly size='small' disabled={isDisabled} onClick={handleConfirm}>
        <FontIcon icon='check' sx={{ color: !isDisabled ? 'green' : 'action.disabled' }} />
      </Button>
    </Stack>
  )
}

export default ConfirmInput
