import { Stack, Typography } from '@mui/material'
import NodeIcon from 'components/Icons/NodeIcon/NodeIcon'
import NodeType from 'models/node.model'

interface NodeRightsNodeNameProps {
  node: NodeType
}

const NodeRightsNodeName = ({ node }: NodeRightsNodeNameProps) => {
  return (
    <Stack spacing='8px' direction='row' alignItems='center' sx={{ overflow: 'hidden' }}>
      <NodeIcon node={node} small />
      <Typography
        noWrap
        variant='h2'
      >
        {node?.name}
      </Typography>
    </Stack>
  )
}

export default NodeRightsNodeName
