import { Box, Button, Dialog, TextField, Typography } from '@mui/material'
import PasswordField from 'components/ui/PasswordField'
import { useAppDispatch, useAppSelector } from 'config/store'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useLoginMutation } from 'reducers/api/auth.api'
import { login, logout } from 'reducers/auth'

interface LoginProps {
  callbackUrl?: string
}

function Login({ callbackUrl }: LoginProps) {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const logged = useAppSelector(state => state.auth.logged)

  const [doLogin, loginResponse] = useLoginMutation()

  const [error, setError] = useState<string | undefined>(undefined)

  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm()

  const handleFormSubmit = handleSubmit(({ login, password }: { login: string, password: string }) => {
    setError(undefined)
    doLogin({ login, password })
  })

  useEffect(() => {
    if (loginResponse?.data) {
      dispatch(login(loginResponse?.data))
    }
    if (loginResponse?.error) {
      setError((loginResponse?.error as any).data?.message)
      dispatch(logout())
    }
  }, [loginResponse])

  useEffect(() => {
    if (logged)
      navigate(callbackUrl || '/')
  }, [logged])

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'center',
          backgroundColor: 'rgba(245, 246, 247, 1)'
        }}
      >
        <img src={process.env.PUBLIC_URL + '/i/mosmetro_logo.svg'} width='159' height='67' style={{ zIndex: 2, padding: '1rem' }} />
      </Box>
      <Dialog
        open={true}
        fullWidth
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: 'transparent'
            }
          }
        }}
        PaperProps={{
          sx: {
            maxWidth: '780px',
            borderRadius: '100px !important',
            boxShadow: '16px 25px 80px 0px rgba(49, 59, 78, 0.2)',
          }
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent'
          }}
        >
          <Box
            sx={{
              width: '100%',
              padding: '64px',
              borderRadius: '100px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '94px',
                borderRadius: '65px',
                boxShadow: '16px 25px 80px 0px rgba(49, 59, 78, 0.2)',
                gap: '2rem'
              }}
            >
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '12px'
              }}>
                <Typography sx={{
                  fontSize: '30px',
                  color: 'primary.mosmetro',
                  textAlign: 'center'
                }}>
                  {t('kbl.header')}
                </Typography>
                <Typography sx={{
                  color: 'primary.text03',
                  textAlign: 'center',
                  lineHeight: 'normal'
                }}>
                  {t('kbl.subheader')}
                </Typography>
              </Box>

              <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '16px'
                }}>
                <TextField
                  fullWidth
                  label={t('kbl.labels.login')}
                  variant="outlined"
                  error={!!error}
                  {...register('login')}
                />

                <Controller
                  control={control}
                  name='password'
                  render={({ field: { onChange } }) => (
                    <>
                      <PasswordField
                        sx={{
                          width: '100%',
                          '& .MuiFormHelperText-root': {
                            lineHeight: 'normal'
                          }
                        }}
                        fullWidth
                        label={t('kbl.labels.password')}
                        error={!!error}
                        helperText={error}
                        onChange={onChange}
                      />
                    </>
                  )}
                />

              </Box>

              <Button
                variant='contained'
                fullWidth
                sx={{ height: '44px', textTransform: 'none', fontSize: '16px' }}
                onClick={handleFormSubmit}>
                {t('kbl.buttons.login')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>

  )
}

export default Login
