export const pptSmallIcon = (
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.03487 0C2.41031 0 1.90918 0.50364 1.90918 1.12819V24.3289C1.90918 24.9534 2.41031 25.4545 3.03487 25.4545H20.4546C21.0792 25.4545 21.5455 24.9534 21.5455 24.3289V6.18182L15.3637 0H3.03487Z" fill="#ECEDEE" />
    <path d="M15.3637 6.18182H21.5455L15.3637 0V6.18182Z" fill="#CDD1D3" />
    <path d="M0 10.7143C0 9.76751 0.767512 9 1.71429 9H22.2857C23.2325 9 24 9.76751 24 10.7143V22.1429C24 23.0896 23.2325 23.8571 22.2857 23.8571H1.71429C0.767513 23.8571 0 23.0896 0 22.1429V10.7143Z" fill="#C3391C" />
    <path d="M6.48461 13.3291C7.31356 13.3291 7.93223 13.509 8.34061 13.8686C8.75508 14.2282 8.96232 14.7402 8.96232 15.4046C8.96232 15.7032 8.91966 15.9897 8.83432 16.264C8.74899 16.5322 8.6088 16.776 8.41375 16.9954C8.2248 17.2088 7.97185 17.3794 7.65489 17.5074C7.33794 17.6293 6.94785 17.6903 6.48461 17.6903H5.99089V19.8571H4.22632V13.3291H6.48461ZM6.44804 14.7554H5.99089V16.2549H6.33832C6.4907 16.2549 6.63089 16.2305 6.75889 16.1817C6.89299 16.1269 6.99966 16.0415 7.07889 15.9257C7.15813 15.8038 7.19775 15.6453 7.19775 15.4503C7.19775 15.243 7.1368 15.0754 7.01489 14.9474C6.89299 14.8194 6.70404 14.7554 6.44804 14.7554Z" fill="white" />
    <path d="M12.2614 13.3291C13.0903 13.3291 13.709 13.509 14.1174 13.8686C14.5319 14.2282 14.7391 14.7402 14.7391 15.4046C14.7391 15.7032 14.6964 15.9897 14.6111 16.264C14.5258 16.5322 14.3856 16.776 14.1905 16.9954C14.0016 17.2088 13.7486 17.3794 13.4317 17.5074C13.1147 17.6293 12.7246 17.6903 12.2614 17.6903H11.7677V19.8571H10.0031V13.3291H12.2614ZM12.2248 14.7554H11.7677V16.2549H12.1151C12.2675 16.2549 12.4077 16.2305 12.5357 16.1817C12.6698 16.1269 12.7764 16.0415 12.8557 15.9257C12.9349 15.8038 12.9745 15.6453 12.9745 15.4503C12.9745 15.243 12.9136 15.0754 12.7917 14.9474C12.6698 14.8194 12.4808 14.7554 12.2248 14.7554Z" fill="white" />
    <path d="M18.6599 19.8571H16.8953V14.7737H15.3045V13.3291H20.2507V14.7737H18.6599V19.8571Z" fill="white" />
  </>
)
