import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { EntitesLayout } from 'modules/Main/Content/Entities/types'

export enum EntitiesSortMode {
  ORDER = 'order',
  NAME = 'name',
  TYPE = 'nodeTypeOrder',
  DATE = 'createdDate',
  AUTHOR = 'author',
}

export enum EntitiesSortDirection {
  ASC = 'asc',
  DESC = 'desc',
}
interface ToolbarState {
  layout: EntitesLayout
  sortMode: EntitiesSortMode
  sortDirection: EntitiesSortDirection
}

const initialState: ToolbarState = {
  layout: EntitesLayout.GRID,
  sortMode: EntitiesSortMode.ORDER,
  sortDirection: EntitiesSortDirection.ASC,
}

const toolbar = createSlice({
  name: 'toolbar',
  initialState,
  reducers: {
    setLayout: (state: ToolbarState, { payload }: PayloadAction<{ layout: EntitesLayout } | undefined>) => {
      state.layout = payload?.layout
    },
    setSortMode: (state: ToolbarState, { payload }: PayloadAction<{ sortMode: EntitiesSortMode } | undefined>) => {
      state.sortMode = payload?.sortMode
    },
    setSortDirection: (state: ToolbarState, { payload }: PayloadAction<{ sortDirection: EntitiesSortDirection } | undefined>) => {
      state.sortDirection = payload?.sortDirection
    },
  }
})

export const {
  setLayout,
  setSortMode,
  setSortDirection,
} = toolbar.actions

export default toolbar
