import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridCellParams, gridDetailPanelExpandedRowsContentCacheSelector,
  GridRenderCellParams, GridRowId, GridToolbarQuickFilter, useGridApiContext,
  useGridSelector
} from '@mui/x-data-grid-premium'
import Button from "components/ui/Button/Button"
import DataGrid from "components/ui/DataGrid/DataGrid"
import { RenderFiltersType } from "components/ui/DataGrid/types"
import FontIcon from "components/ui/FontIcon/FontIcon"
import { renderHeader } from "components/utils/datagrid"
import { AccessGroupType } from "models/access-group.model"
import { isValidElement, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import ItemName from "../components/ItemName/ItemName"
import AccessGroupsItemAction from "./AccessGroupsItemAction/AccessGroupsItemAction"
import AccessGroupsItemContent from "./AccessGroupsItemContent/AccessGroupsItemContent"

interface AccessGroupsListProps {
  data: AccessGroupType[]
  isLoading: boolean
  onEdit: (accessGroup: AccessGroupType) => void
  onRemove: (data: AccessGroupType) => Promise<void>
}

function CustomDetailPanelToggle(props: Pick<GridRenderCellParams, 'id' | 'value'>) {
  const { id, value: isExpanded } = props
  const apiRef = useGridApiContext()

  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowsContentCacheSelector,
  )

  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = isValidElement(contentCache[id])

  return hasDetail && (
    <Button iconOnly
      size="small"
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? 'Close' : 'Open'}
    >
      <FontIcon icon={isExpanded ? 'expand_less' : 'expand_more'} />
    </Button>
  )
}

const AccessGroupsList = ({ data, isLoading, onEdit, onRemove }: AccessGroupsListProps) => {

  const { t } = useTranslation()
  const [pending, setPending] = useState<string[]>(null)

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([])

  const handleDetailPanelExpandedRowIdsChange = useCallback(
    (newIds: GridRowId[]) => {
      setDetailPanelExpandedRowIds(
        newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
      )
    }, []
  )

  const handleRemove = async (data: AccessGroupType) => {
    setPending(v => [...(v || []), data.id])
    await onRemove(data)
  }

  const columns: any[] = useMemo(() => [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      width: 30,
      headerClassName: 'hideRightSeparator',
      renderCell: ({ id, value }: { id: GridRowId; value: boolean }) => (
        <CustomDetailPanelToggle id={id} value={value} />
      ),
    },
    {
      headerName: t('kbl.dataGrid.name'), field: 'name', flex: 1, headerClassName: 'hideRightSeparator', renderCell: (cell: GridCellParams) => (
        <ItemName name={cell.row.name} pending={pending?.includes(cell.row.id)} />
      )
    },
    {
      field: 'actions', type: 'actions', width: 90,
      renderCell: (cell: GridCellParams) => !cell.row.isSystem && (
        <AccessGroupsItemAction
          isPending={pending?.includes(cell.row.id)}
          onEdit={() => onEdit(cell.row)}
          onDelete={() => handleRemove(cell.row)}
        />
      )
    }
  ].map(column => ({ renderHeader, ...column })), [data, pending])

  const renderFilters: RenderFiltersType = (apiRef) => {
    return (
      <GridToolbarQuickFilter sx={{ width: '100%', paddingBottom: 0 }} />
    )
  }

  useEffect(() => {
    setPending(null)
  }, [data])

  const sortedData = useMemo(() => [...data]?.sort((a, b) => Number(a.isSystem) - Number(b.isSystem)), [data])

  return (
    <DataGrid
      columns={columns}
      rows={sortedData}
      stateKey='access-groups'
      stateStorage='localStorage'
      density='compact'
      disableColumnFilter
      disableColumnMenu
      disableRowSelectionOnClick
      disableColumnSelector
      disableColumnReorder
      disableColumnResize
      hideFooter
      autoPageSize
      loading={isLoading}
      showDetailOnRowClick
      sortingOrder={['asc', 'desc']}
      slotProps={{
        toolbar: {
          disableExport: true,
          renderFilters
        },
      }}
      getDetailPanelContent={({ row }) => row.employee?.length > 0 && <AccessGroupsItemContent {...row} />}
      getDetailPanelHeight={() => 'auto'}
      detailPanelExpandedRowIds={detailPanelExpandedRowIds}
      onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
    />
  )
}

export default AccessGroupsList
