import { Stack, Typography } from "@mui/material"
import { GridValidRowModel } from "@mui/x-data-grid-premium"
import NodeIcon from "components/Icons/NodeIcon/NodeIcon"
import CustomDataGrid from "components/ui/DataGrid/DataGrid"
import Link from "components/ui/Link/Link"
import { renderCell, renderHeader } from "components/utils/datagrid"
import { t } from "i18next"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { NavigatorPath } from "../../TopPanel/Navigator/Navigator"

interface GlobalSearchResultsProps {
  searchText: string
  rows: GridValidRowModel[]
}

const GlobalSearchResults = ({ searchText, rows }: GlobalSearchResultsProps) => {

  const navigate = useNavigate()

  const columns = useMemo(() => {

    return [
      {
        headerName: '', field: 'type', width: 30, maxWidth: 30, resizable: false,
        headerClassName: 'hideRightSeparator', align: 'center',
        renderCell: ({ row }) => <NodeIcon small node={{ ...row, document: { attachment: { extension: row.extension } }, nodeType: row.type }} />
      },
      {
        headerName: '', field: 'name', flex: 1,
        renderHeader: () => <Typography variant='h2'>{t('kbl.title.searchResults', { text: searchText })}</Typography>,
        renderCell: ({ row }) => {
          const handleClick = () => navigate(`/${row.type === 'DOCUMENT' ? 'document' : 'content'}/${row.id}`, {
            state: {
              returnUrl: `/search?text=${searchText}`,
              returnMessage: t('kbl.dialog.message.returnMessage')
            }
          })
          return (
            <Stack spacing='8px' direction='row' alignItems='center'>
              <Typography variant='body1'>
                <Link to='#' onClick={(e) => { e.preventDefault(); handleClick() }}>{row.name}</Link>
              </Typography>
            </Stack>
          )
        }
      }, {
        headerName: '', field: 'path', flex: 1,
        renderHeader: () => <Typography variant='h2'>{t('kbl.title.location')}</Typography>,
        valueGetter: ({ row }) => row?.path.map(({ name }: { name: string }) => name).join(''),
        renderCell: ({ row }) => {
          return (
            <NavigatorPath ancestors={row.path} />
          )
        }
      }
    ].map((column: any) => ({ renderCell, renderHeader, ...column }))
  }, [rows, searchText])

  return (
    <CustomDataGrid
      columns={columns}
      rows={rows}
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      disableColumnReorder
      density='compact'
      sortingOrder={['asc', 'desc']}
      slotProps={{
        toolbar: { disableExport: true }
      }}
    />
  )
}

export default GlobalSearchResults
