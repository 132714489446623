import { CircularProgress, CircularProgressProps, Stack, StackProps, Typography, TypographyProps } from "@mui/material"

interface LoadingProps extends CircularProgressProps {
  showLoading: boolean
  message?: string
  containerProps?: StackProps
  circularProgressProps?: CircularProgressProps
  typographyProps?: TypographyProps
  children?: React.ReactNode
}

const Loading = (props: LoadingProps) => {

  const {
    showLoading,
    message,
    containerProps: { sx, ...restContainerProps } = {},
    circularProgressProps,
    typographyProps,
    children } = props

  return showLoading
    ? (
      <Stack
        direction='row'
        spacing='10px'
        sx={{
          display: 'flex',
          height: '100%',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          ...sx
        }}
        {...restContainerProps}
      >
        <CircularProgress {...circularProgressProps} />
        {message && (
          <Typography {...typographyProps}>
            {message}
          </Typography>
        )}
      </Stack>
    )
    : <>{children}</>
}

export default Loading
