import { LinkProps, styled } from "@mui/material"
import { Link } from "react-router-dom"

const StyledLink = styled(Link)<LinkProps>(({ theme }) => {
  return ({
    textDecoration: 'none',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover': {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
  })
})

export default StyledLink
