import { Stack } from "@mui/material"
import EmployeeAutoComplete from "components/controls/EmployeeAutoComplete/EmployeeAutoComplete"
import Button from "components/ui/Button/Button"
import ConfirmInput from "components/ui/ConfirmInput/ConfirmInput"
import FontIcon from "components/ui/FontIcon"
import { EmployeeType } from "models/employee.model"
import { useState } from "react"
import { useTranslation } from "react-i18next"

interface AccessGroupToolbarProps {
  disabled?: boolean
  onAddEmployees: (values: EmployeeType[]) => void
}

const AccessGroupToolbar = ({ disabled = false, onAddEmployees }: AccessGroupToolbarProps) => {

  const { t } = useTranslation()
  const [showAddAccessGroups, setShowAddAccessGroups] = useState(false)

  return (
    <Stack spacing='10px'>
      {showAddAccessGroups
        ? (
          <ConfirmInput<EmployeeType>
            renderInput={({ onChange }) => (
              <EmployeeAutoComplete
                freeSolo
                open
                disableCloseOnSelect
                multiple
                onChange={onChange}
              />
            )}
            onClose={() => setShowAddAccessGroups(false)}
            onConfirm={onAddEmployees}
          />
        )
        : (
          <Button
            size='small'
            variant='outlined'
            disabled={disabled}
            startIcon={<FontIcon icon='add' />}
            onClick={() => setShowAddAccessGroups(true)}
            sx={{ width: 'fit-content' }}
          >
            {t('kbl.buttons.addEmployee')}
          </Button>
        )
      }
    </Stack>
  )
}

export default AccessGroupToolbar
