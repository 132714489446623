export const defaultSmallIcon = (
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.3075 0C2.68295 0 2.18182 0.50364 2.18182 1.12819V24.3289C2.18182 24.9534 2.68295 25.4545 3.3075 25.4545H20.7273C21.3518 25.4545 21.8182 24.9534 21.8182 24.3289V6.18182L15.6364 0H3.3075Z" fill="#ECEDEE" />
    <path d="M15.6364 6.18182H21.8182L15.6364 0V6.18182Z" fill="#CDD1D3" />
    <path d="M6 10.5C6 10.2239 6.22386 10 6.5 10H17.5C17.7761 10 18 10.2239 18 10.5C18 10.7761 17.7761 11 17.5 11H6.5C6.22386 11 6 10.7761 6 10.5Z" fill="white" />
    <path d="M6 13.5C6 13.2239 6.22386 13 6.5 13H17.5C17.7761 13 18 13.2239 18 13.5C18 13.7761 17.7761 14 17.5 14H6.5C6.22386 14 6 13.7761 6 13.5Z" fill="white" />
    <path d="M6 16.5C6 16.2239 6.22386 16 6.5 16H17.5C17.7761 16 18 16.2239 18 16.5C18 16.7761 17.7761 17 17.5 17H6.5C6.22386 17 6 16.7761 6 16.5Z" fill="white" />
    <path d="M6 19.5C6 19.2239 6.22386 19 6.5 19H11.5C11.7761 19 12 19.2239 12 19.5C12 19.7761 11.7761 20 11.5 20H6.5C6.22386 20 6 19.7761 6 19.5Z" fill="white" />
  </>
)
