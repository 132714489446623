import { useTranslation } from "react-i18next"

interface NodeAccessHeaderProps {
}

const NodeAccessHeader = (props: NodeAccessHeaderProps) => {

  const { t } = useTranslation()

  return (
    <>
      {`${t('kbl.dialog.title.nodeAccess')}`}
    </>
  )
}

export default NodeAccessHeader
