import { Typography } from "@mui/material"
import DataGrid from "components/ui/DataGrid/DataGrid"
import Link from "components/ui/Link/Link"
import { renderCell, renderHeader } from "components/utils/datagrid"
import useActions from "hooks/useActions"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useGetTrendsQuery } from "reducers/api/employee.api"
import { useRemoveResponsibleNodeMutation } from "reducers/api/responsible.api"
import { isEditable } from "utils/checkRight"
import Button from "../../../../../components/ui/Button/Button"
import FontIcon from "../../../../../components/ui/FontIcon"
import { useGetNodeResponsibleQuery } from "../../../../../reducers/api/node.api"
import ActionAddButton from "../components/Actions/ActionAddButton"
import DocumentCard from "../components/DocumentCard/DocumentCard"
import { CardBlocksProps } from "./DocumentCardBlocksProps"

const DocumentResponsible = ({ node }: CardBlocksProps) => {
  const currentNodeId = node?.id
  const { t } = useTranslation()

  const { data: trendsData } = useGetTrendsQuery()
  const { data: nodeResponsible } = useGetNodeResponsibleQuery({ id: currentNodeId }, { skip: !currentNodeId })
  const [removeNodeResponsible] = useRemoveResponsibleNodeMutation()

  const { manageResponsible } = useActions(node)

  const referenceId = useMemo(() => trendsData?.find(x => x.isDirectory === true)?.id, [trendsData])

  const handleDeleteClick = (id: string) => {
    removeNodeResponsible({ id, nodeId: currentNodeId })
  }

  const columns = useMemo(() => [
    {
      field: 'shortName', headerName: t('kbl.dataGrid.shortName'), flex: 2,
      renderCell: ({ row }) => <Typography variant='body1'><Link to={`/reference/${referenceId}`}>{row.shortName}</Link></Typography>
    },
    { field: 'department', headerName: t('kbl.dataGrid.department'), flex: 1 },
    { field: 'phone', headerName: t('kbl.dataGrid.phone'), headerClassName: 'hideRightSeparator', flex: 1 },
    ...(isEditable(node) ? [{
      field: 'action',
      headerName: '',
      sortable: false,
      headerClassName: 'hideRightSeparator',
      width: 30,
      renderCell: ({ row }) => (
        <Button iconOnly onClick={() => handleDeleteClick(row.id)}>
          <FontIcon icon='delete' sx={{ color: 'primary.mosmetro' }} />
        </Button>
      )
    }] : [])
  ].map(column => ({ renderCell, renderHeader, ...column })), [node, trendsData])

  return (
    <DocumentCard
      title='Ответственные'
      actions={ActionAddButton(isEditable(node), manageResponsible)}
    >
      {nodeResponsible?.length < 1
        ? (
          <Typography variant="subtitle1" sx={{ textAlign: "center", paddingTop: "16px" }}>
            {t('kbl.responsibleNotFound')}
          </Typography>
        )
        : (
          <DataGrid
            columns={columns}
            rows={nodeResponsible || []}
            disableColumnReorder
            hideFooter
            density='compact'

            slots={{
              toolbar: null
            }}
          />
        )}

    </DocumentCard>
  )
}

export default DocumentResponsible
