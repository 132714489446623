import { Box } from "@mui/material"
import { NOOP } from "config/constants"
import { useAppDispatch, useAppSelector } from "config/store"
import { ElementType, useEffect, useRef, useState } from "react"
import { setContextId } from "reducers/context"
import Menu from "../Menu"
import { MenuItemType } from "../MenuList/MenuList"

type CoordsType = {
  mouseX: number
  mouseY: number
} | null

interface ContextMenuProps {
  contextId: string
  component?: ElementType
  disabled?: boolean
  header?: MenuItemType[]
  items: MenuItemType[]
  children: React.ReactNode
  onBeforeOpen?: Function
  onOpen?: Function
  onClose?: Function
}

function ContextMenu({ contextId, component, disabled, header, items, children, onBeforeOpen, onOpen = NOOP, onClose = NOOP }: ContextMenuProps) {

  const [coords, setCoords] = useState<CoordsType>(undefined)
  const [isOpen, setIsOpen] = useState(false)
  const [pending, setPending] = useState(false)

  const dispatch = useAppDispatch()
  const currentContextId = useAppSelector(state => state.context.currentContextId)

  const refId = useRef(null)
  refId.current = currentContextId

  const refCoords = useRef(null)
  refCoords.current = coords

  const handleClose = () => {
    dispatch(setContextId(undefined))
    setCoords(undefined)
    onClose()
  }

  const handleContextMenu = (event: React.MouseEvent) => {

    event.preventDefault()
    event.stopPropagation()

    dispatch(setContextId({ currentContextId: contextId }))
    setCoords({
      mouseX: event.clientX + 2,
      mouseY: event.clientY - 6,
    })
  }

  useEffect(() => {

    const handleOpen = async () => {
      if (onBeforeOpen) {
        setPending(true)
        await onBeforeOpen()
        //  setPending(false)
      }
      setIsOpen(refCoords.current !== undefined && contextId === refId.current)
    }

    if (contextId === currentContextId)
      handleOpen()
    else {
      setPending(false)
      setIsOpen(false)
    }
  }, [currentContextId])

  useEffect(() => {
    if (isOpen && coords) {
      onOpen()
    }
    else
      onClose()
  }, [coords, isOpen])

  return disabled || items?.length === 0
    ? <>{children}</>
    : (
      <Box id={contextId} component={component} onContextMenu={handleContextMenu}>
        {children}
        {!!coords && isOpen && (
          <Menu
            open={true}
            items={items}
            header={header?.length > 0 ? header : undefined}
            anchorReference="anchorPosition"
            anchorPosition={
              coords !== undefined
                ? { top: coords?.mouseY, left: coords?.mouseX }
                : { top: 10, left: 100 }
            }
            onClose={contextId === currentContextId ? handleClose : undefined}
            slotProps={{
              paper: {
                sx: {
                  //boxShadow: 'none',
                  maxWidth: '350px', zIndex: 9999,
                  //boxShadow: 'none',
                  // , 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)
                  //filter: 'drop-shadow(0 0 0.75rem rgba(0,0,0,0.2))'
                }
              }
            }}
          />
        )}
      </Box>
    )
}

export default ContextMenu
