import { Box, Divider, IconButton } from "@mui/material"
import Toolbar, { ToolbarItem } from "components/Toolbar/Toolbar"
import FontIcon from "components/ui/FontIcon"
import React from "react"

interface NodeToolbarProps {
  items: ToolbarItem[]
}

const NodeToolbar = ({ items }: NodeToolbarProps) => {
  return (
    <Toolbar>
      <>
        {items.map(({ divider, expander, icon, disabled, component, onClick, primaryColor }, idx) => {
          if (expander) {
            return <Box key={idx} flex={1} />
          }
          if (divider) {
            return <Divider key={idx} orientation='vertical' sx={{ height: '60%', marginInline: '10px' }} />
          }
          if (component) {
            return (
              <React.Fragment key={idx}>
                {component}
              </React.Fragment>
            )
          }
          return (
            <IconButton key={idx} disabled={disabled} onClick={onClick}>
              <FontIcon icon={icon}
                sx={{ color: disabled ? 'action.disabled' : primaryColor || 'primary.main' }}
              />
            </IconButton>
          )  
        })}
      </>  
    </Toolbar>
  )
}

export default NodeToolbar
