import { useLocation } from "react-router"

function usePath() {

  const location = useLocation()

  const fullPath = decodeURI(location.pathname.slice(1))
  const pathChunks = fullPath !== '' ? fullPath.split('/') : []

  return pathChunks
}

export default usePath
