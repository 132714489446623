import { commonApi } from "config/common-api"
import { API_URL } from "config/constants"

const apiUrl = `${API_URL}/users/auth`

export const auth = commonApi.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation<any, any>({
      query: (body) => ({
        method: 'POST',
        url: `${apiUrl}/login`,
        body
      }),
      invalidatesTags: ['USER', 'TRENDS', 'NODE', 
							// 'HOMEPAGE', 
							'FAVORITES', 'QUICK_NODES']
    }),

    refresh: builder.mutation<any, string>({
      query: (refreshToken) => ({
        method: 'POST',
        url: `${apiUrl}/refresh`,
        body: { refreshToken }
      })
    })
  })
})

export const { useLoginMutation, useRefreshMutation } = auth
