import { Box, CircularProgress, LinearProgress, LinearProgressProps, Stack, Typography } from "@mui/material"
import NodeIcon from "components/Icons/NodeIcon/NodeIcon"
import Dialog, { DialogProps } from "components/ui/Dialog/Dialog"
import FontIcon from "components/ui/FontIcon"
import { NOOP } from "config/constants"
import { useAppSelector } from "config/store"
import NodeType from "models/node.model"
import { useCallback, useEffect, useMemo } from "react"
import { ClipboardItem } from "reducers/clipboard"

interface PasteDialogProps extends DialogProps {
  open: boolean
  completed?: { [key: string]: boolean | string }
  onClose: () => void
}

function Notch({ status }: { status: string }) {
  switch (status) {
    case 'loading':
      return <CircularProgress size={16} sx={{ display: 'flex' }} />
    case 'success':
      return <FontIcon icon='check' sx={{ color: 'green' }} />
    case 'error':
      return <FontIcon icon='error' sx={{ color: 'primary.mosmetro' }} />
    default:
      return <></>
  }
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      <Box sx={{ flex: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Typography variant="body2" color="text.secondary">{`${Math.round(
        props.value,
      )}%`}</Typography>
    </Stack>
  )
}

function LinearWithValueLabel({ progress, onFinish = NOOP }: any) {
  useEffect(() => {
    if (progress >= 100) {
      onFinish()
    }
  }, [progress])

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  )
}

const PasteDialog = ({ open = false, completed, onClose, ...rest }: PasteDialogProps) => {

  const clipboard = useAppSelector(state => state.clipboard)

  const ItemProgress = useCallback(({ item }: { item: ClipboardItem }) => {

    const type = (item.data as NodeType)?.nodeType || item.type
    const nodeType = type === 'DOCUMENT' || type === 'file' || type === 'url' ? 'DOCUMENT' : 'FOLDER'

    return (
      <Stack key={item.id} direction='row' alignItems='center' justifyContent='space-between' spacing='8px' sx={{ width: '98%', minHeight: '42px' }}>
        <NodeIcon node={{ ...item.data, nodeType }} small />
        <Typography
          noWrap
          variant='body1'
          sx={{
            flex: 1,
          }}
        >
          {item.data.name}
        </Typography>
        <Notch status={completed[item?.id] === true ? 'success' : completed[item?.id] ? 'error' : 'loading'} />
      </Stack>
    )
  }, [completed])

  const isError = useMemo(() => {
    let error = false
    for (const key in completed) {
      if (completed[key] === 'error') {
        error = true
        break
      }
    }
    return error
  }, [completed])

  return open
    ? (
      <Dialog
        open={true}
        onClose={onClose}
        {...rest}
      >
        <Stack sx={{ overflow: 'auto' }}>
          <LinearWithValueLabel
            progress={Object.keys(completed)?.length * 100 / clipboard.data?.length}
            onFinish={() => { !isError && onClose() }}
          />
          <Stack sx={{ overflow: 'auto', marginTop: '20px', '& .MuiStack-root:not(:last-of-type)': { borderBottom: '1px solid rgba(0,0,0,0.1)' } }}>
            {clipboard.data?.map(item => (
              <ItemProgress key={item.id} item={item} />
            ))}
          </Stack>
        </Stack>
      </Dialog>
    )
    : null
}

export default PasteDialog
