import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'config/constants'

export interface AuthState {
  logged: boolean | undefined,
  expiresIn: undefined
}

const initialState: AuthState = {
  logged: undefined,
  expiresIn: undefined
}

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state: AuthState, action: PayloadAction<any>) => {
      sessionStorage.setItem(ACCESS_TOKEN, action.payload.accessToken)
      localStorage.setItem(REFRESH_TOKEN, action.payload.refreshToken)
      state.logged = true
      state.expiresIn = action.payload.expiresIn
    },
    logout: (state: AuthState) => {
      sessionStorage.removeItem(ACCESS_TOKEN)
      localStorage.removeItem(REFRESH_TOKEN)
      state.logged = false
      state.expiresIn = undefined
    },
  }
})

export const {
  login,
  logout,
} = auth.actions

export default auth